import { HttpHeaders } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { InMemoryCache, ApolloLink, concat } from '@apollo/client/core';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';

import { ApiHeaderService } from '@app/core/api-header.service';
import { ConfigService } from '@app/core/config.service';
import { CoreModule } from '@app/core/core.module';

export function createApollo(httpLink: HttpLink, apiHeaderService: ApiHeaderService, configService: ConfigService) {
  const uri = `${configService.json.apiServer}/api/graphql`;
  const http = httpLink.create({ uri });

  const authMiddleware = new ApolloLink((operation, forward) => {
    const { token } = apiHeaderService;

    if (token) {
      const authHeader = `Bearer ${token}`;
      operation.setContext({
        headers: new HttpHeaders().set('Authorization', authHeader),
      });
    }

    return forward(operation);
  });

  return {
    link: concat(authMiddleware, http),
    cache: new InMemoryCache(),
  };
}

@NgModule({
  imports: [CoreModule],
  exports: [],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, ApiHeaderService, ConfigService],
    },
  ],
})
export class GraphQLModule {}
