import { Component, OnInit } from '@angular/core';

import { FlashService } from '@app/shared/flash.service';

@Component({
  selector: 'om-flash',
  templateUrl: './flash.component.html',
  styleUrls: ['./flash.component.scss'],
})
export class FlashComponent implements OnInit {
  errors: string[] = [];
  alerts: string[] = [];
  notices: string[] = [];
  successes: string[] = [];

  constructor(private flashService: FlashService) {}

  ngOnInit() {
    // Can be subscribed to
    this.flashService.messages$.subscribe(data => {
      this.errors = data['error'];
      this.alerts = data['alert'];
      this.notices = data['notice'];
      this.successes = data['success'];
    });
  }

  remove(message, type) {
    switch (type) {
      case 'error':
        this.errors.splice(this.errors.indexOf(message), 1);
        break;
      case 'alert':
        this.alerts.splice(this.alerts.indexOf(message), 1);
        break;
      case 'notice':
        this.notices.splice(this.notices.indexOf(message), 1);
        break;
      case 'success':
        this.successes.splice(this.successes.indexOf(message), 1);
        break;
    }
  }
}
