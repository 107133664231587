import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'om-user-on-comp',
  templateUrl: './user-on-comp.component.html',
})
export class UserOnCompComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
