import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { TypeaheadSuggestion } from '@app/shared/typeahead/typeahead-suggestion';

import { AnalyticsService } from '../core/analytics.service';
import { MP_EVENT_PAGE_VIEWED } from '../core/mixpanel.constants';
import { SurveyQuestion } from '../survey/survey-models';
import { ServiceArea } from './../shared/service-area';
import { AppointmentBookingState } from './appointment-booking-state-service/appointment-booking-state';
import { AppointmentSearchState } from './appointment-search-state';
import { AppointmentInventory } from './provider-inventories';

export enum TrackableFilterType {
  DATE = 'date',
  LOCATION = 'location',
}

export enum AppointmentAnalyticsProperty {
  AppointmentConfirmationPageModule = 'Appointment Confirmation Page',
  BehavioralHealthProgramIneligiblePageModule = 'Behavioral Health Program Ineligible Page',
  BookingFlow = 'Appointment Booking',
  BookingPageModule = 'Appointment Booking Page',
  CommonConcernsPageModule = 'Common Concerns Page',
  GetCareFlow = 'Get Care',
  InventoryPageModule = 'Appointment Inventory Page',
  ReasonForReschedulePageModule = 'Reason for Reschedule Page',
  RescheduleFlow = 'Appointment Reschedule',
  OMNowNavigationFlow = 'OM Now Navigation',
  VirtualOnlyLandingPageModule = 'Virtual Only Landing Page',
  GetVirtualCareCardSubmodule = 'Get Virtual Care Card',
  MessageProviderCardSubmodule = 'Message Provider Card',
  MindsetEducationPageModule = 'Enterprise Mindset Education Page',
  GetCareCardSubmodule = 'Get Care Card',
  SelfPayConfirmationModule = 'Self Pay Confirmation Modal',
  ShiftEducationPageModule = 'Shift Education Page',
  MemberRequestFlow = 'Member Request',
}

@Injectable()
export class AppointmentAnalyticsService extends AnalyticsService {
  protected bookingState: AppointmentBookingState;
  protected searchState: AppointmentSearchState;
  private bookingStartedParams?: { module: AppointmentAnalyticsProperty; flow: AppointmentAnalyticsProperty };

  protected track(eventName: string, properties?: { [index: string]: any }): Observable<any> {
    const trackProps = {
      ...this.defaultProperties,
      ...properties,
      ...(this.bookingState && this.bookingState.trackableProperties),
      ...(this.searchState && this.searchState.trackableProperties),
    };
    return super.track(eventName, trackProps);
  }

  setBookingState(bookingState: AppointmentBookingState) {
    this.bookingState = bookingState;
  }

  setSearchState(searchState: AppointmentSearchState) {
    this.searchState = searchState;
  }

  bookingStarted() {
    return this.track('Appointment Booking Started', this.bookingStartedParams);
  }

  reasonRemoved(selectedReason: object) {
    return this.track('Appointment Reason Deleted', { deleted_reason: selectedReason });
  }

  reasonSubmitted() {
    return this.track('Appointment Reason Submitted', this.channelRoutingTrackingParams());
  }

  appointmentReasonTypeaheadSelected(suggestion: TypeaheadSuggestion) {
    return this.track('Appointment Reason Typeahead Selected', {
      typeahead_option_selected: suggestion.text,
      typeahead_option_rank: suggestion.index,
    });
  }

  bookingPageViewed(pageName: string) {
    return this.track(MP_EVENT_PAGE_VIEWED, {
      flow: AppointmentAnalyticsProperty.BookingFlow,
      module: AppointmentAnalyticsProperty.BookingPageModule,
      submodule: pageName,
    });
  }

  providerCategorySelected() {
    return this.track('Provider Selected');
  }

  providerListSearched(filterText: string) {
    return this.track('Provider List Searched', { filter_text: filterText });
  }

  searched() {
    return this.track('Appointment Inventory Searched', {
      module: AppointmentAnalyticsProperty.InventoryPageModule,
      flow: AppointmentAnalyticsProperty.BookingFlow,
    });
  }

  searchFailed() {
    return this.track('Appointment Inventory Search Failed');
  }

  appointmentTypeSelected() {
    return this.track('Appointment Type Selected');
  }

  inventorySelected(inventory: AppointmentInventory) {
    const properties: any = {
      module: AppointmentAnalyticsProperty.AppointmentConfirmationPageModule,
      flow: AppointmentAnalyticsProperty.BookingFlow,
    };
    if (!!inventory) {
      properties.appointment_inventory_id = inventory.id;
    }
    return this.track('Appointment Selected', properties);
  }

  filterApplied(filterType: string, filterDetails: object) {
    const properties = {
      filter_type: filterType,
      ...filterDetails,
    };
    return this.track('Filter Applied', properties);
  }

  paginateNext() {
    return this.track('Next Button Clicked');
  }

  paginatePrevious() {
    return this.track('Previous Button Clicked');
  }

  bookAppointmentClicked() {
    return this.track('Book Appointment Clicked');
  }

  bookingFailed() {
    return this.track('Appointment Booking Failed');
  }

  bookingCancelled() {
    return this.track('Appointment Booking Cancelled');
  }

  appointmentBooked(appointment_id: number, inventory_id: number) {
    return this.track('Appointment Booked', {
      module: AppointmentAnalyticsProperty.AppointmentConfirmationPageModule,
      flow: AppointmentAnalyticsProperty.BookingFlow,
      appointment_id: appointment_id,
      appointment_inventory_id: inventory_id,
    });
  }

  jumpAheadClicked() {
    return this.track('Jump Ahead Clicked', {
      module: AppointmentAnalyticsProperty.InventoryPageModule,
      flow: AppointmentAnalyticsProperty.BookingFlow,
    });
  }

  addToCalendarClicked() {
    return this.track('Add To Calendar Clicked');
  }

  appointmentSurveySubmitted(surveyName: string) {
    return this.track('Survey Submitted', {
      flow: AppointmentAnalyticsProperty.BookingFlow,
      module: AppointmentAnalyticsProperty.BookingPageModule,
      submodule: surveyName,
    });
  }

  commonConcernsPageViewed() {
    return this.track(MP_EVENT_PAGE_VIEWED, {
      flow: AppointmentAnalyticsProperty.GetCareFlow,
      module: AppointmentAnalyticsProperty.CommonConcernsPageModule,
    });
  }

  commonConcernSubmitted(commonConcern) {
    return this.track('Common Concern Submitted', {
      flow: AppointmentAnalyticsProperty.GetCareFlow,
      module: AppointmentAnalyticsProperty.CommonConcernsPageModule,
      topic_selected: commonConcern,
    });
  }

  appointmentSurveyQuestionAnswered(surveyName: string, surveyQuestion: SurveyQuestion) {
    return this.track('Survey Question Answered', {
      flow: AppointmentAnalyticsProperty.BookingFlow,
      module: AppointmentAnalyticsProperty.BookingPageModule,
      submodule: surveyName,
      survey_question: surveyQuestion.questionText,
      survey_answer: surveyQuestion.patientAnswers.map(answer => answer.answerText).join(', '),
    });
  }

  reasonForCancelSubmitted() {
    return this.track('Reason for Cancel Submitted', {
      flow: AppointmentAnalyticsProperty.RescheduleFlow,
      module: AppointmentAnalyticsProperty.ReasonForReschedulePageModule,
    });
  }

  rescheduled() {
    return this.track('Appointment Rescheduled', {
      flow: AppointmentAnalyticsProperty.RescheduleFlow,
      module: AppointmentAnalyticsProperty.AppointmentConfirmationPageModule,
    });
  }

  trackOMNowPageViewed() {
    return this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, {
      flow: AppointmentAnalyticsProperty.OMNowNavigationFlow,
      module: AppointmentAnalyticsProperty.VirtualOnlyLandingPageModule,
    });
  }

  trackJoinVideoVisitClicked() {
    return this.trackWithDefaultProperties('Send Link Clicked', {
      flow: AppointmentAnalyticsProperty.OMNowNavigationFlow,
      module: AppointmentAnalyticsProperty.VirtualOnlyLandingPageModule,
      submodule: AppointmentAnalyticsProperty.GetVirtualCareCardSubmodule,
    });
  }

  trackMessageAProviderClicked() {
    return this.trackWithDefaultProperties('Message A Provider Clicked', {
      flow: AppointmentAnalyticsProperty.OMNowNavigationFlow,
      module: AppointmentAnalyticsProperty.VirtualOnlyLandingPageModule,
      submodule: AppointmentAnalyticsProperty.MessageProviderCardSubmodule,
    });
  }

  trackBookVisitClicked() {
    return this.trackWithDefaultProperties('Book Visit Clicked', {
      flow: AppointmentAnalyticsProperty.OMNowNavigationFlow,
      module: AppointmentAnalyticsProperty.VirtualOnlyLandingPageModule,
      submodule: AppointmentAnalyticsProperty.GetCareCardSubmodule,
    });
  }

  trackContinueToBookingClicked({ name: service_area_selected }: ServiceArea) {
    return this.trackWithDefaultProperties('Continue To Booking Clicked', {
      service_area_selected,
      flow: AppointmentAnalyticsProperty.OMNowNavigationFlow,
      module: AppointmentAnalyticsProperty.VirtualOnlyLandingPageModule,
      submodule: AppointmentAnalyticsProperty.GetCareCardSubmodule,
    });
  }

  /** BHX Program Ineligibility Events */

  // Sets the properties to pass to `.bookingStarted` to track what module/flow is being referred from
  setAppointmentBookingFromBehavioralHealthProgramIneligibility() {
    this.bookingStartedParams = {
      module: AppointmentAnalyticsProperty.BehavioralHealthProgramIneligiblePageModule,
      flow: AppointmentAnalyticsProperty.BookingFlow,
    };
  }

  behavioralHealthProgramIneligibilityPageViewed() {
    return this.track(MP_EVENT_PAGE_VIEWED, {
      module: AppointmentAnalyticsProperty.BehavioralHealthProgramIneligiblePageModule,
      flow: AppointmentAnalyticsProperty.BookingFlow,
    });
  }

  behavioralHealthProgramIneligibilityMessageUsClicked() {
    return this.trackWithDefaultProperties('Message Us Clicked', {
      module: AppointmentAnalyticsProperty.BehavioralHealthProgramIneligiblePageModule,
      flow: AppointmentAnalyticsProperty.MemberRequestFlow,
    });
  }
  /** End BHX Program Ineligibility Events */

  /** Mindset Events */
  mindsetEducationPageViewed(source?: string) {
    return this.track(MP_EVENT_PAGE_VIEWED, {
      module: AppointmentAnalyticsProperty.MindsetEducationPageModule,
      flow: AppointmentAnalyticsProperty.BookingFlow,
      source,
    });
  }

  mindsetEducationAppointmentBookingStarted() {
    return this.track('Appointment Booking Started', {
      module: AppointmentAnalyticsProperty.MindsetEducationPageModule,
      flow: AppointmentAnalyticsProperty.BookingFlow,
    });
  }
  /** End Mindset Events */

  /** Shift Events */
  shiftEducationPageViewed(options: string[], source?: string) {
    return this.track(MP_EVENT_PAGE_VIEWED, {
      module: AppointmentAnalyticsProperty.ShiftEducationPageModule,
      flow: AppointmentAnalyticsProperty.BookingFlow,
      source: source,
      option_count: options.length,
      options: options,
    });
  }

  shiftEducationAppointmentBookingStarted(appointmentTypeName: string) {
    return this.track('Appointment Booking Started', {
      module: AppointmentAnalyticsProperty.ShiftEducationPageModule,
      flow: AppointmentAnalyticsProperty.BookingFlow,
      appointment_type_name: appointmentTypeName,
    });
  }

  shiftSeeAllOptionsClicked(optionCount: number) {
    return this.track('See Options Clicked', {
      module: AppointmentAnalyticsProperty.ShiftEducationPageModule,
      flow: AppointmentAnalyticsProperty.BookingFlow,
      option_count: optionCount,
    });
  }

  shiftCarouselInteraction(interaction: string) {
    return this.track('Carousel Clicked', {
      module: AppointmentAnalyticsProperty.ShiftEducationPageModule,
      flow: AppointmentAnalyticsProperty.BookingFlow,
      submodule: interaction,
    });
  }
  /** End Shift Events */

  insuranceConfirmation(
    confirmationType: 'Yes' | 'No' | 'Cancel' | 'Confirm',
    module:
      | AppointmentAnalyticsProperty.SelfPayConfirmationModule
      | AppointmentAnalyticsProperty.AppointmentConfirmationPageModule = AppointmentAnalyticsProperty.AppointmentConfirmationPageModule,
  ) {
    return this.track('Confirm Insurance Clicked', {
      module,
      confirmation_type: confirmationType,
      flow: AppointmentAnalyticsProperty.BookingFlow,
    });
  }

  private channelRoutingTrackingParams() {
    const {
      quickumlsAppointmentTypeId,
      channelRoutingAppointmentTypeId,
      channelRoutingEnabled,
    } = this.bookingState.channelRoutingTracking;

    return {
      quickumls_appointment_type_id: quickumlsAppointmentTypeId,
      channel_routing_appointment_type_id: channelRoutingAppointmentTypeId,
      channel_routing_enabled: channelRoutingEnabled,
      channel_routing_quickumls_match: channelRoutingAppointmentTypeId === quickumlsAppointmentTypeId,
    };
  }
}
