import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { TermsOfService } from '@app/core/legal-documents';
import { TosService } from '@app/core/tos.service';

@Component({
  selector: 'om-legal-agreement',
  templateUrl: './legal-agreement.component.html',
  styleUrls: ['./legal-agreement.component.scss'],
})
export class LegalAgreementComponent implements OnInit {
  @Input() processing: boolean;
  @Input() agree: boolean;
  @Output() agreed = new EventEmitter<TermsOfService>();

  constructor(public tosService: TosService) {}

  ngOnInit() {}

  agreeSelected($event) {
    $event.preventDefault();
    this.agreed.emit();
  }
}
