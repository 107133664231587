import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PhoneNumberFormatter } from '@app/shared/phone-number-formatter';
import { phoneNumberValidator } from '@app/shared/phone-number-validator.directive';

@Component({
  selector: 'om-phone-number-input',
  templateUrl: './phone-number-input.component.html',
  styleUrls: ['../form-input.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneNumberInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PhoneNumberInputComponent),
      multi: true,
    },
  ],
})
export class PhoneNumberInputComponent implements OnInit, ControlValueAccessor, Validator, OnDestroy {
  @Input() overrideDisplayError = false;

  phoneNumberForm: FormGroup;
  displayError = false;
  errorMessage = 'Please enter a valid phone number';
  @Output() errorMessageEmitter = new EventEmitter<string>();

  private destroy$ = new Subject();

  constructor(public formBuilder: FormBuilder) {}

  ngOnInit() {
    this.createForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  createForm() {
    this.phoneNumberForm = this.formBuilder.group({
      formattedPhoneNumber: [null, [Validators.required]],
      phoneNumber: [null, [Validators.required, phoneNumberValidator]],
    });
  }

  handlePhoneBlurValidation() {
    if (this.phoneControl.invalid && this.phoneControl.errors) {
      this.displayError = true;
      this.errorMessageEmitter.emit(this.errorMessage);
    } else {
      this.displayError = false;
      this.errorMessageEmitter.emit(undefined);
    }
  }

  private handlePhoneChangeValidation() {
    if (this.phoneControl.invalid && this.phoneControl.errors.required) {
      this.displayError = true;
      this.errorMessageEmitter.emit(this.errorMessage);
    } else {
      this.displayError = false;
      this.errorMessageEmitter.emit(undefined);
    }
  }

  private setPhoneNumber(phoneNumber) {
    const formatter = new PhoneNumberFormatter(phoneNumber);
    this.formattedPhoneControl.setValue(formatter.display, { emitEvent: false });
    this.phoneControl.setValue(formatter.tenDigitNumber, { emitEvent: false });
  }

  private get phoneControl() {
    return this.phoneNumberForm.controls.phoneNumber;
  }

  private get formattedPhoneControl() {
    return this.phoneNumberForm.controls.formattedPhoneNumber;
  }

  onTouched: () => void = () => {};

  writeValue(val: any): void {
    if (val) {
      this.setPhoneNumber(val);
    }
  }

  registerOnChange(fn: any): void {
    this.phoneNumberForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.setPhoneNumber(data.formattedPhoneNumber);
      this.handlePhoneChangeValidation();
      fn(this.phoneNumberForm.value.phoneNumber);
    });
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.phoneNumberForm.valid
      ? null
      : { dateForm: { valid: false, message: 'phone number fields are invalid' } };
  }

  markAsTouchedAndDirty() {
    this.phoneNumberForm.controls.formattedPhoneNumber.markAsDirty();
    this.phoneNumberForm.controls.formattedPhoneNumber.markAsTouched();
  }
}
