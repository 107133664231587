import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of as observableOf } from 'rxjs';

import { EnterpriseRegistration } from '../enterprise-registration';
import { EnterpriseRegistrationAnalyticsService } from '../enterprise-registration-analytics.service';
import { IRegistrationStep, RegistrationStep } from '../registration-step';
import { SuccessStepComponent } from './success-step.component';

@Injectable()
export class SuccessConfig extends RegistrationStep implements IRegistrationStep {
  GA_LABEL = 'Congratulations_Step';
  MODULE = 'Enterprise Registration Confirmation Page';
  CONSENT_FORM_REDIRECT_B2B_COMPANIES = [753];
  component = SuccessStepComponent;
  componentInstance: SuccessStepComponent;
  progress = 100;
  form = null;

  constructor(
    private router: Router,
    private enterpriseRegistrationAnalyticsService: EnterpriseRegistrationAnalyticsService,
  ) {
    super();
  }

  canGoBack(): boolean {
    return false;
  }

  initComponent(component: SuccessStepComponent, state: EnterpriseRegistration) {
    this.componentInstance = component;
    this.componentInstance.patientEmail = state.patient.email;
    this.componentInstance.patientFirstName = state.patient.firstName;
    this.componentInstance.isNewPatient = !state.enterpriseConversionComplete;
    this.componentInstance.module = this.MODULE;
    this.componentInstance.isWhitelisted = state.isWhitelisted;
    this.componentInstance.serviceArea = state.patient.serviceArea;
    this.componentInstance.shouldRedirectToConsentForm = this.shouldRedirectToConsentForm(state.b2bCompanyId);
    if (state.b2bCompany) {
      this.componentInstance.showDirectSignup = state.b2bCompany.includesDependent;
    }
    this.trackPageView();
  }

  submit(state: EnterpriseRegistration, captcha: any): Observable<any> {
    return observableOf(true);
  }

  private shouldRedirectToConsentForm(b2bCompanyId) {
    // TODO: Should refactor this to remove hardcoded company ID
    return this.CONSENT_FORM_REDIRECT_B2B_COMPANIES.includes(b2bCompanyId);
  }

  private trackPageView() {
    this.enterpriseRegistrationAnalyticsService.trackGoogleEvent(this.GA_ACTION, this.GA_LABEL);
  }
}
