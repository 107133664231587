export const GENERIC_WHITELISTING_ERROR_MESSAGE = 'Something went wrong, please refresh and try again.';

export class AlreadyRegisteredError extends Error {
  alreadyRegistered = true;
}
export class EmployeeNotFoundError extends Error {
  employeeNotFound = true;
}
export class DependentAlreadyRegisteredError extends Error {
  dependentAlreadyRegistered = true;
}
export class NoWhitelistedDependentFoundError extends Error {
  noWhitelistedDependentFound = true;
}
export class AllDependentsRegisteredError extends Error {
  allDependentsRegistered = true;
}
export class InvalidConversionError extends Error {
  invalidConversion = true;
}

export const INVALID_CONVERSION_ERROR_MESSAGE =
  "You're already enrolled with your company's plan. If you have questions about your membership contact <a href='mailto:admin@onemedical.com'>admin@onemedical.com</a>";
export const PLAN_DOESNT_SUPPORT_DEPENDENTS_ERROR_MESSAGE =
  "Your employer doesn't pay for your child's membership. For more information contact your benefits team.";
