import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Observable, of as observableOf, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { WindowService } from '@app/core/window.service';

import { mapHtml, modifyForm } from './formstack-form.utils';
import { FormstackForm } from './formstack.type';

@Component({
  selector: 'om-formstack-form',
  templateUrl: './formstack-form.component.html',
  styleUrls: ['./formstack-form.component.scss'],
})
export class FormstackFormComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();

  iframeReady = false;
  iframeSrc$: Observable<SafeResourceUrl>;

  @ViewChild('iframe') iframe: ElementRef;

  @Input()
  formstackForm: FormstackForm;

  @Output()
  submit = new EventEmitter<any>();

  constructor(
    private sanitizer: DomSanitizer,
    private windowService: WindowService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.iframeSrc$ = observableOf(this.formstackForm).pipe(
      map(mapHtml), // fix any necessary html for rendering within our iframe
      map(html => this.windowService.getURL().createObjectURL(new Blob([html], { type: 'text/html' }))),
      map(url => this.sanitizer.bypassSecurityTrustResourceUrl(url)),
    );
  }

  iframeLoaded(event) {
    if (!event.target.src) {
      return;
    }

    modifyForm(this.iframe.nativeElement, this.submit);
    this.showIFrame();
  }

  showIFrame() {
    this.iframeReady = true;
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
