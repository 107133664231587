import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ApiService } from '../core/api.service';

@Injectable()
export class RegistrationService {
  constructor(private apiService: ApiService) {}

  markRegComplete(pendingEnterpriseRegistrationId) {
    return this.apiService
      .post('/api/v2/patient/registrations/mark_reg_complete', {
        pending_enterprise_registration_id: pendingEnterpriseRegistrationId,
      })
      .pipe(catchError(error => observableThrowError(error)));
  }

  generateEmail(firstName: string, lastName: string): Observable<string> {
    return this.apiService
      .get('/api/v2/public/random_email?', false, { preferred_name: firstName, last_name: lastName })
      .pipe(
        map(response => response['email']),
        catchError(error => observableThrowError(error)),
      );
  }
}
