/**
 * NOTE: this is meant to be accessed through the anonymous-flag-data-service, not directly imported
 *       this allows hooks to fire appropriately to store the client token appropriately
 */
import { Injectable } from '@angular/core';
import { Query, gql, Apollo } from 'apollo-angular';

export interface AnonymousFlag {
  name: string;
  enabled: boolean;
}

export interface AnonymousFlagData {
  clientToken: string;
  flags: AnonymousFlag[];
}

export interface AnonymousFlagDataResponse {
  anonymousFlagData: AnonymousFlagData;
}

export interface AnonymousFlagDataParameters {
  clientToken: string;
}

@Injectable()
export class AnonymousFlagDataGraphQL extends Query<AnonymousFlagDataResponse, AnonymousFlagDataParameters> {
  constructor(apollo: Apollo) {
    super(apollo);
  }

  document = gql`
    query AnonymousFlagData($clientToken: String) {
      anonymousFlagData(clientToken: $clientToken) {
        clientToken
        flags {
          name
          enabled
        }
      }
    }
  `;
}
