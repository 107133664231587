import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'om-modal-template',
  templateUrl: './modal-template.component.html',
  styleUrls: ['./modal-template.component.scss'],
})
export class ModalTemplateComponent implements OnInit {
  @Input() heading: string;
  @Input() subheader: string;
  @Input() primaryCallToActionLabel: string;
  @Input() secondaryCallToActionLabel: string;
  @Input() primaryClasses = 'modal-footer pt-0 px-md-5 pb-md-5 border-0 justify-content-center';

  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  @Output() primaryCallToAction: EventEmitter<void> = new EventEmitter<void>();
  @Output() secondaryCallToAction: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  ngOnInit() {}
}
