import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'om-svg-vaccine',
  template: `
    <svg
      width="29px"
      height="28px"
      viewBox="0 0 29 28"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <!-- Generator: Sketch 39 (31667) - http://www.bohemiancoding.com/sketch -->
      <title>Page 1</title>
      <desc>Created with Sketch.</desc>
      <defs></defs>
      <g id="icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Page-1" stroke="#1b7e74">
          <path d="M24.1958,23.7344 L28.2308,27.7694" id="Stroke-2" [attr.stroke]="color" />
          <path d="M15.6058,9.644 L13.3138,11.936" id="Stroke-3" [attr.stroke]="color" />
          <path d="M18.292,11.9358 L16,14.2278" id="Stroke-3" [attr.stroke]="color" />
          <path d="M20.1894,14.2276 L17.8974,16.5196" id="Stroke-4" [attr.stroke]="color" />
          <path d="M22.4812,16.5194 L20.1892,18.8114" id="Stroke-5" [attr.stroke]="color" />
          <g id="Group-22">
            <path
              d="M6.4386,5.9771 C7.4516,6.9901 7.4516,8.6311 6.4386,9.6441 C5.4256,10.6561 3.7846,10.6561 2.7716,9.6441 C1.7596,8.6311 1.7596,6.9901 2.7716,5.9771 C3.7846,4.9641 5.4256,4.9641 6.4386,5.9771 L6.4386,5.9771 Z"
              id="Stroke-6"
              [attr.stroke]="color"
            />
            <path d="M0.9382,4.1437 L4.6052,0.4767" id="Stroke-8" [attr.stroke]="color" />
            <path d="M5.9802,5.5188 L10.1052,9.6438" id="Stroke-10" [attr.stroke]="color" />
            <path
              d="M12.7789,17.8889 C10.4209,15.5039 8.2719,13.3109 8.2719,13.3109 C7.7679,12.8069 7.7679,11.9819 8.2719,11.4779 L11.9389,7.8109 C12.4429,7.3059 13.2679,7.3059 13.7719,7.8109 L16.9429,10.9809 L19.0318604,13.0755616"
              id="Stroke-12"
              [attr.stroke]="color"
            />
            <path
              d="M18.269,12.3072 L24.315,18.3532 C23.226,21.8482 24.315,23.8532 24.315,23.8532 C24.315,23.8532 22.08,22.9932 18.814,23.8532 C18.744,23.8722 14.8701245,19.9826963 12.6901245,17.7886963"
              id="Stroke-14"
              [attr.stroke]="color"
            />
            <path
              d="M10.1054,2.3102 C11.1184,3.3232 11.1184,4.9642 10.1054,5.9772 C9.0924,6.9892 7.4514,6.9892 6.4384,5.9772 C5.4264,4.9642 5.4264,3.3232 6.4384,2.3102 C7.4514,1.2972 9.0924,1.2972 10.1054,2.3102 L10.1054,2.3102 Z"
              id="Stroke-16"
              [attr.stroke]="color"
            />
          </g>
        </g>
      </g>
    </svg>
  `,
})
export class SvgVaccineComponent implements OnInit {
  @Input() color = 'currentColor';
  constructor() {}

  ngOnInit() {}
}
