import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize',
})
export class FileSizePipe implements PipeTransform {
  constructor() {}

  transform(size: number) {
    if (size > 2 ** 20) {
      return `${(size / 2 ** 20).toFixed(0)} MB`;
    } else if (size > 2 ** 10) {
      return `${(size / 2 ** 10).toFixed(0)} KB`;
    } else {
      return `${size} bytes`;
    }
  }
}
