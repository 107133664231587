<div class="container" *ngIf="links.length > 0">
  <div class="row mt-4">
    <div class="col-md-4 col-lg-3 d-none d-md-block">
      <h3 class="mt-2 pl-3">{{ sidebarTitle }}</h3>
      <ul class="nav flex-column">
        <li class="nav-item" *ngFor="let link of links">
          <ng-template #navItem>
            <div class="inline-icon-with-text">
              <div class="nav-icon mr-2"><ng-template #svg></ng-template></div> {{ link.name }}
            </div>
          </ng-template>

          <ng-container *ngIf="link.routerLink; then linkContainer; else textContainer"></ng-container>

          <ng-template #linkContainer>
            <a
              class="nav-link"
              [routerLink]="link.routerLink"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: matchExact }"
              data-cy="sidebar-nav-link"
            >
              <ng-container *ngTemplateOutlet="navItem"></ng-container>
            </a>
          </ng-template>

          <ng-template #textContainer>
            <div class="nav-link"> <ng-container *ngTemplateOutlet="navItem"></ng-container> </div>
          </ng-template>

          <ul class="nav flex-column" *ngIf="link.subRoutes">
            <li class="nav-item" *ngFor="let subRoute of link.subRoutes">
              <a class="nav-link" [routerLink]="subRoute.routerLink" routerLinkActive="active">
                <div class="d-inline-block pl-5"> {{ subRoute.name }} </div>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="col-md-8 col-lg-9"><router-outlet></router-outlet></div>
  </div>
</div>
