import { Component, ViewChild } from '@angular/core';
import { RecaptchaComponent } from 'ng-recaptcha';
import { Observable, Observer, of as observableOf, Subject } from 'rxjs';

import { ConfigService } from '@app/core/config.service';

@Component({
  selector: 'om-recaptcha',
  templateUrl: './om-recaptcha.component.html',
})
export class OmRecaptchaComponent {
  @ViewChild('captcha') captcha: RecaptchaComponent;

  constructor(public config: ConfigService) {}

  token$: Subject<string>;

  onResolved(token: string) {
    this.token$.next(token);
  }

  getToken(): Observable<string> {
    if (!this.captcha) {
      return observableOf(undefined);
    }
    if (this.token$) {
      this.token$.complete();
    }
    this.token$ = new Subject<string>();
    this.captcha.reset();
    this.captcha.execute();
    return this.token$;
  }
}
