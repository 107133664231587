<div class="modal-header p-2 border-0">
  <om-close-x
    role="button"
    class="btn close font-weight-light text-right"
    aria-label="Close"
    (click)="close.emit()"
  ></om-close-x>
</div>
<div class="d-md-block d-sm-none d-none text-center pb-4"><ng-content></ng-content></div>
<div class="mx-md-5">
  <div class="modal-body px-4 pb-4 px-md-0 pt-0 pb-md-4 text-center">
    <h2>{{ heading }}</h2> <p class="fixed-size mb-0"> {{ subheader }} </p>
  </div>
</div>
<div [class]="primaryClasses">
  <div class="col-md-12 col-lg-12 col-sm-12 px-0">
    <button (click)="primaryCallToAction.emit()" class="btn btn-primary btn-block text-center">
      {{ primaryCallToActionLabel }}
    </button>
  </div>
</div>
<div *ngIf="secondaryCallToActionLabel" class="modal-body text-center pt-0 py-3">
  <button (click)="secondaryCallToAction.emit()" class="btn btn-link"> {{ secondaryCallToActionLabel }} </button>
</div>
<ng-content select="[custom]"></ng-content>
