import { LazyMapsAPILoaderConfigLiteral } from '@agm/core';
import { Injectable } from '@angular/core';

import { ConfigService } from '../core/config.service';

@Injectable()
export class MapsApiConfig implements LazyMapsAPILoaderConfigLiteral {
  apiKey: string;
  libraries: string[];

  constructor(public config: ConfigService) {
    this.apiKey = this.config.json.googlePlacesKey;
    this.libraries = ['places'];
  }
}
