import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'om-exclamation-in-bubble',
  templateUrl: './exclamation-in-bubble.component.html',
})
export class ExclamationInBubbleComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
