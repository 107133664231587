import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FetchResult } from '@apollo/client/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ComponentType } from '@app/component-type';
import { UserService } from '@app/core/user.service';
import { AppStoreRedirectService } from '@app/shared/app-store-redirect.service';
import { RemoveCardEvent } from '@app/shared/card/card.component';
import { SendAppLink } from '@app/shared/download-app-card/__generated__/SendAppLink';
import { SendAppLinkGraphql } from '@app/shared/download-app-card/send-app-link-graphql.service';
import { PageState } from '@app/shared/page-state/page-state';
import { User } from '@app/shared/user';

@Component({
  selector: 'om-download-app',
  templateUrl: './download-app-card.component.html',
})
export class DownloadAppCardComponent implements OnInit, OnDestroy {
  constructor(
    private sendAppLinkGraphQL: SendAppLinkGraphql,
    private userService: UserService,
    private appStoreRedirectService: AppStoreRedirectService,
  ) {}

  static StorageKey = 'hide_download_app_card';
  pageState = PageState.LOADING;
  buttonClasses: string;
  buttonText = 'Send';
  dataCy: string;
  title: string;
  text: string;
  textClasses: string;
  showCloseButton = true;
  wrappedErrorMessage = false;
  componentType: ComponentType = DownloadAppCardComponent;

  @Output() removeCard = new EventEmitter<RemoveCardEvent>();

  formGroup: FormGroup;
  formControlName = 'phoneNumber';
  formErrorMessage: string;

  submitting: boolean;
  submitted: boolean;
  currentButtonText: string;

  deviceSpecificRedirectUrl: string;

  private destroy$ = new Subject();

  ngOnInit(): void {
    this.setInitialFormState();
    this.pageState = PageState.SUCCESS;
    const phoneNumberControl = new FormControl({ value: undefined, disabled: false }, [Validators.required]);

    this.formGroup = new FormGroup({
      phoneNumber: phoneNumberControl,
    });

    const phoneControl = this.formGroup.controls[this.formControlName];

    this.userService.user$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (user: User) => {
        phoneControl.setValue(user.phoneNumber);
      },
    });
    this.userService.getUser();

    this.deviceSpecificRedirectUrl = this.appStoreRedirectService.redirectUrl;
  }

  onSubmit(): void {
    const phoneNumber = `+1${this.formGroup.controls[this.formControlName].value}`;
    this.setSubmittingFormState();
    this.setErrorMessage(undefined);

    this.sendAppLinkGraphQL
      .mutate({ input: { phoneNumber: phoneNumber } })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: FetchResult<SendAppLink>) => {
          const { success, errors } = response.data.sendAppLink;

          if (success) {
            this.setSubmittedFormState();

            setTimeout(() => {
              this.setInitialFormState();
            }, 5000);
          } else {
            this.setErrorMessage(errors.join(', '));
            this.setInitialFormState();
          }
        },
        error: (error: Error) => {
          this.setErrorMessage(error.message);
          this.setInitialFormState();
        },
      });
  }

  dismissCard(event: MouseEvent) {
    event.preventDefault();
    this.removeCard.emit({ component: this.componentType, storageKey: this.componentType.StorageKey });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setErrorMessage(errorMessage: string) {
    this.formErrorMessage = errorMessage;
  }

  private setSubmittedFormState() {
    this.submitting = false;
    this.submitted = true;
    this.currentButtonText = 'Link Sent';
  }

  private setSubmittingFormState() {
    this.submitting = true;
    this.submitted = false;
    this.currentButtonText = 'Sending...';
  }

  private setInitialFormState() {
    this.submitting = false;
    this.submitted = false;
    this.currentButtonText = this.buttonText;
  }
}
