<div ngbDropdown #dropdown="ngbDropdown" autoClose="outside" (openChange)="onTouch()">
  <button ngbDropdownToggle [id]="id" type="button" class="btn btn-alternate dropdown-toggle" data-cy="dropdown-toggle"
    ><span *ngIf="!selectedValue" class="text-gray-sand">{{ placeholder }}</span
    >{{ selectedValue }}</button
  >
  <!-- the placeholder span allows applying a color style to the text without affecting the dropdown triangle -->
  <div ngbDropdownMenu data-cy="dropdown-menu">
    <button
      *ngFor="let value of allValues"
      ngbDropdownItem
      (click)="selectValue(value, $event, dropdown)"
      (keydown.enter)="selectValue(value, $event, dropdown)"
      type="button"
      data-cy="dropdown-item"
    >
      {{ value }}
    </button>
  </div>
</div>
