import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

import { SurveySummary, SurveySummaryVariables } from './__generated__/SurveySummary';

export const healthHistoryAdultSurveyId = '1';
export const healthHistoryPediatricSurveyId = '2';

@Injectable()
export class SurveySummaryGraphQL extends Query<SurveySummary, SurveySummaryVariables> {
  answerFields = gql`
    fragment answerFields on SurveyAnswer {
      id
      answerText
      isCustom
      kind
      singletonAnswer
      __typename
    }
  `;

  questionFields = gql`
    fragment questionFields on SurveyQuestion {
      id
      allowMultipleAnswers
      helperPrompt
      iconKey
      placeholderAnswer
      text
      context
      required
      tocHeader
      automaticallyCalculatedAnswers {
        answerId
      }
      suggestedAnswers {
        answerId
      }
      __typename
    }
  `;

  document = gql`
    query SurveySummary($surveyId: ID!) {
      surveySummary(surveyId: $surveyId) {
        alreadyConfirmed
        lastPatientSurvey {
          id
          outro {
            header
            body
          }
        }
        survey {
          id
          allowMultiplePerPatient
          instructions
          introduction
          name
          sections {
            header
            questions {
              ...questionFields
              answers {
                ...answerFields
                dependentQuestion {
                  ...questionFields
                  answers {
                    ...answerFields
                    dependentQuestion {
                      ...questionFields
                      answers {
                        ...answerFields
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    ${this.answerFields}
    ${this.questionFields}
  `;
}
