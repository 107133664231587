<div>
  <div class="pt-3 d-md-block d-none">
    <div class="float-left ml-3" *ngIf="canGoBack" (click)="onBack()">
      <om-arrow-left size="large"> </om-arrow-left>
    </div>
    <small *ngIf="showLoginLink" class="float-right mr-3">
      <a href="" (click)="onLogIn($event)" data-cy="progress-bar-login">Already a member? Log in</a>
    </small>
    <om-logo class="brand mx-auto"></om-logo>
    <ngb-progressbar class="d-block mt-3" [height]="progressBarHeight" [value]="percentComplete"></ngb-progressbar>
  </div>
  <div class="pt-3 d-md-none d-block">
    <div>
      <div class="float-left ml-3" *ngIf="canGoBack" (click)="onBack()">
        <om-arrow-left size="large"> </om-arrow-left>
      </div>
      <om-logo class="brand mx-auto"></om-logo>
    </div>
    <ngb-progressbar class="d-block mt-3" [height]="progressBarHeight" [value]="percentComplete"></ngb-progressbar>
    <small *ngIf="showLoginLink" class="border-bottom d-block p-2 text-center">
      <a href="" (click)="onLogIn($event)">Already a member? Log in</a>
    </small>
  </div>
</div>
