<form [formGroup]="form" [ngClass]="{ 'd-flex justify-content-start': inline }">
  <div
    class="my-2 d-flex"
    [class.submitting]="submitting"
    [class.mr-5]="inline && !last"
    *ngFor="let option of options; let last = last"
  >
    <div class="radio-button-container">
      <input
        [id]="option.elementId"
        type="radio"
        (click)="onClick(option)"
        [formControlName]="option.formControlName"
        [value]="option.value"
      />
      <span
        class="radio-button"
        [class.checked]="option.checked()"
        [class.disabled]="option.disabled"
        aria-hidden="true"
      ></span>
    </div>
    <label class="radio-label" [for]="option.elementId">{{ option.label }}</label>
  </div>
</form>
