import { Injectable } from '@angular/core';
import camelCase from 'lodash-es/camelCase';
import debounce from 'lodash-es/debounce';
import isEmpty from 'lodash-es/isEmpty';
import noop from 'lodash-es/noop';
import { BehaviorSubject, Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, filter, map, take, tap } from 'rxjs/operators';

import { ParamValueMap } from '@app/shared';
import { Address } from '@app/shared/address';

import { EnterpriseRegistrationDetails, User } from '../shared/user';
import { AnalyticsService } from './analytics.service';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';

interface PatientApiV2Response {
  token: string;
  patient: SerializedApiV2Patient;
}

export class SerializedApiV2EnterpriseRegistrationDetails {
  static readonly MAPPING_EXCEPTIONS = {
    discount_code: 'activationCode',
    b2b_company_id: 'b2bCompanyId',
  };

  b2b_company_id: number = null;
  discount_code: string = null;
  work_email: string = null;
  membership_type: string = null;
  whitelisted_employee_id: number = null;
  service_area_code: string = null;

  static fromEnterpriseRegistrationDetails(
    details: EnterpriseRegistrationDetails,
  ): SerializedApiV2EnterpriseRegistrationDetails | undefined {
    if (!details) {
      return undefined;
    }

    const serializedDetails = new SerializedApiV2EnterpriseRegistrationDetails();

    Object.keys(serializedDetails).forEach(attr => {
      serializedDetails[attr] = details[this.MAPPING_EXCEPTIONS[attr] || camelCase(attr)] || null;
    });

    return serializedDetails;
  }
}

export class SerializedApiV2Address {
  static readonly MAPPING_EXCEPTIONS = {
    state_code: 'state',
    is_preferred: 'preferred',
  };

  id: number = null;
  address1: string = null;
  address2: string = null;
  city: string = null;
  zip: string = null;
  state_id: number = null;
  state_code: string = null;
  is_preferred: boolean = null;

  static fromAddress(address: Address): SerializedApiV2Address | undefined {
    if (!address || isEmpty(address)) {
      return undefined;
    }

    const serializedAddress = new SerializedApiV2Address();

    Object.keys(serializedAddress).forEach(attr => {
      serializedAddress[attr] = address[this.MAPPING_EXCEPTIONS[attr] || camelCase(attr)] || null;
    });

    return serializedAddress;
  }
}

export class SerializedApiV2Patient {
  static readonly MAPPING_EXCEPTIONS = {
    member_since_at: 'memberSince',
    date_of_birth: 'dob',
    sex: 'gender',
    emp_name: 'employerName',
  };

  // Initialize these values to null so they can be looped through and set at runtime using Object.keys
  id: number = null;
  accessToken: string = null;
  first_name: string = null;
  last_name: string = null;
  preferred_name: string = null;
  nickname: string = null;
  gender_details: string = null;
  date_of_birth: string = null;
  phone_number: string = null;
  email: string = null;
  sex: string = null;
  preferred_email: string = null;
  password: string = null;
  service_area_id: number = null;
  terms_of_service_accepted: boolean = null;
  hearabout_id: number = null;
  hearabout_other: string = null;
  profile_image_url: string = null;
  type: string = null;
  member_since_at: string = null;
  office_id: number = null;
  emp_name: string = null;
  pending_enterprise_reg_id: number = null;
  has_access_to_onsite: boolean = null;
  is_direct_signup_eligible: boolean = null;
  referral_link_web_social: string = null;
  age_in_years: number = null;
  has_unregistered_dependents: boolean = null;
  whitelisted_employee: boolean = null;
  membership_id: number = null;
  same_address: boolean = null;
  'g-recaptcha-response': string;
  reg_flow_version: string = null;

  address: SerializedApiV2Address;
  enterprise_registration: SerializedApiV2EnterpriseRegistrationDetails;

  static fromPatient(patient: User, recaptchaToken: string | null): SerializedApiV2Patient {
    const serializedPatient = new SerializedApiV2Patient();

    Object.keys(serializedPatient).forEach(attr => {
      serializedPatient[attr] = patient[this.MAPPING_EXCEPTIONS[attr] || camelCase(attr)] || null;
    });

    serializedPatient.service_area_id = patient.serviceArea && patient.serviceArea.id;
    serializedPatient.address = SerializedApiV2Address.fromAddress(patient.address);
    serializedPatient.enterprise_registration = SerializedApiV2EnterpriseRegistrationDetails.fromEnterpriseRegistrationDetails(
      patient.enterpriseRegistrationDetails,
    );

    if (recaptchaToken) {
      serializedPatient['g-recaptcha-response'] = recaptchaToken;
    }

    return serializedPatient;
  }
}

@Injectable()
export class UserService {
  private _user$ = new BehaviorSubject<User>(null);

  readonly user$: Observable<User>;

  _debouncedGetUser;
  _debouncedCreatePediatricUserForNonmember: (user: User, discountCode: string) => Observable<User>;
  _debouncedCreatePediatricUserForEnterpriseNonmember: (
    user: User,
    enterpriseRegistrationDetails: EnterpriseRegistrationDetails,
  ) => Observable<User>;

  _debouncedCreatePediatricUserForConsumerNonmember: (user: User) => Observable<User>;
  _debouncedCreatePediatricUserForPatient: (user: User, planId: number) => Observable<User>;

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private analyticsService: AnalyticsService,
  ) {
    this.user$ = this._user$.asObservable().pipe(
      filter(user => user != null),
      tap((user: User) => this.analyticsService.identifyAndUpdateUser(user)),
    );

    this._debouncedGetUser = debounce(this._getUser.bind(this), 1000, { leading: true });
    this._debouncedCreatePediatricUserForNonmember = debounce(this._createPediatricUserForNonmember.bind(this), 1000, {
      leading: true,
    });
    this._debouncedCreatePediatricUserForEnterpriseNonmember = debounce(
      this._createPediatricUserForEnterpriseNonmember.bind(this),
      1000,
      {
        leading: true,
      },
    );
    this._debouncedCreatePediatricUserForPatient = debounce(this._createPediatricUserForPatient.bind(this), 1000, {
      leading: true,
    });

    this._debouncedCreatePediatricUserForConsumerNonmember = debounce(
      this._createPediatricUserForConsumerNonmember.bind(this),
      1000,
      {
        leading: true,
      },
    );
  }

  getUser(force = false) {
    let req;

    if (force) {
      req = this._getUser();
    } else if (this._user$.getValue() == null) {
      req = this._debouncedGetUser();
    }

    return req;
  }

  createUser(patient: User, recaptchaToken: string): Observable<User> {
    // Many of the calls in this service add a lot of logic in order to handle debouncing. It was unclear why
    // this was needed, so we don't handle it here. Investigate if we need to add it back.

    return this.apiService
      .post('/api/v2/public/patients', SerializedApiV2Patient.fromPatient(patient, recaptchaToken))
      .pipe(
        tap((response: PatientApiV2Response) => this.authService.setToken(response.token)),
        map((response: PatientApiV2Response) => {
          response.patient.accessToken = response.token;
          return response;
        }),
        map((response: PatientApiV2Response) => User.fromApiV2(response.patient)),
        catchError(this.handleUserCreationError),
      );
  }

  createDirectSignupUser(patient: User): Observable<User> {
    return this.apiService
      .post('/api/v2/public/patients/create_direct_signup', SerializedApiV2Patient.fromPatient(patient, null))
      .pipe(
        map((response: PatientApiV2Response) => User.fromApiV2(response.patient)),
        catchError(this.handleDirectSignupError),
      );
  }

  submitEnterpriseConversion(patient: User, reCaptchaToken: string): Observable<any> {
    const requestBody = {
      'g-recaptcha-response': reCaptchaToken,
      enterprise_registration: SerializedApiV2EnterpriseRegistrationDetails.fromEnterpriseRegistrationDetails(
        patient.enterpriseRegistrationDetails,
      ),
    };

    return this.apiService.post('/api/v2/public/patients/enterprise_conversion', requestBody);
  }

  createPediatricUserForLegacyEnterpriseNonmember(
    user: User,
    planId: number,
    pendingEnterpriseRegistrationUUID: string,
    force = false,
  ): Observable<User> {
    if (force) {
      return this._createPediatricUserForNonmember(user, pendingEnterpriseRegistrationUUID);
    } else {
      return this._debouncedCreatePediatricUserForNonmember(user, pendingEnterpriseRegistrationUUID);
    }
  }

  createPediatricUserForConsumerNonmember(user: User, force = false): Observable<User> {
    if (force) {
      return this._createPediatricUserForConsumerNonmember(user);
    } else {
      return this._debouncedCreatePediatricUserForConsumerNonmember(user);
    }
  }

  createPediatricUserForEnterpriseNonmember(
    user: User,
    enterpriseRegistrationDetails: EnterpriseRegistrationDetails,
    force = false,
  ): Observable<User> {
    if (force) {
      return this._createPediatricUserForEnterpriseNonmember(user, enterpriseRegistrationDetails);
    } else {
      return this._debouncedCreatePediatricUserForEnterpriseNonmember(user, enterpriseRegistrationDetails);
    }
  }

  createPediatricUserForPatient(user: User, planId?: number, force = false): Observable<User> {
    if (force) {
      return this._createPediatricUserForPatient(user, planId);
    } else {
      return this._debouncedCreatePediatricUserForPatient(user, planId);
    }
  }

  updateUserProfile(data) {
    return this.apiService.patch('/api/v2/patient/profile', data).pipe(catchError(this.handleUserUpdateError));
  }

  updateUser(params: ParamValueMap) {
    const request = this.apiService.patch('/api/v2/user', params).pipe(
      map(response => User.fromApiV2(response)),
      tap((user: User) => this._user$.next(user)),
    );

    request.subscribe({ error: noop });

    return request;
  }

  updateRegistration(params: ParamValueMap) {
    const request = this.apiService.post('/api/v2/user/update_registration', params).pipe(
      map(response => User.fromApiV2(response)),
      tap((user: User) => this._user$.next(user)),
    );

    request.subscribe({ error: noop });

    return request;
  }

  mergeUserWithGraphQLResponse(mergeResponse: (User) => void): void {
    const user = this._user$.getValue();

    mergeResponse(user);

    this._user$.next(user);
  }

  private _getUser() {
    const req = this.apiService.get('/api/v2/user.json').pipe(map(user => User.fromApiV2(user)));
    req.subscribe(user => {
      this._user$.next(user);
    });
    return req;
  }

  private handleUserCreationError(response) {
    const message =
      "We've encountered an issue creating your account. Please try again. If this issue persists, please email us at admin@onemedical.com";

    return observableThrowError(message);
  }

  private handleDirectSignupError(response) {
    if (response.error) {
      return observableThrowError(response.error.error);
    } else {
      return observableThrowError(
        "We've encountered an issue creating the account. Please try again. If this issue persists, please email us at admin@onemedical.com",
      );
    }
  }

  private handlePediatricCreationError(response) {
    const message =
      "We've encountered an issue creating your account. Please try again. If this issue persists, please email us at admin@onemedical.com";

    return observableThrowError({ message: message, error: response.error });
  }

  private handleUserUpdateError(response) {
    const message =
      'We seem to have run into an issue saving your information. Please try again. If this issue persists, please email us at admin@onemedical.com';

    return observableThrowError(message);
  }

  private _createPediatricUserForNonmember(
    patient: User,
    pendingEnterpriseRegistrationUUID?: string,
  ): Observable<User> {
    const params = {
      patient: User.forPediatricApiV2(patient, null),
      uuid: pendingEnterpriseRegistrationUUID,
    };

    const postCall = this.apiService.post('/api/v2/public/pediatric/patients', params);

    this.setToken(postCall);

    return this._postCreateUserForPatient(postCall);
  }

  private _createPediatricUserForConsumerNonmember(patient: User): Observable<User> {
    const postCall = this.apiService.post('/api/v2/public/pediatric/patients', {
      patient: User.forPediatricApiV2(patient, null),
    });

    this.setToken(postCall);

    return this._postCreateUserForPatient(postCall);
  }

  private _createPediatricUserForEnterpriseNonmember(
    patient: User,
    enterpriseRegistrationDetails: EnterpriseRegistrationDetails,
  ): Observable<User> {
    const params = {
      patient: User.forPediatricApiV2(patient, null),
      enterprise_registration: SerializedApiV2EnterpriseRegistrationDetails.fromEnterpriseRegistrationDetails(
        enterpriseRegistrationDetails,
      ),
    };

    const postCall = this.apiService.post('/api/v2/public/pediatric/patients', params);

    this.setToken(postCall);

    return this._postCreateUserForPatient(postCall);
  }

  private _createPediatricUserForPatient(patient: User, planId: number): Observable<User> {
    const params = {
      patient: User.forPediatricApiV2(patient, null),
      ...(planId && { b2b_plan_id: planId }),
    };

    const postCall = this.apiService.post('/api/v2/patient/pediatric/patients', params);
    return this._postCreateUserForPatient(postCall);
  }

  private _postCreateUserForPatient(observable) {
    return observable.pipe(
      catchError(this.handlePediatricCreationError),
      map((response: object) => User.fromApiV2(response)),
    );
  }

  private setToken(postCall) {
    postCall.pipe(take(1)).subscribe(
      response => {
        this.authService.setToken(response['token']);
      },
      error => {},
    );
  }
}
