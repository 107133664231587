import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '@app/core/auth.service';
import { LinksService } from '@app/core/links.service';

interface secondaryCtaOptionsInterface {
  type: string;
  message: string;
}

@Component({
  selector: 'om-redirect-prompt-modal',
  templateUrl: './redirect-prompt-modal.component.html',
  styleUrls: ['./redirect-prompt-modal.component.scss'],
})
export class RedirectPromptModalComponent {
  constructor(
    private authService: AuthService,
    private linksService: LinksService,
    private modal: NgbActiveModal,
    private router: Router,
  ) {}

  @Input() cta = 'Continue';
  @Input() logoutBeforeRedirect = true;
  @Input() message: string;
  @Input() redirectLinkKey: string;
  @Input() redirectQueryParams: object = {};
  @Input() title: string;
  // secondary cta - if "type = close", then it's a link to close modal
  // potentially in the future we can add "type = redirect" with additional redirect links
  @Input() secondaryCtaOptions: secondaryCtaOptionsInterface = {
    type: '',
    message: '',
  };

  closeModal() {
    this.modal.close();
  }

  redirect() {
    if (this.logoutBeforeRedirect) {
      const redirectUrl = this.linksService.constructWithQueryParams(this.redirectLinkKey, this.redirectQueryParams);
      this.authService.logout(redirectUrl);
    } else {
      this.router.navigate([this.linksService[this.redirectLinkKey]], { queryParams: this.redirectQueryParams });
    }

    this.closeModal();
  }
}
