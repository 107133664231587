<label class="omgui-checkbox-container">
  <input
    type="checkbox"
    [id]="inputId"
    [attr.name]="name"
    [attr.value]="value"
    [attr.disabled]="disabled ? 'disabled' : null"
    [(ngModel)]="_checked"
    (ngModelChange)="handleChange($event)"
    (blur)="handleTouched()"
  />{{ label }}<span class="omgui-checkbox-checkmark"></span>
</label>
