import { Component, Input } from '@angular/core';

@Component({
  selector: 'om-svg-eye-open',
  templateUrl: './svg-eye-open.component.html',
})
export class SvgEyeOpenComponent {
  @Input() color = '#0358b2';
  @Input() width = 22;
  @Input() height = 14;
}
