import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

/*
 * This component must be used with a form control, but it is possible to make
 * this "optional" by extracting a super class that does not implement
 * ControlValueAccessor (the child would) and giving it the selector 'om-dropdown'.
 * By using the same styles and templates, our forms will look uniform. This would
 * require adding some inputs and outputs to the super class for interactivity between
 * components.
 * */
@Component({
  selector: 'om-dropdown[formControlName], om-dropdown[formControl]',
  templateUrl: './dropdown-form.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DropdownFormComponent),
    },
  ],
})
export class DropdownFormComponent<T> implements ControlValueAccessor {
  @Input() allValues: T[];
  @Input() id?: string; // Provide an id if you want to attach a <label> to the dropdown
  @Input() placeholder = 'Select';
  selectedValue?: T;
  onChange: Function;
  onTouched: Function;

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: T): void {
    this.selectedValue = value;
  }

  selectValue(newValue: T, event: MouseEvent, dropdown: NgbDropdown) {
    if (event.type === 'click') {
      event.preventDefault();
      dropdown.close();
    }
    this.onChange(newValue);
    this.selectedValue = newValue;
  }

  onTouch() {
    this.onTouched();
  }
}
