import { Profile } from './profile-bubble/profile-bubble.component';

export class Provider implements Profile {
  id: number;
  name: string;
  firstName: string;
  preferredName: string;
  lastName: string;
  profileImageUrl: string;
  type: string;
  suffix: string;
  outOfOffice: boolean;
  returnDate: string;
  email: string;
  displayName: string;
  colleagues: Provider[];
  serviceAreaId: number;
  preparedName: string;
  acceptingNewPatients: boolean;

  constructor() {}

  static preparedName(provider) {
    return provider.firstName + ' ' + provider.lastName + (provider.suffix ? ', ' + provider.suffix : '');
  }

  static fromGraphQL(object: any): Provider {
    const provider = new Provider();
    provider.id = object.id;
    provider.displayName = object.displayName;
    provider.profileImageUrl = object.profileImageUrl;
    return provider;
  }

  static fromApiV2(providerObject): Provider {
    const provider = new Provider();
    provider.id = providerObject.id;
    provider.name = providerObject.name;
    provider.firstName = providerObject.first_name;
    provider.preferredName = providerObject.first_name;
    provider.lastName = providerObject.last_name;
    provider.type = providerObject.type;
    provider.suffix = providerObject.suffix;
    provider.outOfOffice = providerObject.out_of_office;
    provider.returnDate = providerObject.return_date;
    provider.email = providerObject.email;
    provider.displayName = providerObject.display_name;
    provider.serviceAreaId = providerObject.service_area_id;
    provider.acceptingNewPatients = providerObject.accepting_new_patients;

    provider.profileImageUrl = providerObject.profile_image_url;
    if (!provider.profileImageUrl && providerObject.image) {
      provider.profileImageUrl = providerObject.image;
    }

    provider.preparedName = Provider.preparedName(provider);

    if (providerObject.colleagues) {
      provider.colleagues = providerObject.colleagues.map(colObj => Provider.fromApiV2(colObj));
    }

    return provider;
  }

  toApiV2() {
    const apiV2 = {
      id: this.id,
      name: this.name,
      first_name: this.firstName,
      last_name: this.lastName,
      profile_image_url: this.profileImageUrl,
      type: this.type,
      suffix: this.suffix,
      out_of_office: this.outOfOffice,
      return_date: this.returnDate,
      email: this.email,
      display_name: this.displayName,
      service_area_id: this.serviceAreaId,
    };

    if (this.colleagues) {
      apiV2['colleagues'] = this.colleagues.map(colleague => colleague.toApiV2());
    }

    return apiV2;
  }
}
