<div class="d-flex align-items-center">
  <om-profile-bubble
    [profile]="provider"
    size="medium-small"
    class="p-3"
    [colorCombo]="provider.id"
  ></om-profile-bubble>
  <div class="provider-detail">
    <h4 class="align-self-baseline font-weight-bold m-0">{{ provider.displayName }}</h4>
    <p class="font-weight-bold specialty-text"></p>
  </div>
</div>
