<div class="card hide-border-bottom" @enter [@cardContent]="pageState">
  <div class="card-body" @enter *ngIf="isSuccessState(pageState)">
    <h5 class="card-title">
      <div class="inline-icon-with-text">
        <om-svg-info class="mr-3 d-inline-block d-md-none" width="18" height="18" stroke="currentColor"></om-svg-info>
        <om-svg-info class="mr-3 d-none d-md-inline-block" stroke="currentColor"></om-svg-info>
        <ng-template [ngTemplateOutlet]="header.templateRef"></ng-template>
      </div>
    </h5>
    <div class="body"> <ng-template [ngTemplateOutlet]="body.templateRef"></ng-template> </div>
  </div>
</div>
