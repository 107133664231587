import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

export interface AppointmentBookingSession {
  id: number;
  appointmentBookingStateCache: AppointmentBookingStateCache;
}

export interface VisitReasonCategory {
  categorizableId: number;
  categorizableType: string;
  matchedText: string;
}

export interface AppointmentBookingState {
  commonConcern: string;
  providerType: string;
  isAppointmentRecommended: boolean;
  searchDateStart: Date;
  searchDateEnd: Date;
  appointmentCancellationReason: AppointmentCancellationReason;
  appointmentType: AppointmentType;
  fromAppointment: Appointment;
  provider: Provider;
  selectedReasons: SelectedReason[];
  serviceArea: ServiceArea;
}

export interface AppointmentBookingStateCache {
  appointmentCategory: string;
  appointmentDate: Date;
  appointmentReason: string;
  appointmentTypeId: number;
  firstAvailable: boolean;
  providerId: number;
  serviceAreaId: number;
  visitReasonCategories: VisitReasonCategory[];
  appointmentBookingState: AppointmentBookingState;
}

export interface AppointmentCancellationReason {
  id: number;
  displayReason: string;
}

export interface AppointmentType {
  id: number;
  description: Text;
  displayName: string;
  firstAppointmentSelected: boolean;
  firstVisitAppointmentTypeId: number;
  isSpecialty: boolean;
  remote: boolean;
  position: number;
}

export interface Appointment {
  id: number;
  startTime: Date;
  officeName: string;
  providerName: string;
}

export interface Provider {
  id: number;
  firstName: string;
  lastName: string;
  profileImageUrl: string;
  suffix: string;
  outOfOffice: Date;
  returnDate: Date;
  email: string;
  displayName: string;
  serviceArea: IDOnly;
}

export interface SelectedReason {
  text: string;
  visitReasonCategory: VisitReasonCategory;
}

export interface ServiceArea {
  id: number;
  name: string;
  code: string;
  defaultOffice: {
    id: number;
    name: string;
  };
  registrationHidden: boolean;
}

export interface IDOnly {
  id: number;
}

export interface AppointmentBookingSessionResponse {
  patient: {
    appointmentBookingSession: AppointmentBookingSession;
  };
}

@Injectable({
  providedIn: 'root',
})
export class AppointmentBookingSessionGraphQL extends Query<AppointmentBookingSessionResponse> {
  document = gql`
    query FetchAppointmentBookingStateCache {
      patient {
        appointmentBookingSession {
          id
          appointmentBookingStateCache {
            appointmentCategory
            appointmentDate
            appointmentReason
            appointmentTypeId
            firstAvailable
            providerId
            serviceAreaId
            task
            visitReasonCategories {
              categorizableId
              categorizableType
              matchedText
            }
            appointmentBookingState {
              commonConcern
              providerType
              isAppointmentRecommended
              searchDateStart
              searchDateEnd
              appointmentCancellationReason {
                id
                displayReason
              }
              appointmentType {
                id
                description
                displayName
                firstAppointmentSelected
                firstVisitAppointmentTypeId
                isSpecialty
                remote
                position
              }
              fromAppointment {
                id
                startTime
                officeName
                providerName
              }
              provider {
                id
                serviceArea {
                  id
                  name
                }
              }
              selectedReasons {
                text
                visitReasonCategory {
                  categorizableId
                  categorizableType
                  matchedText
                }
              }
              serviceArea {
                id
                name
                defaultOffice {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  `;
}
