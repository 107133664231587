import {
  Component,
  OnInit,
  ViewChild,
  Input,
  ContentChildren,
  QueryList,
  ElementRef,
  AfterViewInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'om-card-slider',
  styleUrls: ['./card-slider.component.scss'],
  templateUrl: 'card-slider.component.html',
})
export class CardSliderComponent implements OnInit, AfterViewInit {
  /** Reference to the main scroll container to control the scroll position */
  @ViewChild('scrollBox', { static: true }) scrollBox: ElementRef;

  /** Reference to the child card elements. Must add the #cardSliderContent template ref to allow for stepWise scrolling */
  @ContentChildren('cardSliderContent', { read: ElementRef }) cardSliderContent: QueryList<ElementRef>;

  /**
   * Sets the scroll behavior to advance one card at a time.
   *
   * Note: You must add the `#cardSliderContent` template tag to the child content for this to work
   */
  @Input() stepWise = false;

  /** Where to place the controls */
  @Input() controlPosition: 'sides' | 'top' = 'sides';

  /** emits event with the direction that was clicked */
  @Output() scrollClicked = new EventEmitter<'Forward' | 'Back'>();

  /** @ignore */
  showRightArrow = true;

  /** @ignore */
  showLeftArrow = false;

  /** @ignore */
  scrollContainerPadding: number;

  constructor() {}

  /** @ignore */
  ngOnInit() {
    fromEvent(this.scrollBox.nativeElement, 'scroll').subscribe((e: Event) => {
      const { scrollLeft, scrollWidth, offsetWidth } = e.target as HTMLElement;
      this.showLeftArrow = scrollLeft > 0;
      this.showRightArrow = scrollWidth - offsetWidth > scrollLeft;
    });
  }

  /** @ignore */
  ngAfterViewInit() {
    this.initScrollArrows();
    this.scrollContainerPadding = this.getComputedPadding(this.scrollBox.nativeElement.firstElementChild);
  }

  /** Scrolls cards to the right */
  scrollToRight() {
    const scrollLeft = this.stepWiseScroll()
      ? this.getNextRightCardScrollPosition()
      : this.scrollBox.nativeElement.scrollWidth;
    this.scrollBox.nativeElement.scrollLeft = scrollLeft;
    this.scrollClicked.emit('Forward');
  }

  /** Scrolls cards to the left */
  scrollToLeft() {
    this.scrollBox.nativeElement.scrollLeft = this.stepWiseScroll() ? this.getNextLeftCardScrollPosition() : 0;
    this.scrollClicked.emit('Back');
  }

  /** Checks if scroll behavior should be stepwise */
  private stepWiseScroll(): boolean {
    return this.stepWise && this.cardSliderContent.length > 0;
  }

  /** Hides scroll arrows if no overflow is detected */
  private initScrollArrows() {
    const { scrollWidth, offsetWidth } = this.scrollBox.nativeElement;

    if (scrollWidth === offsetWidth) {
      setTimeout(() => {
        this.showRightArrow = false;
        this.showLeftArrow = false;
      }, 0);
    }
  }

  // Note: I attempted to use .scrollIntoView(), but the scroll mask and padding behavior obscures the edge of the cards
  /**
   * Finds the card that overlaps the right-side boundary and calculates the next scrollLeft position to scroll that card fully into view
   */
  private getNextRightCardScrollPosition(): number {
    let nextRightCardPosition = 0;
    const { offsetWidth, scrollLeft } = this.scrollBox.nativeElement;
    const rightBoundary = offsetWidth + scrollLeft;

    // Using .some here to short-circuit when the accumulated content width is greater than the current scroll position
    this.cardSliderContent.some(card => {
      nextRightCardPosition += card.nativeElement.offsetWidth + this.getComputedMargin(card.nativeElement);
      return nextRightCardPosition > rightBoundary;
    });

    return nextRightCardPosition + this.scrollContainerPadding - offsetWidth;
  }

  /**
   * When stepwise scrolling to the left, find the card that overlaps the current scrollLeft position and returns the
   * width of all the cards before that card
   */
  private getNextLeftCardScrollPosition(): number {
    let nextLeftCardPosition = 0;
    const currentScrollPosition = this.scrollBox.nativeElement.scrollLeft;

    this.cardSliderContent.some(card => {
      const nextCardOuterWidth = card.nativeElement.offsetWidth + this.getComputedMargin(card.nativeElement);
      if (nextLeftCardPosition + nextCardOuterWidth >= currentScrollPosition) {
        return true;
      }
      nextLeftCardPosition += nextCardOuterWidth;
      return false;
    });
    return nextLeftCardPosition;
  }

  /** Sums the computed left and right margins of an element */
  private getComputedMargin(el: HTMLElement): number {
    const { marginLeft, marginRight } = window.getComputedStyle(el);
    return parseInt(marginLeft, 10) + parseInt(marginRight, 10);
  }

  /** Sums the computed left and right padding of an element */
  private getComputedPadding(el: HTMLElement): number {
    const { paddingLeft, paddingRight } = window.getComputedStyle(el);
    return parseInt(paddingLeft, 10) + parseInt(paddingRight, 10);
  }
}
