import { Injectable } from '@angular/core';
import { Query, gql, Apollo } from 'apollo-angular';

export interface FeatureFlag {
  name: string;
  enabledForDiscountCode: boolean;
  enabledForPendingEnterpriseRegistration: boolean;
  enabledForCompany: boolean;
}

export interface FeatureFlagResponse {
  featureFlag: FeatureFlag;
}

export interface FeatureFlagVariables {
  code: string;
  name: string;
  id: number;
}

export interface B2bIDVariables {
  name: string;
  id: number;
}

export interface PendingEnterpriseRegistrationVariables {
  uuid: string;
  name: string;
}

@Injectable()
export class FeatureFlagGraphQL extends Query<FeatureFlagResponse, FeatureFlagVariables> {
  constructor(apollo: Apollo) {
    super(apollo);
  }

  document = gql`
    query FeatureFlag($name: String!, $code: String!) {
      featureFlag(name: $name) {
        name
        enabledForDiscountCode(code: $code)
      }
    }
  `;
}

@Injectable()
export class FeatureFlagByB2bIDGraphQL extends Query<FeatureFlagResponse, B2bIDVariables> {
  constructor(apollo: Apollo) {
    super(apollo);
  }

  document = gql`
    query FeatureFlagByB2BID($name: String!, $id: Int!) {
      featureFlag(name: $name) {
        name
        enabledForCompany(id: $id)
      }
    }
  `;
}

@Injectable()
export class FeatureFlagByUUIDGraphQL extends Query<FeatureFlagResponse, PendingEnterpriseRegistrationVariables> {
  constructor(apollo: Apollo) {
    super(apollo);
  }

  document = gql`
    query FeatureFlagByUUID($name: String!, $uuid: String!) {
      featureFlag(name: $name) {
        name
        enabledForPendingEnterpriseRegistration(uuid: $uuid)
      }
    }
  `;
}
