<div>
  <om-navbar *ngIf="!shouldHideNavigation"></om-navbar>
  <om-expired-membership-banner></om-expired-membership-banner>
  <om-flash></om-flash>
  <om-toast></om-toast>
  <ngb-alert class="text-center" type="success" *ngIf="notice && !closed" (close)="closed = true">{{
    notice
  }}</ngb-alert>
</div>

<div class="flex-grow-1"><router-outlet></router-outlet></div>

<om-footer [homePage]="onHomePage"></om-footer>
