import { Injectable } from '@angular/core';
import { createFeatureSelector, createSelector, Store } from '@ngrx/store';
import { noop } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { FeatureFlagActions } from '@app/core/feature-flags/feature-flag.actions';
import { FeatureFlagState } from '@app/core/feature-flags/feature-flag.reducer';

export const selectFeatureFlagState = createFeatureSelector<FeatureFlagState>('featureFlags');

export const getFeatureFlag = createSelector(
  selectFeatureFlagState,
  (state: FeatureFlagState, props) => state.flags[props.flag],
);

export const getIsLDClientInitialized = createSelector(
  selectFeatureFlagState,
  (state: FeatureFlagState) => state.isLDClientInitialized,
);

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagSelectors {
  constructor(private store: Store<FeatureFlagState>, private featureFlagActions: FeatureFlagActions) {}

  getFeatureFlag(flag: string, defaultValue?: any) {
    return this.store.select(getFeatureFlag, { flag }).pipe(
      tap(v => (v === undefined ? this.featureFlagActions.loadFeatureFlag(flag, defaultValue) : noop())),
      filter(v => v !== undefined),
    );
  }
}
