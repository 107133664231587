import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Directive()
export abstract class RegistrationStepDirective {
  @Input() form: FormGroup;
  @Input() isLoggedIn = false;
  @Output() login: EventEmitter<any> = new EventEmitter<any>();
  @Output() submit: EventEmitter<any> = new EventEmitter<any>();
  submitting = false;

  onError(_err: Error) {
    this.submitting = false;
  }
}
