import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';

import { userLoggedIn, userLoggedOut } from '@app/auth/auth.actions';
import { ApiHeaderService } from '@app/core/api-header.service';
import { Auth0AuthService } from '@app/core/auth0-auth.service';
import { AnonymousFlagDataService, AnonymousFlagName } from '@app/shared/anonymous-flag-data.service';

import { OnelifeAuthService } from './onelife-auth.service';
import { RollbarService } from './rollbar.service';

export interface AuthServiceImplementation {
  /**
   * fetch the token from the implementation
   * not meant to be done on every request (I understand it to be an api call)
   */
  getToken(): Observable<string>;

  /**
   * check if there is an authentication token for the user
   */
  isAuthenticated(): Observable<boolean>;

  /**
   * logout should remove authorization and
   * redirect the user to an appropriate location, e.g. '/login' by default
   */
  logout(returnURI?: string): void;

  /**
   * set the token in app, and hook into anything required in the implementation
   */
  setToken(token): void;

  /**
   * Initialize() - method to initialize the logged in state based upon the implementation
   */
  init(): Observable<boolean>;

  /**
   * navigate to login, return to this url (else '/')
   */
  goLogin(url): void;
}

@Injectable({
  providedIn: 'root',
})
/**
 * Class AuthService - parent, the api interface that can hande auth, exposed to all the apps
 */
export class AuthService implements AuthServiceImplementation {
  implementation: AuthServiceImplementation;
  private _initialized$ = new Subject<boolean>();
  readonly initialized$: Observable<boolean> = this._initialized$.pipe(shareReplay(1));

  constructor(
    private anonymousFlagDataService: AnonymousFlagDataService,
    private apiHeaderService: ApiHeaderService,
    private auth0AuthService: Auth0AuthService,
    private onelifeAuthService: OnelifeAuthService,
    private rollbarService: RollbarService,
    private store: Store,
  ) {
    // this.initialized$ = this._initialized$.pipe(shareReplay(1));
    this.initialized$.subscribe();
    this.init();
  }

  getToken() {
    // consider updating local token stash in apiHeaderService
    const getToken$ = this.initialized$.pipe(switchMap(() => this.implementation.getToken()));
    getToken$.subscribe();
    return getToken$;
  }

  goLogin(url = '/') {
    this.apiHeaderService.revokeAccessToken();
    const goLogin$ = this.initialized$.pipe(map(() => this.implementation.goLogin(url)));
    goLogin$.subscribe();
  }

  isAuthenticated() {
    return this.implementation.isAuthenticated();
  }

  init() {
    this.anonymousFlagDataService
      .enabledByName$(AnonymousFlagName.AUTH0_WEB)
      .pipe(
        map(auth0WebEnabled => {
          const useAuth0 = this.auth0AuthService.configured && auth0WebEnabled;
          this.implementation = useAuth0 ? this.auth0AuthService : this.onelifeAuthService;
          if (auth0WebEnabled && !this.auth0AuthService.configured) {
            const message = 'Missing Auth0 configuration, while auth0_web is enabled';
            try {
              (<any>this.rollbarService).error(message);
            } catch (error) {
              console.error('Rollbar failure to report: ');
              console.error(message);
            }
          }
        }),
        switchMap(() => this.implementation.init()),
        switchMap(() => this.implementation.getToken()),
        map(token => {
          this.setToken(token);
          this._initialized$.next(true);
        }),
      )
      .subscribe();
    return this.initialized$;
  }

  logout(returnURI?: string) {
    this.apiHeaderService.revokeAccessToken();
    const logout$ = this.initialized$.pipe(map(() => this.implementation.logout()));
    logout$.subscribe();
    this.store.dispatch(userLoggedOut());
    this.goLogin(returnURI);
  }

  setToken(token) {
    if (!token) {
      this.apiHeaderService.revokeAccessToken();
      return;
    }
    this.apiHeaderService.setAccessToken(token);
    this.implementation.setToken(token);
    this.store.dispatch(userLoggedIn());
  }

  isTokenBlank() {
    return !this.apiHeaderService.token;
  }

  isLoggedIn() {
    return !!this.apiHeaderService.token;
  }
}
