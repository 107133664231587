import { AgmInfoWindow } from '@agm/core';
import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import {
  usCenterLatitude,
  usCenterLongitude,
  usRestriction,
  serviceAreaMapStyles,
} from '@app/shared/google-maps-values';
import { ServiceArea } from '@app/shared/service-area';
import { ServiceAreaService } from '@app/shared/service-area.service';

import { EnterpriseRegistrationAnalyticsService } from '../enterprise-registration-analytics.service';
import { RegistrationStepDirective } from '../registration-step.directive';

@Component({
  selector: 'om-service-area-selection-step',
  styleUrls: ['../../../shared/form-input.scss', './service-area-selection-step.component.scss'],
  templateUrl: './service-area-selection-step.component.html',
})
export class ServiceAreaSelectionStepComponent extends RegistrationStepDirective implements OnInit, OnDestroy {
  @HostBinding('className') _flexFill = 'flex-fill d-flex';
  @Input() numberOfOffices: number;

  MAP_ICON_ACTIVE = '/assets/images/map-pin-active.svg';
  MAP_ICON_INACTIVE = '/assets/images/map-pin-inactive.svg';

  private destroy$ = new Subject();
  private notifyForServiceAreaCodes: String[];
  hasVirtualServiceAreas$: Observable<boolean>;

  centerLatitude = usCenterLatitude;
  centerLongitude = usCenterLongitude;
  usRestriction = usRestriction;
  mapStyles = serviceAreaMapStyles;

  @ViewChildren(AgmInfoWindow) mapInfoWindows: QueryList<AgmInfoWindow>;
  @ViewChild('virtualServiceAreaModal', { static: true }) virtualServiceAreaModal: ElementRef;

  constructor(
    public serviceAreaService: ServiceAreaService,
    private modalService: NgbModal,
    private analytics: EnterpriseRegistrationAnalyticsService,
  ) {
    super();
  }

  ngOnInit() {
    const { b2bCompanyId } = this.form.parent.value.workEmail;
    this.serviceAreaService.allServiceAreaOptions$
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        serviceAreas =>
          (this.notifyForServiceAreaCodes = serviceAreas.filter(area => area.virtual).map(area => area.code)),
      );

    this.hasVirtualServiceAreas$ = this.serviceAreaService.virtualServiceAreas$.pipe(
      takeUntil(this.destroy$),
      map(virtualServiceAreas => virtualServiceAreas.length > 0),
    );

    this.serviceAreaService.getServiceAreas(b2bCompanyId);

    this.watchServiceAreaChanges();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  showError() {
    const { serviceArea } = this.form.controls;
    return serviceArea.dirty && serviceArea.touched && serviceArea.invalid;
  }

  getMapIcon(serviceArea: ServiceArea) {
    // If no service area is selected, use active for all pins
    if (!this.form.value['serviceArea']) {
      return this.MAP_ICON_ACTIVE;
    }

    // If a service area is selected, use active for the selected one and inactive for the rest.
    if (serviceArea.code === this.form.value['serviceArea']) {
      return this.MAP_ICON_ACTIVE;
    }

    return this.MAP_ICON_INACTIVE;
  }

  onMapReady(gmap: google.maps.Map) {
    gmap.setOptions({ streetViewControl: false });
  }

  onMarkerClick(serviceArea: ServiceArea) {
    // Select the service area that was clicked
    this.form.patchValue({ serviceArea: serviceArea.code });
  }

  onSubmit() {
    const { serviceArea } = this.form.controls;
    if (Object.keys(serviceArea.value).length === 0) {
      serviceArea.markAsDirty();
      serviceArea.markAsTouched();
    } else if (this.notifyForServiceAreaCodes.includes(serviceArea.value)) {
      this.notifyForServiceAreaCodes = this.notifyForServiceAreaCodes.filter(code => code !== serviceArea.value);
      this.showVirtualServiceAreaModal();
    } else {
      this.submit.emit(this.form.value);
    }
  }

  continueRegistrationFlow() {
    this.analytics.continueAsAVirtualMemberClicked();
    this.submit.emit(this.form.value);
    this.closeModal();
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  private showVirtualServiceAreaModal() {
    this.modalService.open(this.virtualServiceAreaModal, { centered: true });
  }

  // Opens the correct map info window when the form value changes, whether through the select or the map.
  private watchServiceAreaChanges() {
    combineLatest([this.serviceAreaService.serviceAreas$, this.form.valueChanges])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([serviceAreas, value]: [ServiceArea[], any]) => {
        const serviceArea = serviceAreas.find((area: ServiceArea) => area.code === value.serviceArea);
        this.showMapInfoWindow(serviceArea);
      });
  }

  // Opens the correct map info window for the given service area.
  private showMapInfoWindow(serviceArea: ServiceArea | null) {
    this.mapInfoWindows.forEach((mapInfoWindow: AgmInfoWindow) => {
      if (serviceArea && mapInfoWindow.hostMarker.title === serviceArea.name) {
        mapInfoWindow.open();
      } else {
        // Close any other open info windows so only one is open at a time
        mapInfoWindow.close();
      }
    });
  }
}
