import { Component } from '@angular/core';

@Component({
  selector: 'om-svg-profile',
  template: `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.22158 20.7852C8.55101 21.6322 10.2078 22 12 22C13.7922 22 15.449 21.6322 16.7784 20.7852C17.4888 20.3326 18.1058 19.7432 18.6 19C18.567 16.811 16 13.5 12 13.5C8 13.5 5.433 16.811 5.4 19C5.8942 19.7432 6.51116 20.3326 7.22158 20.7852ZM19.5679 18.5343C19.4039 17.2228 18.6287 15.8117 17.4806 14.7082C16.1722 13.4506 14.2895 12.5 12 12.5C9.7105 12.5 7.82783 13.4506 6.51941 14.7082C5.37125 15.8117 4.59607 17.2228 4.43211 18.5343C2.91689 16.7813 2 14.497 2 12C2 6.48028 6.48028 2 12 2C17.5197 2 22 6.48028 22 12C22 14.497 21.0831 16.7813 19.5679 18.5343ZM17.2978 21.6411C20.696 19.7687 23 16.1518 23 12C23 5.928 18.072 1 12 1C5.928 1 1 5.928 1 12C1 16.1518 3.30396 19.7687 6.70224 21.6411C8.27396 22.5072 10.0798 23 12 23C13.9202 23 15.726 22.5072 17.2978 21.6411ZM12 3.3C14.3783 3.3 16.3 5.22172 16.3 7.6C16.3 9.97828 14.3783 11.9 12 11.9C9.62171 11.9 7.7 9.97828 7.7 7.6C7.7 5.22172 9.62171 3.3 12 3.3ZM15.3 7.6C15.3 5.774 13.826 4.3 12 4.3C10.174 4.3 8.7 5.774 8.7 7.6C8.7 9.426 10.174 10.9 12 10.9C13.826 10.9 15.3 9.426 15.3 7.6Z"
        fill="currentColor"
      />
    </svg>
  `,
})
export class SvgProfileComponent {}
