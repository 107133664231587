import { Injectable } from '@angular/core';

@Injectable()
export class RollbarService {
  private rollbar;
  scope;
  error;

  constructor() {
    this.rollbar = (<any>window).rollbar;
    this.scope = (...args) => this.rollbar.scope(...args);
    this.error = (...args) => this.rollbar.error(...args);
    return this.rollbar;
  }
}
