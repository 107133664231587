import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Auth0AuthService } from '@app/core/auth0-auth.service';

import { AuthService } from './auth.service';

@Injectable()
export class LoginGuardService implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.authService.initialized$.pipe(
      map(() => {
        const returnUrl = route.queryParams.returnUrl || '/';
        if (this.authService.isLoggedIn()) {
          this.router.navigate([returnUrl]);
          return true;
        }
        if (this.authService.implementation instanceof Auth0AuthService) {
          this.authService.goLogin(returnUrl);
          return false;
        }
        return true;
      }),
    );
  }
}
