import { Component, Input } from '@angular/core';

@Component({
  selector: 'om-arrow-right',
  templateUrl: './arrow-right.component.html',
  styleUrls: ['./arrow-right.component.scss'],
})
export class ArrowRightComponent {
  @Input() disabled: boolean;
}
