import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { IRoutingGroupOption } from '@app/shared';

import { MembershipType } from '../membership-type';
import { RegistrationStepDirective } from '../registration-step.directive';
import { AllDependentsRegisteredError, AlreadyRegisteredError } from '../whitelisted-employee-errors';

@Component({
  selector: 'om-membership-selection-step',
  templateUrl: './membership-selection-step.component.html',
})
export class MembershipSelectionStepComponent extends RegistrationStepDirective {
  @Input() errorMessage = '';
  @ViewChild('typeNotAvailable', { static: true }) typeNotAvailable;
  @ViewChild('personalAlreadyRegistered', { static: true }) personalAlreadyRegistered;

  hasError = false;

  constructor(public modalService: NgbModal, private router: Router) {
    super();

    this.options = this.DEFAULT_OPTIONS;
  }

  pediatricFeatureEnabled: boolean;
  options: IRoutingGroupOption[];
  DEFAULT_OPTIONS: IRoutingGroupOption[] = [
    {
      pngIconUrl: '/assets/images/patient-icon.png',
      header: 'Myself',
      subheader: 'I receive this benefit from my sponsored organization',
      value: MembershipType.PERSONAL,
    },
    {
      pngIconUrl: '/assets/images/spouse-icon.png',
      header: 'Spouse or adult dependent membership',
      subheader: "I'm the spouse or adult dependent of the employee who gets this benefit",
      value: MembershipType.SPOUSE,
    },
  ];

  CHILD_MEMBERSHIP_OPTION: IRoutingGroupOption = {
    pngIconUrl: '/assets/images/kids-icon.png',
    header: 'Child membership',
    subheader: "I'm creating this account for my dependent under 18. Plans to join yourself? Sign yourself up, first.",
    value: MembershipType.KIDS,
  };

  onSubmit(value: string) {
    this.submit.emit(value);
  }

  onError(err: Error) {
    if ((err as AllDependentsRegisteredError).allDependentsRegistered) {
      this.modalService.open(this.typeNotAvailable);
    } else if ((err as AlreadyRegisteredError).alreadyRegistered) {
      this.modalService.open(this.personalAlreadyRegistered);
    }
  }

  logIn() {
    this.modalService.dismissAll();
    this.router.navigateByUrl('/login');
  }
}
