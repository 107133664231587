import { AfterViewInit, Component, ViewChild } from '@angular/core';

import { AddressOptionInputComponent } from '@app/shared/address-option-input/address-option-input.component';
import { PhoneNumberInputComponent } from '@app/shared/phone-number-input/phone-number-input.component';

import { RegistrationStepDirective } from '../registration-step.directive';

@Component({
  selector: 'om-account-set-up-step',
  styleUrls: ['../../../shared/form-input.scss'],
  templateUrl: './account-set-up-step.component.html',
})
export class AccountSetUpStepComponent extends RegistrationStepDirective implements AfterViewInit {
  @ViewChild('firstName', { static: true }) firstName;
  @ViewChild('address', { static: true }) address: AddressOptionInputComponent;
  @ViewChild('phoneNumber', { static: true }) phoneNumber: PhoneNumberInputComponent;

  showPreferredName = false;

  ngAfterViewInit(): void {
    // TODO: understand why this crashes on iPhone 14.1 Safari
    // this.firstName.nativeElement.focus();
  }

  onSubmit() {
    this.submitting = true;
    this.form.controls.firstName.markAsDirty();
    this.form.controls.lastName.markAsDirty();
    this.phoneNumber.markAsTouchedAndDirty();
    this.address.markAsTouchedAndDirty();
    this.submit.emit();
  }
}
