import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[focus-target]',
})
export class FocusTargetDirective implements AfterViewInit {
  @Input() focusChildSelector;

  constructor(public elem: ElementRef) {}

  ngAfterViewInit() {
    if (this.focusChildSelector) {
      this.elem.nativeElement.querySelector(this.focusChildSelector).focus();
    } else {
      this.elem.nativeElement.focus();
    }
  }
}
