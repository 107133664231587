<div
  class="slider-container position-relative"
  [class.mask-left]="showLeftArrow"
  [class.mask-right]="showRightArrow"
  [class.control-position-top]="controlPosition === 'top'"
>
  <button
    class="arrow arrow-left btn position-absolute shadow-none p-0 border-0 d-none d-sm-block"
    [class.arrow-hide]="!showLeftArrow"
    (click)="scrollToLeft()"
    aria-label="scroll left"
  >
    <img src="assets/images/icons/circle-arrow-left.svg" role="presentation" />
  </button>
  <div class="scroll-box row overflow-auto py-3" #scrollBox>
    <div class="text-nowrap px-3 reset-text-wrap d-flex">
      <ng-content></ng-content>
    </div>
  </div>
  <button
    class="arrow arrow-right btn position-absolute shadow-none p-0 border-0 d-none d-sm-block"
    [class.arrow-hide]="!showRightArrow"
    (click)="scrollToRight()"
    aria-label="scroll right"
  >
    <img src="assets/images/icons/circle-arrow-right.svg" role="presentation" />
  </button>
</div>
