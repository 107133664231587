import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'om-svg-caret-right',
  template: `
    <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.77" d="M0.5 0.5L7.5 7.5L0.500001 14.5" [attr.stroke]="stroke" />
    </svg>
  `,
  styleUrls: [],
})
export class SvgCaretRightComponent implements OnInit {
  @Input() stroke = '#1A1A1A';

  constructor() {}

  ngOnInit() {}
}
