import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import range from 'lodash-es/range';

@Component({
  selector: 'om-email-verification-registration',
  styleUrls: ['../form-input.scss'],
  templateUrl: './email-verification-registration.component.html',
})
export class EmailVerificationRegistrationComponent {
  @Input() form: FormGroup;
  @Input() submitting;
  @Input() preferredEmail;
  @Input() errorMessage;
  @Output() submit = new EventEmitter<boolean>();
  @Output() skip = new EventEmitter<boolean>();
  @Output() resendEmail = new EventEmitter<boolean>();

  ENTER_KEY = 'Enter';

  onSubmit() {
    this.form.controls.verificationCode.markAsTouched();
    this.form.controls.verificationCode.markAsDirty();
    this.submit.emit();
  }

  onSkip() {
    this.skip.emit();
  }

  onResendEmail(e: Event) {
    e.preventDefault();
    this.resendEmail.emit();
  }

  filterNonNumeric(e: KeyboardEvent) {
    if (e.key === this.ENTER_KEY) {
      this.onSubmit();
      return;
    }
    const keys = range(10).map(n => n.toString());
    return keys.indexOf(e.key) > -1;
  }

  filterPaste(e: ClipboardEvent) {
    e.preventDefault();
    const text = e.clipboardData.getData('text').trim();
    const regex = /^[0-9]*$/;
    if (regex.test(text)) {
      (e.target as HTMLInputElement).value = text.slice(0, 6);
    }
  }

  hasError(): boolean {
    return !!this.errorMessage && this.errorMessage !== '';
  }
}
