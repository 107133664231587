import { Injectable } from '@angular/core';

import { EmployeeIdConfig } from '@app/registration/enterprise/employee-id/employee-id-config';
import { TermsOfServiceConfig } from '@app/registration/enterprise/terms-of-service/terms-of-service-config';

import { AccountSetUpConfig } from './account-set-up/account-set-up-config';
import { ActivationCodeConfig } from './activation-code/activation-code-config';
import { CreateAccountConfig } from './create-account/create-account-config';
import { DobSexConfig } from './dob-sex/dob-sex-config';
import { MembershipSelectionConfig } from './membership-selection/membership-selection-config';
import { IRegistrationStep } from './registration-step';
import { ServiceAreaConfig } from './service-area-selection/service-area-config';
import { SuccessConfig } from './success/success-config';
import { WorkEmailConfig } from './work-email/work-email-config';

@Injectable()
export class RegistrationNavigation {
  steps: { [s: string]: IRegistrationStep };

  constructor(
    accountSetUpConfig: AccountSetUpConfig,
    activationCodeConfig: ActivationCodeConfig,
    createAccountConfig: CreateAccountConfig,
    dobSexConfig: DobSexConfig,
    employeeIdConfig: EmployeeIdConfig,
    membershipSelectionConfig: MembershipSelectionConfig,
    serviceAreaConfig: ServiceAreaConfig,
    termsOfService: TermsOfServiceConfig,
    workEmailConfig: WorkEmailConfig,
    successConfig: SuccessConfig,
  ) {
    this.steps = {
      accountSetUp: accountSetUpConfig,
      activationCode: activationCodeConfig,
      createAccount: createAccountConfig,
      dobSex: dobSexConfig,
      employeeId: employeeIdConfig,
      membershipSelection: membershipSelectionConfig,
      serviceArea: serviceAreaConfig,
      termsOfService: termsOfService,
      workEmail: workEmailConfig,
      success: successConfig,
    };
  }

  getStep(stepName: string): IRegistrationStep {
    return this.steps[stepName];
  }
}
