import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import {
  Consent,
  LegalDocumentGraphQLResponse,
  LegalDocumentsService,
  TermsOfService,
  TermsOfServiceQueryRef,
} from '@app/core/legal-documents';
import { LegalDocumentsForBeneficiary } from '@app/core/legal-documents/__generated__/LegalDocumentsForBeneficiary';
import { LoginService } from '@app/core/login.service';
import { DocumentSigner } from '@app/core/tos.service';
import { User } from '@app/shared/user';

/**
 * Component that displays legal documents ("terms of service") and allows the
 * user to read them and consent to them by clicking a button. Legal documents
 * are loaded using the {LegalDocumentsService}.
 *
 * A user can sign legal documents either for him/herself, or on behalf of a
 * beneficiary (in which case the signer is acting as the administrator).
 *
 * When the user clicks the "I Agree" button for a doc, a GraphQL mutation is
 * sent to the back-end to sign the document, but the front-end is not reloaded.
 * Instead, the doc is marked as signed locally.
 *
 * When the last document is signed, this kicks off a refresh of all documents,
 * which updates their signed/unsigned status. If all docs remained signed after
 * the API refresh, then the "Next" button is enabled.
 */

@Component({
  selector: 'om-terms-of-service',
  templateUrl: './tos.component.html',
  styleUrls: ['./tos.component.scss'],
})
export class TosComponent implements OnInit, OnChanges, OnDestroy {
  /** If the user is signing on behalf of, this is the user to which the legal documents apply. */
  @Input() beneficiary: User;

  /** If true, the user is signing on behalf of, but is also logged in as the beneficiary user. */
  @Input() beneficiaryLoggedIn: boolean;

  /** Information about the person who is signing the documents, either for him/herself or on behalf of another. */
  @Input() signer: DocumentSigner;

  private _signOnBehalf: boolean;

  /** If `true`, the current user is signing on behalf of someone else. */
  @Input() set signOnBehalf(value: boolean) {
    this._signOnBehalf = value;
    if (this.signOnBehalf) {
      this.documentsEnabled = false;
    }
  }

  /** custom classes for layout **/
  @Input() nextButtonClass = 'col-10 offset-1';
  @Input() contentWidthClass = 'col-sm-10 offset-sm-1';
  @Input() containerClass = 'container tos';

  get signOnBehalf(): boolean {
    return this._signOnBehalf;
  }

  /** Emitted when the "Activate Membership" button is pressed. */
  @Output() nextAction = new EventEmitter<void>();

  /** The text of the "go to next step" button. */
  @Input() nextActionButtonText = 'Activate Membership';

  /** If there's a redirect error */
  @Input() redirectError = null;

  private _legalDocuments$: BehaviorSubject<TermsOfService[]> = new BehaviorSubject(null);
  legalDocuments$: Observable<TermsOfService[]> = this._legalDocuments$
    .asObservable()
    .pipe(filter(docs => docs !== null));

  documentsLoaded$ = this.legalDocuments$.pipe(map(docs => docs.length > 0));
  documentsToAcknowledge$ = this.legalDocuments$.pipe(
    map(docs => docs.filter(doc => doc.consentType === Consent.I_ACKNOWLEDGE)),
  );

  documentsToAgreeTo$ = this.legalDocuments$.pipe(map(docs => docs.filter(doc => doc.consentType === Consent.I_AGREE)));
  unsignedDocuments$ = this.legalDocuments$.pipe(map(docs => docs.filter(doc => !doc.signedOrSignedOnBehalf)));
  allTermsAccepted$ = this.unsignedDocuments$.pipe(map(docs => docs.length === 0));

  agreedToSignOnBehalf = false;
  documentsEnabled = true;
  legalGuardianSet = false;
  redirecting = false;

  private query: TermsOfServiceQueryRef<LegalDocumentGraphQLResponse | LegalDocumentsForBeneficiary>;
  private querySubscription: Subscription;

  private destroy$ = new Subject();

  constructor(private legalDocumentsService: LegalDocumentsService, private loginService: LoginService) {}

  ngOnInit() {
    this.fetchOrRefetchDocuments();
    this.allTermsAccepted$.subscribe(done => {
      if (done) {
        this.fetchOrRefetchDocuments();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.beneficiary) {
      if (this.query) {
        this.querySubscription.unsubscribe();
      }
      this.query = null;
    }

    if (changes.redirectError) {
      this.redirecting = false;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  fetchOrRefetchDocuments() {
    // We have to use `refetch` to explicitly bust the Apollo cache
    if (this.query) {
      this.query.refetch();
    } else {
      this.fetchDocuments();
    }
  }

  private fetchDocuments() {
    if (this.signingForSelf || this.beneficiaryLoggedIn) {
      this.query = this.legalDocumentsService.getForSelf();
    } else {
      this.query = this.legalDocumentsService.getForBeneficiary(this.beneficiary);
    }
    this.querySubscription = this.query.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(docs => this._legalDocuments$.next(docs));
  }

  docSigned(doc) {
    this._legalDocuments$.next(
      this._legalDocuments$.value.map(d => {
        if (d.type === doc.type) {
          const newDoc = d.clone();
          this.signOnBehalf ? (newDoc.signedOnBehalfOf = true) : (newDoc.signed = true);
          return newDoc;
        } else {
          return d;
        }
      }),
    );
  }

  logout(e: MouseEvent) {
    e.preventDefault();
    this.loginService.logout().subscribe();
  }

  continue() {
    this.redirecting = true;
    this.nextAction.emit();
  }

  setLegalGuardian() {
    const createGuardianRequest = this.beneficiaryLoggedIn
      ? this.legalDocumentsService.setLegalGuardianForBeneficiaryWhenLoggedInAsBeneficiary(this.signer)
      : this.legalDocumentsService.setLegalGuardianForBeneficiaryWhenLoggedInAsAdministrator(this.beneficiary);

    createGuardianRequest.subscribe(() => {
      this.legalGuardianSet = true;
      this.setEnableDocuments();
    });
  }

  setAgreedToSignOnBehalf() {
    this.agreedToSignOnBehalf = true;
    this.setEnableDocuments();
  }

  private get signingForSelf() {
    return !this.signOnBehalf;
  }

  private setEnableDocuments() {
    this.documentsEnabled = !this.signOnBehalf || (this.agreedToSignOnBehalf && this.legalGuardianSet);
  }
}
