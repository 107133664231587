import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { WindowService } from '../../../core/window.service';
import { ServiceArea } from '../../../shared/service-area';
import { EnterpriseRegistrationAnalyticsService } from '../enterprise-registration-analytics.service';
import { RegistrationStepDirective } from '../registration-step.directive';

@Component({
  selector: 'om-success-step',
  templateUrl: './success-step.component.html',
})
export class SuccessStepComponent extends RegistrationStepDirective {
  @Input() showDirectSignup = true;
  @Input() shouldRedirectToConsentForm: boolean;
  patientFirstName = null;
  patientEmail = null;
  isNewPatient = true;
  module = null;
  isWhitelisted = null;
  serviceArea: ServiceArea;

  constructor(
    private windowService: WindowService,
    private router: Router,
    private enterpriseRegistrationAnalyticsService: EnterpriseRegistrationAnalyticsService,
  ) {
    super();
  }

  redirectToHomePage() {
    this.enterpriseRegistrationAnalyticsService.redirectToHomeClicked({
      module: this.module,
      isWhitelist: this.isWhitelisted,
    });

    this.windowService.redirect('');
  }

  redirectToDirectSignup() {
    this.enterpriseRegistrationAnalyticsService.directSignupStartedPostRegistration();
    this.windowService.redirect('/registration/referrals');
  }

  redirectToConsentForm() {
    this.enterpriseRegistrationAnalyticsService.redirectToConsentFormClicked({
      module: this.module,
      isWhitelist: this.isWhitelisted,
    });
    this.router.navigateByUrl('/health-information-release/employer-release');
  }
}
