<div class="col-12 row p-0 m-0" *ngIf="!experimentLoading">
  <form
    class="col-12 col-xl-6 col-lg-8 offset-lg-2 offset-xl-0 mt-xl-5 mt-3 pb-3"
    [formGroup]="form"
    (ngSubmit)="onSubmit()"
  >
    <div class="col-xl-8 offset-xl-3 col-12 mt-3 mt-xl-3">
      <h1> Welcome to <span class="text-nowrap">One Medical.</span> </h1>
      <div class="no-gutters mb-4">
        <p class="col-12">
          Activate your sponsored membership to get exceptional primary care, same-day appointments, and 24/7 virtual
          care.
        </p>
      </div>
      <div class="mb-3">
        <om-label-wrapper
          class=""
          label="What’s your sponsored organization (work, school, etc.) email?"
          [hasError]="hasError"
          [errorMessage]="errorMessage"
        >
          <om-email-input
            #workEmail
            formControlName="workEmail"
            placeholder="Enter email"
            [autofocus]="true"
          ></om-email-input>
          <small class="text-black-50">
            No sponsored organization email? Enter your personal email.<br />
            Are you the spouse or dependent of the sponsored individual? Use the email associated with the sponsorship.
          </small>
        </om-label-wrapper>
      </div>
      <div><om-submit-button class="col-12" label="Get Started" [submitting]="submitting"></om-submit-button></div>
      <div *ngIf="!isLoggedIn" class="col-12 text-center">
        <small> Already have an account? <a href="" (click)="onLogIn($event)">Update your membership</a> </small>
      </div>
    </div>
  </form>
  <div class="col-xl-6 d-none d-xl-flex align-middle mt-5" *ngIf="shownView === 'control'">
    <img src="/assets/images/work-email-image.png" />
  </div>
  <div class="col-xl-6 col-12 pt-xl-10 pt-4 min-vh-100 bg-wild-sand" *ngIf="shownView === 'variant'">
    <h2 class="offset-xl-2 font-weight-normal mb-xl-5 mb-4 text-center text-xl-left">
      We’re your healthcare home base
    </h2>
    <div class="col-md-8 col-xl-9 offset-md-2 p-xl-0">
      <div class="d-flex flex-row mb-4">
        <div class="mr-4 mt-2">
          <om-svg-stethoscope></om-svg-stethoscope>
        </div>
        <div class="col-xl-8 p-0">
          <p class="lh-175 mb-1">
            <b>Primary care</b><br />
            Get care for any physical or mental health concern - from colds to chronic conditions.
          </p>
        </div>
      </div>

      <div class="d-flex flex-row mb-4">
        <div class="mr-4 mt-2">
          <om-svg-video-call></om-svg-video-call>
        </div>
        <div class="col-xl-8 p-0">
          <p class="lh-175 mb-1">
            <b>24/7 virtual care</b><br />
            Video chat with a provider or message our providers at no cost to you.
          </p>
        </div>
      </div>

      <div class="d-flex flex-row mb-4">
        <div class="mr-4 mt-2">
          <om-svg-calendar></om-svg-calendar>
        </div>
        <div class="col-xl-8 p-0">
          <p class="lh-175 mb-1">
            <b>Same - or next-day appointments</b><br />
            Safe, convenient care - in our {{ numberOfOffices }}+ offices or over video.
          </p>
        </div>
      </div>

      <div class="d-flex flex-row mb-4">
        <div class="mr-4 mt-2">
          <om-svg-germ></om-svg-germ>
        </div>
        <div class="col-xl-8 p-0">
          <p class="lh-175 mb-1">
            <b>Help with COVID-19</b><br />
            Get the latest COVID-19 information, care, and testing if needed.
          </p>
        </div>
      </div>

      <div class="d-flex flex-row mb-4">
        <div class="mr-4 mt-2">
          <om-svg-lab-work></om-svg-lab-work>
        </div>
        <div class="col-xl-8 p-0">
          <p class="lh-175 mb-1">
            <b>Onsite labs</b><br />
            Walk in for blood tests, immunizations, vaccines, and more at any One Medical office.
          </p>
        </div>
      </div>

      <div class="d-flex flex-row mb-4">
        <div class="mr-4 mt-2">
          <om-svg-pill-bottle></om-svg-pill-bottle>
        </div>
        <div class="col-xl-8 p-0">
          <p class="lh-175 mb-1">
            <b>Rx renewals</b><br />
            Easily request prescription renewals and get them filled wherever you go, online or in the app.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="mt-10" [hidden]="!experimentLoading"> <om-logo-spinner color="#005450" size="100"></om-logo-spinner> </div>
