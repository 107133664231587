import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { of as observableOf } from 'rxjs';

import { AuthServiceImplementation } from './auth.service';
import { StorageService } from './storage.service';

// **** This Injectable should only be accessed through the AuthService ****

@Injectable({
  providedIn: 'root',
})
/**
 * This Class is specifically for handling the onelife implementation of authentication
 * We are moving away from this towards auth0; separating the implementation from the
 * interface will allow use to seemlessly switch to a new implementation
 */
export class OnelifeAuthService implements AuthServiceImplementation {
  static TOKEN_KEY = 'api_token';

  private get _token() {
    return this.storageService.getItem(OnelifeAuthService.TOKEN_KEY);
  }

  constructor(private storageService: StorageService, private router: Router) {
    const token = this.storageService.getItem(OnelifeAuthService.TOKEN_KEY);
    if (token) {
      this.setToken(token);
    }
  }

  init() {
    return observableOf(true);
  }

  getToken() {
    return observableOf(this._token);
  }

  goLogin(returnUrl = '/') {
    this.router.navigate(['login'], { queryParams: { returnUrl: returnUrl } });
  }

  isAuthenticated() {
    return observableOf(!!this._token);
  }

  logout(returnURI = '/') {
    this.storageService.removeItem(OnelifeAuthService.TOKEN_KEY);
    this.storageService.removeTldItem(OnelifeAuthService.TOKEN_KEY);
  }

  setToken(token) {
    this.storageService.setTldItem(OnelifeAuthService.TOKEN_KEY, token);
  }
}
