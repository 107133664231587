import { Component, Input } from '@angular/core';

@Component({
  selector: 'om-arrow-left',
  templateUrl: './arrow-left.component.html',
  styleUrls: ['./arrow-left.component.scss'],
})
export class ArrowLeftComponent {
  @Input() disabled: boolean;
  @Input() size = 'small';
}
