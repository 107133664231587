<div *ngIf="inlineForm.showingForm" class="form-container">
  <form [name]="inlineForm.name" (ngSubmit)="onSubmit()" [formGroup]="inlineForm.formGroup">
    <div class="content-container">
      <ng-content></ng-content>

      <span *ngIf="inlineForm.formGroup.hasError('apiError')" class="error-message">
        <small>{{ inlineForm.formGroup.getError('apiError') }}</small>
      </span>
    </div>

    <div class="action-container">
      <hr />

      <div class="button-container">
        <button type="submit" [disabled]="inlineForm.submitting || inlineForm.formGroup.invalid"> Save </button>
        <button
          *ngIf="inlineForm.canDelete"
          type="button"
          (click)="onDelete()"
          class="desktop-button btn btn-outline-primary delete"
          [disabled]="inlineForm.submitting || inlineForm.formGroup.invalid"
          data-cy="inline-form-delete-button"
        >
          Delete
        </button>
        <button
          type="button"
          (click)="inlineForm.hideForm()"
          class="desktop-button btn btn-link"
          [disabled]="inlineForm.submitting"
          data-cy="inline-form-cancel-button"
        >
          Cancel
        </button>

        <button
          *ngIf="inlineForm.canDelete"
          type="button"
          (click)="onDelete()"
          class="mobile-button btn btn-outline-primary delete"
          [disabled]="inlineForm.submitting || inlineForm.formGroup.invalid"
        >
          Delete
        </button>
        <button
          type="button"
          (click)="inlineForm.hideForm()"
          class="mobile-button btn btn-outline-primary"
          [disabled]="inlineForm.submitting"
        >
          Cancel
        </button>
      </div>
    </div>
  </form>
</div>
