import { AbstractControl } from '@angular/forms';

export function phoneNumberValidator(control: AbstractControl) {
  let valid = true;
  let controlValue = control.value || '';
  const errors = {};

  controlValue = controlValue.replace(/[^0-9]/g, '');

  if (controlValue.length !== 10) {
    valid = false;
    errors['invalidLength'] = true;
  }

  return valid ? null : errors;
}
