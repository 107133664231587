import { Injectable } from '@angular/core';
import { finalize, map, onErrorResumeNext, share } from 'rxjs/operators';

import { AnalyticsService } from '@app/core/analytics.service';

import { ApiService } from './api.service';
import { AuthService } from './auth.service';

@Injectable()
export class LoginService {
  constructor(
    private analyticsService: AnalyticsService,
    private apiService: ApiService,
    private authService: AuthService,
  ) {}

  login(username, password) {
    const params = {
      username: username,
      password: password,
      grant_type: 'password',
    };

    return this.apiService.post('/oauth/token', params, true).pipe(
      map((response: any) => {
        if (!response.error) {
          this.authService.setToken(response.access_token);
        }
        return response;
      }),
    );
  }

  logout() {
    // TODO: For Auth0 Launch - investigate moving the logout call to the authservice.logout()
    this.analyticsService.resetMixpanelId();
    const request = this.apiService.delete('/api/v2/patient/logout').pipe(
      onErrorResumeNext(),
      finalize(() => this.authService.logout()),
      share(),
    );
    request.subscribe();
    return request;
  }
}
