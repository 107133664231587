<svg
  width="50px"
  height="50px"
  viewBox="0 0 50 50"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <g id="Table-button/subcomponent/any-provider" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <circle stroke="currentColor" cx="25" cy="25" r="24.5"></circle>
    <g id="ico-vaccine-order">
      <g transform="translate(14.000000, 12.000000)" id="Page-1">
        <g>
          <path d="M0,0 L40,0 L40,34 L0,34 L0,0 Z"></path>
          <path
            d="M2.94367857,18.0714286 C0.977035714,17.3839286 -0.463178571,13.3571429 0.977035714,11.9821429"
            id="Stroke-1"
            stroke="currentColor"
          ></path>
          <path
            d="M18.6860929,18.0714286 C20.9143786,17.5803571 22.5188071,13.75 20.87745,11.9821429"
            id="Stroke-3"
            stroke="currentColor"
          ></path>
          <path
            d="M14.7858857,6.48921429 C14.8982429,8.57685714 13.2977429,10.3596429 11.2101,10.4727857 C9.12324286,10.5851429 7.33967143,8.98464286 7.22731429,6.897 C7.11417143,4.80935714 8.71545714,3.02657143 10.8023143,2.91342857 C12.8899571,2.80107143 14.6735286,4.40157143 14.7858857,6.48921429 Z"
            id="Stroke-5"
            stroke="currentColor"
          ></path>
          <polygon
            id="Fill-7"
            fill="currentColor"
            points="12.0503429 6.69499286 11.0092714 7.81699286 9.88648571 6.77592143 10.9283429 5.65392143"
          ></polygon>
          <polygon
            id="Stroke-9"
            stroke="currentColor"
            fill="currentColor"
            points="12.0503429 6.69499286 11.0092714 7.81699286 9.88648571 6.77592143 10.9283429 5.65392143"
          ></polygon>
          <path
            d="M14.0326214,8.96484286 C19.1059786,9.25948571 19.6269071,10.4742 19.6269071,10.4742"
            id="Stroke-11"
            stroke="currentColor"
          ></path>
          <path
            d="M2.11333571,10.4742786 C2.11333571,10.4742786 3.46476429,8.96413571 7.97869286,8.96413571"
            id="Stroke-13"
            stroke="currentColor"
          ></path>
          <path
            d="M5.25870714,7.03096429 C2.89842143,7.49296429 2.11349286,8.11682143 2.11349286,8.11682143 L1.97520714,7.85753571 C1.97520714,13.6191786 3.14592143,17.8423929 3.53877857,19.3486071 C3.93163571,20.8540357 8.73627857,26.3218214 9.42377857,26.3218214 L12.2397786,26.3218214 C13.0577071,26.3218214 17.7727786,20.8540357 18.1656357,19.3486071 C18.5584929,17.8423929 19.7692786,13.6191786 19.7692786,7.85753571 L19.6270643,8.11682143 C19.6270643,8.11682143 19.1116357,7.49296429 16.9517071,7.03096429"
            id="Stroke-15"
            stroke="currentColor"
          ></path>
          <path d="M17.2806071,21.2142857 L17.2806071,26.7142857" id="Stroke-17" stroke="currentColor"></path>
          <path d="M4.70917857,21.2142857 L4.70917857,24.3571429" id="Stroke-19" stroke="currentColor"></path>
          <path
            d="M19.7695143,7.85714286 C19.7695143,2.0955 14.0400857,0.392857143 11.1918714,0.392857143 L10.6348,0.392857143 C7.78658571,0.392857143 1.97544286,2.0955 1.97544286,7.85714286"
            id="Stroke-21"
            stroke="currentColor"
          ></path>
          <path
            d="M9.03060714,18.4642857 C9.03060714,18.4642857 10.1439643,18.8901429 10.9948929,18.8901429 C11.8458214,18.8901429 12.9591786,18.4642857 12.9591786,18.4642857"
            id="Stroke-23"
            stroke="currentColor"
          ></path>
        </g>
      </g>
    </g>
  </g>
</svg>
