import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApolloQueryResult } from '@apollo/client/core';
import { combineLatest, Observable, of as observableOf } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import {
  MembershipResult,
  MembershipResult_membership as Membership,
  MembershipResult_membership_plan_B2bPlan as B2bPlan,
} from '@app/core/__generated__/MembershipResult';
import { AuthService } from '@app/core/auth.service';
import { MembershipPlan } from '@app/core/membership';
import { MembershipGraphQL } from '@app/core/membership-graphql.service';
import { EnterpriseRegistration } from '@app/registration/enterprise';
import { RegistrationStateService } from '@app/registration/registration-state.service';
import { FeatureFlagService } from '@app/shared';
import { Features } from '@app/shared/active-feature-flag-collection';
import { FeatureFlagByB2bIDGraphQL, FeatureFlagByUUIDGraphQL } from '@app/shared/feature-flag-graphql.service';

@Injectable()
export class PediatricMembershipTypeService {
  constructor(
    public registrationStateService: RegistrationStateService,
    private enterpriseRegistration: EnterpriseRegistration,
    public authService: AuthService,
    private route: ActivatedRoute,
    private featureFlagService: FeatureFlagService,
    private membershipGraphQL: MembershipGraphQL,
    private featureFlagByUUIDGraphQL: FeatureFlagByUUIDGraphQL,
    private featureFlagByB2bIDGraphQL: FeatureFlagByB2bIDGraphQL,
  ) {}

  get isEnterprise$(): Observable<boolean> {
    if (this.authService.isLoggedIn()) {
      return this.isMembershipCorePlusEnterprise();
    } else if (this.enterpriseRegistration.b2bCompanyId) {
      return this.isEnterpriseByB2bCompanyId();
    } else {
      return this.isEnterpriseByUUID();
    }
  }

  isCorePlusEnterprise(membership: Membership): boolean {
    return membership.planType === MembershipPlan.B2B && (membership.plan as B2bPlan).company.includesDependent;
  }

  private isEnterpriseByUUID() {
    return this.route.queryParamMap.pipe(
      map(params => params.get('uuid')),
      switchMap(uuid => {
        if (!uuid) {
          return observableOf(false);
        }

        return this.featureFlagByUUIDGraphQL
          .fetch({ uuid: uuid, name: Features.DISABLE_ENTERPRISE_PEDS_REGISTRATION })
          .pipe(
            take(1),
            map(result => !result.data.featureFlag.enabledForPendingEnterpriseRegistration),
          );
      }),
    );
  }

  private isEnterpriseByB2bCompanyId() {
    const b2bId = this.enterpriseRegistration.b2bCompanyId;
    return this.featureFlagByB2bIDGraphQL
      .fetch({ id: b2bId, name: Features.DISABLE_ENTERPRISE_PEDS_REGISTRATION })
      .pipe(
        take(1),
        map(result => !result.data.featureFlag.enabledForCompany && this.b2bCompanyIncludesDependent),
      );
  }

  private isMembershipCorePlusEnterprise(): Observable<boolean> {
    const pediatricEnabled$ = this.featureFlagService
      .featureActive$(Features.DISABLE_ENTERPRISE_PEDS_REGISTRATION)
      .pipe(map(disabled => !disabled));
    return combineLatest([pediatricEnabled$, this.membershipGraphQL.fetch()]).pipe(
      take(1),
      map(([pediatricFeatureEnabled, membershipResponse]: [boolean, ApolloQueryResult<MembershipResult>]) => {
        const { membership } = membershipResponse.data;
        return (
          pediatricFeatureEnabled &&
          this.isCorePlusEnterprise(membership) &&
          (membership.plan as B2bPlan).hasActiveDiscountTypes
        );
      }),
    );
  }

  private get b2bCompanyIncludesDependent() {
    return this.enterpriseRegistration.b2bCompany && this.enterpriseRegistration.b2bCompany.includesDependent;
  }
}
