<div *ngIf="!shouldRedirectToConsentForm" class="container mb-5">
  <div class="row justify-content-center">
    <div class="col-md-8 pt-5 text-center" *ngIf="isNewPatient; else enterpriseConversionMessage">
      <om-svg-welcome-to-office-image></om-svg-welcome-to-office-image>
      <h3>Congratulations!</h3> <h3>Your sponsored membership is now active.</h3>
      <p class="lead">
        We sent a confirmation email to {{ patientEmail }}. Sign up your family or book an appointment to start using
        One Medical now.
      </p>
    </div>

    <ng-template #enterpriseConversionMessage>
      <div class="col-md-8 pt-5 text-center">
        <om-svg-welcome-to-office-image></om-svg-welcome-to-office-image>
        <h3>Congratulations, {{ patientFirstName }}!</h3> <h3>Your sponsored membership is updated.</h3>
        <p class="lead">
          We sent a confirmation email to {{ patientEmail }}. Sign up your family or book an appointment to start using
          One Medical now.
        </p>
      </div>
    </ng-template>
  </div>
  <div class="row justify-content-center">
    <div class="col-6 pt-5">
      <div class="row justify-content-center">
        <button
          *ngIf="showDirectSignup"
          (click)="redirectToDirectSignup()"
          class="btn btn-primary btn-block"
          data-cy="direct-signup-link"
        >
          Register a spouse or dependent
        </button>
      </div>
      <div class="row justify-content-center">
        <button
          (click)="redirectToHomePage()"
          [ngClass]="showDirectSignup ? 'btn btn-link' : 'btn btn-primary btn-block'"
          data-cy="home-link"
        >
          Continue to Home
        </button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="shouldRedirectToConsentForm" class="container mb-5">
  <div class="row justify-content-center">
    <div class="col-md-8 pt-5 text-center">
      <img class="mb-4" src="assets/images/logo_confetti.svg" height="150" />
      <h3>Welcome to One Medical</h3>
      <p class="col-12 col-lg-8 offset-lg-2">
        Just one more step! Your sponsored organization requires that you complete the consent form before booking an
        appointment.
      </p>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-12 col-lg-6 pt-2">
      <div class="row justify-content-center">
        <button (click)="redirectToConsentForm()" class="btn btn-primary" data-cy="consent-link">
          Complete Consent Form
        </button>
      </div>
    </div>
  </div>
</div>
