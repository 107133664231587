<div class="modal-header pb-0 border-0">
  <om-close-x
    role="button"
    class="btn close font-weight-light text-right"
    aria-label="Close"
    (click)="close(false)"
  ></om-close-x>
</div>
<div class="modal-body pt-0 px-4 pb-4 text-center">
  <h2 class="text-center">{{ title }}</h2> <div>{{ body }}</div>
  <div class="row px-3 mt-4 mb-3 justify-content-center">
    <button type="button" (click)="close(true)" class="col-4 mr-3 btn btn-primary" data-cy="modal-yes-button"
      >Yes</button
    >
    <button type="button" (click)="close(false)" class="col-4 btn btn-outline-primary">No</button>
  </div>
</div>
