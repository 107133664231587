import { Component, Input } from '@angular/core';

@Component({
  selector: 'om-svg-health-information-exchange',
  template: `
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.99 6L0 10L3.99 14V11H11V9H3.99V6ZM18 4L14.01 0V3H7V5H14.01V8L18 4Z" [attr.fill]="color" />
    </svg>
  `,
  styles: [
    `
      svg {
        position: relative;
        left: 2px;
      }
    `,
  ],
})
export class SvgHealthInformationExchangeComponent {
  @Input() color = 'currentColor';

  constructor() {}
}
