import { Injectable } from '@angular/core';

import { ConfigService } from '@app/core/config.service';
import { healthHistoryAdultSurveyId, healthHistoryPediatricSurveyId } from '@app/survey/survey-summary-graphql.service';

@Injectable()
export class LinksService {
  // Emails
  adminEmail = 'mailto:admin@onemedical.com';

  // Onelife Links
  onelife = {
    homePage: `${this.config.json.myoneServer}/pt/my-one/index`,
    legacyEnterpriseReg: `${this.config.json.myoneServer}/enterprise_registration/determine_b2b_type`,
    logInUrl: `${this.config.json.myoneServer}/pt/patient/login`,
    resetPassword: `${this.config.json.myoneServer}/pt/auth/forgot_password`,
    medicalRecords: `${this.config.json.myoneServer}/pt/questionnaire/medical-records-release`,
  };

  // Patient UI Paths
  home = `/`;
  login = '/login';
  membershipRenew = `/membership/renew`;
  membershipRenewConsumer = `/membership/renew/consumer`;
  membershipRenewEnterprise = `/membership/renew/enterprise`;
  membershipRenewOMNow = `/membership/renew/omnow`;
  membershipSettings = `/membership/settings`;
  registrationEnterprise = `/registration/enterprise`;
  registrationPediatric = `/registration/pediatric`;
  newAppointment = `/appointments/reason`;
  healthSurvey = `/survey/${healthHistoryAdultSurveyId}`;
  kidsHealthSurvey = `/survey/${healthHistoryPediatricSurveyId}`;

  // External links
  familyPromoTerms = 'https://onemedical.com/kids-special/#disclaimers';

  constructor(private config: ConfigService) {}

  employeeIdRegistration(b2bCompanyId: number): string {
    return `${this.registrationEnterprise}/employee_id?b2b_company_id=${b2bCompanyId}`;
  }

  constructWithQueryParams(key, options): string {
    return this[key] + this.constructQueryParams(options);
  }

  constructQueryParams(options): string {
    this.sanitizeParams(options);
    const queryParams: string = new URLSearchParams(options).toString();
    return queryParams ? queryParams : `?${queryParams}`;
  }

  private sanitizeParams(object) {
    Object.keys(object).map(key => {
      if (object[key].length === 0) {
        delete object[key];
      }
    });
  }
}
