import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'om-download-app-link-input',
  templateUrl: './download-app-link-input.component.html',
  styleUrls: ['../form-input.scss'],
})
export class DownloadAppLinkInputComponent {
  @Input() form: FormGroup;

  constructor() {}
}
