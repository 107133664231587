import { Injectable } from '@angular/core';
import { Query, gql, Apollo } from 'apollo-angular';

import { DocumentSigner } from '@app/core/tos.service';

export interface DocumentSignerResponse {
  patient: {
    id: string;
    documentSigner: DocumentSigner;
  };
}

@Injectable()
export class DocumentSignerGraphQL extends Query<DocumentSignerResponse> {
  constructor(apollo: Apollo) {
    super(apollo);
  }

  document = gql`
    query DocumentSigner {
      patient {
        id
        documentSigner {
          id
          type
          firstName
          lastName
          email
        }
      }
    }
  `;
}
