import { Component, Input, OnInit } from '@angular/core';

import { Provider } from '../../shared/provider';

@Component({
  selector: 'om-profile-bubble-with-details',
  templateUrl: './profile-bubble-with-details.component.html',
  styleUrls: ['./profile-bubble-with-details.component.scss'],
})
export class ProfileBubbleWithDetailsComponent implements OnInit {
  @Input() provider: Provider;

  constructor() {}

  ngOnInit() {}
}
