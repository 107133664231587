<div>
  <div class="modal-header border-0 cy-redirect-modal">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-content px-5 pb-5 border-0 text-center">
    <h2 class="mx-3" [innerHtml]="title"></h2>
    <p class="modal-subheader mt-3 mb-4" [innerHtml]="message"></p>
    <button
      class="col-sm-12 col-md-8 offset-md-2 my-2 btn btn-primary"
      (click)="redirect()"
      data-cy="redirect"
      ngbAutofocus
    >
      {{ cta }}
    </button>
    <div *ngIf="secondaryCtaOptions.type === 'close'">
      <div class="secondary-cta mt-3" (click)="closeModal()">{{ secondaryCtaOptions.message }}</div>
    </div>
  </div>
</div>
