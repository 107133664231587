import { createReducer, on } from '@ngrx/store';
import { LDFlagSet } from 'launchdarkly-js-sdk-common';

import { LDClientInitialized, setFlags } from '@app/core/feature-flags/feature-flag.actions';

export const featureKey = 'featureFlags';

export interface FeatureFlagState {
  flags: LDFlagSet;
  isLDClientInitialized: boolean;
}

export const initialState: FeatureFlagState = {
  flags: {},
  isLDClientInitialized: false,
};

export const reducer = createReducer(
  initialState,
  on(setFlags, (state, action) => ({
    ...state,
    flags: { ...state.flags, ...action.flags },
  })),
  on(LDClientInitialized, (state, action) => ({
    ...state,
    isLDClientInitialized: true,
  })),
);
