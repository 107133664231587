import { AbstractControl } from '@angular/forms';

interface PasswordComplexityErrors {
  key: string;
  message: string;
}

export const passwordComplexityErrorsToMessages: PasswordComplexityErrors[] = [
  { key: 'characterMinimum', message: '8 characters minimum' },
  { key: 'upperCase', message: 'One uppercase letter' },
  { key: 'lowerCase', message: 'One lowercase letter' },
  { key: 'number', message: 'One number' },
];

export function passwordComplexityValidator(control: AbstractControl) {
  const valid = false;
  const controlValue = control.value || '';
  const errors = {};

  if (controlValue.length < 8) {
    errors['characterMinimum'] = 'must contain 8 or more characters';
  }

  if (!controlValue.match(/[A-Z]+/g)) {
    errors['upperCase'] = 'must contain an uppercase letter';
  }

  if (!controlValue.match(/[a-z]+/g)) {
    errors['lowerCase'] = 'must contain a lower case letter';
  }

  if (!controlValue.match(/\d+/g)) {
    errors['number'] = 'must contain a number';
  }

  return valid ? null : errors;
}
