import { Injectable } from '@angular/core';
import lodashMap from 'lodash-es/map';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from '../core/api.service';
import { Office } from './office';

@Injectable()
export class OfficeService {
  readonly _offices$ = new BehaviorSubject<Array<Office>>([]);
  readonly offices$ = this._offices$.asObservable();

  constructor(private apiService: ApiService) {}

  getOffices(): Observable<Office[]> {
    const request$: Observable<Office[]> = this.apiService
      .get('/api/v2/public/offices')
      .pipe(map((result: any) => lodashMap(result, officeResponse => Office.fromApiV2(officeResponse))));
    request$.subscribe(offices => this._offices$.next(offices));
    return request$;
  }

  getOffice(id): Observable<Office> {
    return this.apiService
      .get(`/api/v2/public/offices/${id}.json`)
      .pipe(map(officeResponse => Office.fromApiV2(officeResponse)));
  }

  getRoundedOfficeCount(): Observable<number> {
    return this.getOffices().pipe(
      map((offices = []) => {
        const rawCount = offices.filter(o => !o.permalink.match(/remote|virtual|testing/g)).length;
        return Math.floor(rawCount / 10) * 10;
      }),
    );
  }
}
