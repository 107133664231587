!(function(e, r) {
  if ("object" == typeof exports && "object" == typeof module) module.exports = r();
  else if ("function" == typeof define && define.amd) define([], r);
  else {
    var t = r();
    for (var n in t) ("object" == typeof exports ? exports : e)[n] = t[n];
  }
})(this, function() {
  return (function(e) {
    function r(n) {
      if (t[n]) return t[n].exports;
      var o = (t[n] = { exports: {}, id: n, loaded: !1 });
      return e[n].call(o.exports, o, o.exports, r), (o.loaded = !0), o.exports;
    }
    var t = {};
    return (r.m = e), (r.c = t), (r.p = ""), r(0);
  })([
    function(e, r, t) {
      e.exports = t(1);
    },
    function(e, r, t) {
      "use strict";
      function n() {
        var e = "undefined" == typeof JSON ? {} : JSON;
        o.setupJSON(e);
      }
      var o = t(2),
        i = t(3);
      n();
      var a = window._rollbarConfig,
        s = (a && a.globalAlias) || "Rollbar",
        u = window[s] && "undefined" != typeof window[s].shimId;
      !u && a
        ? o.wrapper.init(a)
        : ((window.Rollbar = o.wrapper), (window.RollbarNotifier = i.Notifier)),
        (e.exports = o.wrapper);
    },
    function(e, r, t) {
      "use strict";
      function n(e, r, t) {
        !t[4] &&
          window._rollbarWrappedError &&
          ((t[4] = window._rollbarWrappedError), (window._rollbarWrappedError = null)),
          e.uncaughtError.apply(e, t),
          r && r.apply(window, t);
      }
      function o(e, r) {
        if (r.hasOwnProperty && r.hasOwnProperty("addEventListener")) {
          var t = r.addEventListener;
          r.addEventListener = function(r, n, o) {
            t.call(this, r, e.wrap(n), o);
          };
          var n = r.removeEventListener;
          r.removeEventListener = function(e, r, t) {
            n.call(this, e, (r && r._wrapped) || r, t);
          };
        }
      }
      var i = t(3),
        a = t(8),
        s = i.Notifier;
      window._rollbarWrappedError = null;
      var u = {};
      (u.init = function(e, r) {
        var t = new s(r);
        if ((t.configure(e), e.captureUncaught)) {
          var i;
          r && a.isType(r._rollbarOldOnError, "function")
            ? (i = r._rollbarOldOnError)
            : window.onerror && !window.onerror.belongsToShim && (i = window.onerror),
            (window.onerror = function() {
              var e = Array.prototype.slice.call(arguments, 0);
              n(t, i, e);
            });
          var u,
            c,
            l = [
              "EventTarget",
              "Window",
              "Node",
              "ApplicationCache",
              "AudioTrackList",
              "ChannelMergerNode",
              "CryptoOperation",
              "EventSource",
              "FileReader",
              "HTMLUnknownElement",
              "IDBDatabase",
              "IDBRequest",
              "IDBTransaction",
              "KeyOperation",
              "MediaController",
              "MessagePort",
              "ModalWindow",
              "Notification",
              "SVGElementInstance",
              "Screen",
              "TextTrack",
              "TextTrackCue",
              "TextTrackList",
              "WebSocket",
              "WebSocketWorker",
              "Worker",
              "XMLHttpRequest",
              "XMLHttpRequestEventTarget",
              "XMLHttpRequestUpload"
            ];
          for (u = 0; u < l.length; ++u)
            (c = l[u]), window[c] && window[c].prototype && o(t, window[c].prototype);
        }
        return (
          e.captureUnhandledRejections &&
            (r &&
              a.isType(r._unhandledRejectionHandler, "function") &&
              window.removeEventListener("unhandledrejection", r._unhandledRejectionHandler),
            (t._unhandledRejectionHandler = function(e) {
              var r = e.reason,
                n = e.promise,
                o = e.detail;
              !r && o && ((r = o.reason), (n = o.promise)), t.unhandledRejection(r, n);
            }),
            window.addEventListener("unhandledrejection", t._unhandledRejectionHandler)),
          (window.Rollbar = t),
          s.processPayloads(),
          t
        );
      }),
        (e.exports = { wrapper: u, setupJSON: i.setupJSON });
    },
    function(e, r, t) {
      "use strict";
      function n(e) {
        (E = e), w.setupJSON(e);
      }
      function o(e, r) {
        return function() {
          var t = r || this;
          try {
            return e.apply(t, arguments);
          } catch (n) {
            console.error("[Rollbar]:", n);
          }
        };
      }
      function i() {
        h || (h = setTimeout(f, 1e3));
      }
      function a() {
        return _;
      }
      function s(e) {
        _ = _ || this;
        var r = "https://" + s.DEFAULT_ENDPOINT;
        (this.options = {
          enabled: !0,
          endpoint: r,
          environment: "production",
          scrubFields: g([], s.DEFAULT_SCRUB_FIELDS),
          checkIgnore: null,
          logLevel: s.DEFAULT_LOG_LEVEL,
          reportLevel: s.DEFAULT_REPORT_LEVEL,
          uncaughtErrorLevel: s.DEFAULT_UNCAUGHT_ERROR_LEVEL,
          payload: {}
        }),
          (this.lastError = null),
          (this.plugins = {}),
          (this.parentNotifier = e),
          e && (e.hasOwnProperty("shimId") ? (e.notifier = this) : this.configure(e.options));
      }
      function u(e) {
        window._rollbarPayloadQueue.push(e), i();
      }
      function c(e) {
        return o(function() {
          var r = this._getLogArgs(arguments);
          return this._log(
            e || r.level || this.options.logLevel || s.DEFAULT_LOG_LEVEL,
            r.message,
            r.err,
            r.custom,
            r.callback
          );
        });
      }
      function l(e, r) {
        e || (e = r ? E.stringify(r) : "");
        var t = { body: e };
        return r && (t.extra = g(!0, {}, r)), { message: t };
      }
      function p(e, r, t) {
        var n = m.guessErrorClass(r.message),
          o = r.name || n[0],
          i = n[1],
          a = { exception: { class: o, message: i } };
        if ((e && (a.exception.description = e || "uncaught exception"), r.stack)) {
          var s, u, c, p, f, d, h, w;
          for (a.frames = [], h = 0; h < r.stack.length; ++h)
            (s = r.stack[h]),
              (u = {
                filename: s.url ? v.sanitizeUrl(s.url) : "(unknown)",
                lineno: s.line || null,
                method: s.func && "?" !== s.func ? s.func : "[anonymous]",
                colno: s.column
              }),
              (c = p = f = null),
              (d = s.context ? s.context.length : 0),
              d &&
                ((w = Math.floor(d / 2)),
                (p = s.context.slice(0, w)),
                (c = s.context[w]),
                (f = s.context.slice(w))),
              c && (u.code = c),
              (p || f) &&
                ((u.context = {}),
                p && p.length && (u.context.pre = p),
                f && f.length && (u.context.post = f)),
              s.args && (u.args = s.args),
              a.frames.push(u);
          return a.frames.reverse(), t && (a.extra = g(!0, {}, t)), { trace: a };
        }
        return l(o + ": " + i, t);
      }
      function f() {
        var e;
        try {
          for (; (e = window._rollbarPayloadQueue.shift()); ) d(e);
        } finally {
          h = void 0;
        }
      }
      function d(e) {
        var r = e.endpointUrl,
          t = e.accessToken,
          n = e.payload,
          o = e.callback || function() {},
          i = new Date().getTime();
        i - L >= 6e4 && ((L = i), (R = 0));
        var a = window._globalRollbarOptions.maxItems,
          c = window._globalRollbarOptions.itemsPerMinute,
          l = function() {
            return !n.ignoreRateLimit && a >= 1 && T >= a;
          },
          p = function() {
            return !n.ignoreRateLimit && c >= 1 && R >= c;
          };
        return l()
          ? void o(new Error(a + " max items reached"))
          : p()
            ? void o(new Error(c + " items per minute reached"))
            : (T++,
              R++,
              l() &&
                _._log(
                  _.options.uncaughtErrorLevel,
                  "maxItems has been hit. Ignoring errors for the remainder of the current page load.",
                  null,
                  { maxItems: a },
                  null,
                  !1,
                  !0
                ),
              n.ignoreRateLimit && delete n.ignoreRateLimit,
              void y.post(r, t, n, function(r, t) {
                return r
                  ? (r instanceof b &&
                      ((e.callback = function() {}),
                      setTimeout(function() {
                        u(e);
                      }, s.RETRY_DELAY)),
                    o(r))
                  : o(null, t);
              }));
      }
      var h,
        g = t(4),
        m = t(5),
        v = t(8),
        w = t(10),
        y = w.XHR,
        b = w.ConnectionError,
        E = null;
      (s.NOTIFIER_VERSION = "1.9.2"),
        (s.DEFAULT_ENDPOINT = "api.rollbar.com/api/1/"),
        (s.DEFAULT_SCRUB_FIELDS = [
          "pw",
          "pass",
          "passwd",
          "password",
          "secret",
          "confirm_password",
          "confirmPassword",
          "password_confirmation",
          "passwordConfirmation",
          "access_token",
          "accessToken",
          "secret_key",
          "secretKey",
          "secretToken"
        ]),
        (s.DEFAULT_LOG_LEVEL = "debug"),
        (s.DEFAULT_REPORT_LEVEL = "debug"),
        (s.DEFAULT_UNCAUGHT_ERROR_LEVEL = "error"),
        (s.DEFAULT_ITEMS_PER_MIN = 60),
        (s.DEFAULT_MAX_ITEMS = 0),
        (s.LEVELS = { debug: 0, info: 1, warning: 2, error: 3, critical: 4 }),
        (s.RETRY_DELAY = 1e4),
        (window._rollbarPayloadQueue = window._rollbarPayloadQueue || []),
        (window._globalRollbarOptions = {
          startTime: new Date().getTime(),
          maxItems: s.DEFAULT_MAX_ITEMS,
          itemsPerMinute: s.DEFAULT_ITEMS_PER_MIN
        });
      var _,
        x = s.prototype;
      (x._getLogArgs = function(e) {
        for (
          var r, t, n, i, a, u, c = this.options.logLevel || s.DEFAULT_LOG_LEVEL, l = [], p = 0;
          p < e.length;
          ++p
        )
          (u = e[p]),
            (a = v.typeName(u)),
            "string" === a
              ? r
                ? l.push(u)
                : (r = u)
              : "function" === a
                ? (i = o(u, this))
                : "date" === a
                  ? l.push(u)
                  : "error" === a ||
                    u instanceof Error ||
                    ("undefined" != typeof DOMException && u instanceof DOMException)
                    ? t
                      ? l.push(u)
                      : (t = u)
                    : ("object" !== a && "array" !== a) || (n ? l.push(u) : (n = u));
        return (
          l.length && ((n = n || {}), (n.extraArgs = l)),
          { level: c, message: r, err: t, custom: n, callback: i }
        );
      }),
        (x._route = function(e) {
          var r = this.options.endpoint,
            t = /\/$/.test(r),
            n = /^\//.test(e);
          return t && n ? (e = e.substring(1)) : t || n || (e = "/" + e), r + e;
        }),
        (x._processShimQueue = function(e) {
          for (var r, t, n, o, i, a, u, c = {}; (t = e.shift()); )
            (r = t.shim),
              (n = t.method),
              (o = t.args),
              (i = r.parentShim),
              (u = c[r.shimId]),
              u || (i ? ((a = c[i.shimId]), (u = new s(a))) : (u = this), (c[r.shimId] = u)),
              u[n] && v.isType(u[n], "function") && u[n].apply(u, o);
        }),
        (x._buildPayload = function(e, r, t, n, o) {
          var i = this.options.accessToken,
            a = this.options.environment,
            u = g(!0, {}, this.options.payload),
            c = v.uuid4();
          if (void 0 === s.LEVELS[r]) throw new Error("Invalid level");
          if (!t && !n && !o) throw new Error("No message, stack info or custom data");
          var l = {
            environment: a,
            endpoint: this.options.endpoint,
            uuid: c,
            level: r,
            platform: "browser",
            framework: "browser-js",
            language: "javascript",
            body: this._buildBody(t, n, o),
            request: {
              url: window.location.href,
              query_string: window.location.search,
              user_ip: "$remote_ip"
            },
            client: {
              runtime_ms: e.getTime() - window._globalRollbarOptions.startTime,
              timestamp: Math.round(e.getTime() / 1e3),
              javascript: {
                browser: window.navigator.userAgent,
                language: window.navigator.language,
                cookie_enabled: window.navigator.cookieEnabled,
                screen: { width: window.screen.width, height: window.screen.height },
                plugins: this._getBrowserPlugins()
              }
            },
            server: {},
            notifier: { name: "rollbar-browser-js", version: s.NOTIFIER_VERSION }
          };
          u.body && delete u.body;
          var p = { access_token: i, data: g(!0, l, u) };
          return this._scrub(p.data), p;
        }),
        (x._buildBody = function(e, r, t) {
          var n;
          return (n = r ? p(e, r, t) : l(e, t));
        }),
        (x._getBrowserPlugins = function() {
          if (!this._browserPlugins) {
            var e,
              r,
              t = window.navigator.plugins || [],
              n = t.length,
              o = [];
            for (r = 0; r < n; ++r)
              (e = t[r]), o.push({ name: e.name, description: e.description });
            this._browserPlugins = o;
          }
          return this._browserPlugins;
        }),
        (x._scrub = function(e) {
          function r(e, r, t, n, o, i) {
            return r + v.redact(i);
          }
          function t(e) {
            var t;
            if (v.isType(e, "string")) for (t = 0; t < s.length; ++t) e = e.replace(s[t], r);
            return e;
          }
          function n(e, r) {
            var t;
            for (t = 0; t < a.length; ++t)
              if (a[t].test(e)) {
                r = v.redact(r);
                break;
              }
            return r;
          }
          function o(e, r) {
            var o = n(e, r);
            return o === r ? t(o) : o;
          }
          var i = this.options.scrubFields,
            a = this._getScrubFieldRegexs(i),
            s = this._getScrubQueryParamRegexs(i);
          return v.traverse(e, o), e;
        }),
        (x._getScrubFieldRegexs = function(e) {
          for (var r, t = [], n = 0; n < e.length; ++n)
            (r = "\\[?(%5[bB])?" + e[n] + "\\[?(%5[bB])?\\]?(%5[dD])?"), t.push(new RegExp(r, "i"));
          return t;
        }),
        (x._getScrubQueryParamRegexs = function(e) {
          for (var r, t = [], n = 0; n < e.length; ++n)
            (r = "\\[?(%5[bB])?" + e[n] + "\\[?(%5[bB])?\\]?(%5[dD])?"),
              t.push(new RegExp("(" + r + "=)([^&\\n]+)", "igm"));
          return t;
        }),
        (x._urlIsWhitelisted = function(e) {
          var r, t, n, o, i, a, s, u, c, l;
          try {
            if (
              ((r = this.options.hostWhiteList),
              (t = e && e.data && e.data.body && e.data.body.trace),
              !r || 0 === r.length)
            )
              return !0;
            if (!t) return !0;
            for (s = r.length, i = t.frames.length, c = 0; c < i; c++) {
              if (((n = t.frames[c]), (o = n.filename), !v.isType(o, "string"))) return !0;
              for (l = 0; l < s; l++) if (((a = r[l]), (u = new RegExp(a)), u.test(o))) return !0;
            }
          } catch (p) {
            return (
              this.configure({ hostWhiteList: null }),
              console.error(
                "[Rollbar]: Error while reading your configuration's hostWhiteList option. Removing custom hostWhiteList.",
                p
              ),
              !0
            );
          }
          return !1;
        }),
        (x._messageIsIgnored = function(e) {
          var r, t, n, o, i, a, s, u, c;
          try {
            if (((i = !1), (n = this.options.ignoredMessages), !n || 0 === n.length)) return !1;
            if (
              ((s = e && e.data && e.data.body),
              (u = s && s.trace && s.trace.exception && s.trace.exception.message),
              (c = s && s.message && s.message.body),
              (r = u || c),
              !r)
            )
              return !1;
            for (
              o = n.length, t = 0;
              t < o && ((a = new RegExp(n[t], "gi")), !(i = a.test(r)));
              t++
            );
          } catch (l) {
            this.configure({ ignoredMessages: null }),
              console.error(
                "[Rollbar]: Error while reading your configuration's ignoredMessages option. Removing custom ignoredMessages."
              );
          }
          return i;
        }),
        (x._enqueuePayload = function(e, r, t, n) {
          var o = {
              callback: n,
              accessToken: this.options.accessToken,
              endpointUrl: this._route("item/"),
              payload: e
            },
            i = function() {
              if (n) {
                var e =
                  "This item was not sent to Rollbar because it was ignored. This can happen if a custom checkIgnore() function was used or if the item's level was less than the notifier' reportLevel. See https://rollbar.com/docs/notifier/rollbar.js/configuration for more details.";
                n(null, { err: 0, result: { id: null, uuid: null, message: e } });
              }
            };
          if (this._internalCheckIgnore(r, t, e)) return void i();
          try {
            if (v.isType(this.options.checkIgnore, "function") && this.options.checkIgnore(r, t, e))
              return void i();
          } catch (a) {
            this.configure({ checkIgnore: null }),
              console.error(
                "[Rollbar]: Error while calling custom checkIgnore() function. Removing custom checkIgnore().",
                a
              );
          }
          if (this._urlIsWhitelisted(e) && !this._messageIsIgnored(e)) {
            if (this.options.verbose) {
              if (e.data && e.data.body && e.data.body.trace) {
                var s = e.data.body.trace,
                  c = s.exception.message;
                console.error("[Rollbar]: ", c);
              }
              console.info("[Rollbar]: ", o);
            }
            v.isType(this.options.logFunction, "function") && this.options.logFunction(o);
            try {
              v.isType(this.options.transform, "function") && this.options.transform(e);
            } catch (a) {
              this.configure({ transform: null }),
                console.error(
                  "[Rollbar]: Error while calling custom transform() function. Removing custom transform().",
                  a
                );
            }
            this.options.enabled && u(o);
          }
        }),
        (x._internalCheckIgnore = function(e, r, t) {
          var n = r[0],
            o = s.LEVELS[n] || 0,
            i = s.LEVELS[this.options.reportLevel] || 0;
          if (o < i) return !0;
          var a = this.options ? this.options.plugins : {};
          if (a && a.jquery && a.jquery.ignoreAjaxErrors)
            try {
              return !!t.data.body.message.extra.isAjax;
            } catch (u) {
              return !1;
            }
          return !1;
        }),
        (x._log = function(e, r, t, n, o, i, a) {
          var s = null;
          if (t)
            try {
              if (
                ((s = t._savedStackTrace ? t._savedStackTrace : m.parse(t)), t === this.lastError)
              )
                return;
              this.lastError = t;
            } catch (u) {
              console.error("[Rollbar]: Error while parsing the error object.", u),
                (r = t.message || t.description || r || String(t)),
                (t = null);
            }
          var c = this._buildPayload(new Date(), e, r, s, n);
          a && (c.ignoreRateLimit = !0), this._enqueuePayload(c, !!i, [e, r, t, n], o);
        }),
        (x.log = c()),
        (x.debug = c("debug")),
        (x.info = c("info")),
        (x.warn = c("warning")),
        (x.warning = c("warning")),
        (x.error = c("error")),
        (x.critical = c("critical")),
        (x.uncaughtError = o(function(e, r, t, n, o, i) {
          if (((i = i || null), o && v.isType(o, "error")))
            return void this._log(this.options.uncaughtErrorLevel, e, o, i, null, !0);
          if (r && v.isType(r, "error"))
            return void this._log(this.options.uncaughtErrorLevel, e, r, i, null, !0);
          var a = { url: r || "", line: t };
          (a.func = m.guessFunctionName(a.url, a.line)),
            (a.context = m.gatherContext(a.url, a.line));
          var s = {
              mode: "onerror",
              message: o ? String(o) : e || "uncaught exception",
              url: document.location.href,
              stack: [a],
              useragent: navigator.userAgent
            },
            u = this._buildPayload(new Date(), this.options.uncaughtErrorLevel, e, s, i);
          this._enqueuePayload(u, !0, [this.options.uncaughtErrorLevel, e, r, t, n, o]);
        })),
        (x.unhandledRejection = o(function(e, r) {
          if (null == e)
            return void _._log(
              _.options.uncaughtErrorLevel,
              "unhandled rejection was null or undefined!",
              null,
              {},
              null,
              !1,
              !1
            );
          var t = e.message || (e ? String(e) : "unhandled rejection"),
            n = e._rollbarContext || r._rollbarContext || null;
          if (e && v.isType(e, "error"))
            return void this._log(this.options.uncaughtErrorLevel, t, e, n, null, !0);
          var o = { url: "", line: 0 };
          (o.func = m.guessFunctionName(o.url, o.line)),
            (o.context = m.gatherContext(o.url, o.line));
          var i = {
              mode: "unhandledrejection",
              message: t,
              url: document.location.href,
              stack: [o],
              useragent: navigator.userAgent
            },
            a = this._buildPayload(new Date(), this.options.uncaughtErrorLevel, t, i, n);
          this._enqueuePayload(a, !0, [this.options.uncaughtErrorLevel, t, o.url, o.line, 0, e, r]);
        })),
        (x.global = o(function(e) {
          e = e || {};
          var r = {
            startTime: e.startTime,
            maxItems: e.maxItems,
            itemsPerMinute: e.itemsPerMinute
          };
          g(!0, window._globalRollbarOptions, r),
            void 0 !== e.maxItems && (T = 0),
            void 0 !== e.itemsPerMinute && (R = 0);
        })),
        (x.configure = o(function(e, r) {
          var t = g(!0, {}, e);
          g(!r, this.options, t), this.global(t);
        })),
        (x.scope = o(function(e) {
          var r = new s(this);
          return g(!0, r.options.payload, e), r;
        })),
        (x.wrap = function(e, r) {
          try {
            var t;
            if (
              ((t = v.isType(r, "function")
                ? r
                : function() {
                    return r || {};
                  }),
              !v.isType(e, "function"))
            )
              return e;
            if (e._isWrap) return e;
            if (!e._wrapped) {
              (e._wrapped = function() {
                try {
                  return e.apply(this, arguments);
                } catch (r) {
                  throw ("string" == typeof r && (r = new String(r)),
                  r.stack || (r._savedStackTrace = m.parse(r)),
                  (r._rollbarContext = t() || {}),
                  (r._rollbarContext._wrappedSource = e.toString()),
                  (window._rollbarWrappedError = r),
                  r);
                }
              }),
                (e._wrapped._isWrap = !0);
              for (var n in e) e.hasOwnProperty(n) && (e._wrapped[n] = e[n]);
            }
            return e._wrapped;
          } catch (o) {
            return e;
          }
        }),
        (x.loadFull = function() {
          console.error("[Rollbar]: Unexpected Rollbar.loadFull() called on a Notifier instance");
        }),
        (s.processPayloads = function(e) {
          return e ? void f() : void i();
        });
      var L = new Date().getTime(),
        T = 0,
        R = 0;
      e.exports = { Notifier: s, setupJSON: n, topLevelNotifier: a };
    },
    function(e, r) {
      "use strict";
      var t = Object.prototype.hasOwnProperty,
        n = Object.prototype.toString,
        o = function(e) {
          return "function" == typeof Array.isArray
            ? Array.isArray(e)
            : "[object Array]" === n.call(e);
        },
        i = function(e) {
          if (!e || "[object Object]" !== n.call(e)) return !1;
          var r = t.call(e, "constructor"),
            o =
              e.constructor &&
              e.constructor.prototype &&
              t.call(e.constructor.prototype, "isPrototypeOf");
          if (e.constructor && !r && !o) return !1;
          var i;
          for (i in e);
          return "undefined" == typeof i || t.call(e, i);
        };
      e.exports = function a() {
        var e,
          r,
          t,
          n,
          s,
          u,
          c = arguments[0],
          l = 1,
          p = arguments.length,
          f = !1;
        for (
          "boolean" == typeof c
            ? ((f = c), (c = arguments[1] || {}), (l = 2))
            : (("object" != typeof c && "function" != typeof c) || null == c) && (c = {});
          l < p;
          ++l
        )
          if (((e = arguments[l]), null != e))
            for (r in e)
              (t = c[r]),
                (n = e[r]),
                c !== n &&
                  (f && n && (i(n) || (s = o(n)))
                    ? (s ? ((s = !1), (u = t && o(t) ? t : [])) : (u = t && i(t) ? t : {}),
                      (c[r] = a(f, u, n)))
                    : "undefined" != typeof n && (c[r] = n));
        return c;
      };
    },
    function(e, r, t) {
      "use strict";
      function n() {
        return l;
      }
      function o() {
        return null;
      }
      function i(e) {
        var r = {};
        return (
          (r._stackFrame = e),
          (r.url = e.fileName),
          (r.line = e.lineNumber),
          (r.func = e.functionName),
          (r.column = e.columnNumber),
          (r.args = e.args),
          (r.context = o(r.url, r.line)),
          r
        );
      }
      function a(e) {
        function r() {
          var r = [];
          try {
            r = c.parse(e);
          } catch (t) {
            r = [];
          }
          for (var n = [], o = 0; o < r.length; o++) n.push(new i(r[o]));
          return n;
        }
        return { stack: r(), message: e.message, name: e.name };
      }
      function s(e) {
        return new a(e);
      }
      function u(e) {
        if (!e) return ["Unknown error. There was no error message to display.", ""];
        var r = e.match(p),
          t = "(unknown)";
        return (
          r &&
            ((t = r[r.length - 1]),
            (e = e.replace((r[r.length - 2] || "") + t + ":", "")),
            (e = e.replace(/(^[\s]+|[\s]+$)/g, ""))),
          [t, e]
        );
      }
      var c = t(6),
        l = "?",
        p = new RegExp("^(([a-zA-Z0-9-_$ ]*): *)?(Uncaught )?([a-zA-Z0-9-_$ ]*): ");
      e.exports = {
        guessFunctionName: n,
        guessErrorClass: u,
        gatherContext: o,
        parse: s,
        Stack: a,
        Frame: i
      };
    },
    function(e, r, t) {
      var n, o, i;
      !(function(a, s) {
        "use strict";
        (o = [t(7)]),
          (n = s),
          (i = "function" == typeof n ? n.apply(r, o) : n),
          !(void 0 !== i && (e.exports = i));
      })(this, function(e) {
        "use strict";
        function r(e, r, t) {
          if ("function" == typeof Array.prototype.map) return e.map(r, t);
          for (var n = new Array(e.length), o = 0; o < e.length; o++) n[o] = r.call(t, e[o]);
          return n;
        }
        function t(e, r, t) {
          if ("function" == typeof Array.prototype.filter) return e.filter(r, t);
          for (var n = [], o = 0; o < e.length; o++) r.call(t, e[o]) && n.push(e[o]);
          return n;
        }
        var n = /(^|@)\S+\:\d+/,
          o = /^\s*at .*(\S+\:\d+|\(native\))/m,
          i = /^(eval@)?(\[native code\])?$/;
        return {
          parse: function(e) {
            if ("undefined" != typeof e.stacktrace || "undefined" != typeof e["opera#sourceloc"])
              return this.parseOpera(e);
            if (e.stack && e.stack.match(o)) return this.parseV8OrIE(e);
            if (e.stack) return this.parseFFOrSafari(e);
            throw new Error("Cannot parse given Error object");
          },
          extractLocation: function(e) {
            if (e.indexOf(":") === -1) return [e];
            var r = e.replace(/[\(\)\s]/g, "").split(":"),
              t = r.pop(),
              n = r[r.length - 1];
            if (!isNaN(parseFloat(n)) && isFinite(n)) {
              var o = r.pop();
              return [r.join(":"), o, t];
            }
            return [r.join(":"), t, void 0];
          },
          parseV8OrIE: function(n) {
            var i = t(
              n.stack.split("\n"),
              function(e) {
                return !!e.match(o);
              },
              this
            );
            return r(
              i,
              function(r) {
                r.indexOf("(eval ") > -1 &&
                  (r = r
                    .replace(/eval code/g, "eval")
                    .replace(/(\(eval at [^\()]*)|(\)\,.*$)/g, ""));
                var t = r
                    .replace(/^\s+/, "")
                    .replace(/\(eval code/g, "(")
                    .split(/\s+/)
                    .slice(1),
                  n = this.extractLocation(t.pop()),
                  o = t.join(" ") || void 0,
                  i = "eval" === n[0] ? void 0 : n[0];
                return new e(o, void 0, i, n[1], n[2], r);
              },
              this
            );
          },
          parseFFOrSafari: function(n) {
            var o = t(
              n.stack.split("\n"),
              function(e) {
                return !e.match(i);
              },
              this
            );
            return r(
              o,
              function(r) {
                if (
                  (r.indexOf(" > eval") > -1 &&
                    (r = r.replace(/ line (\d+)(?: > eval line \d+)* > eval\:\d+\:\d+/g, ":$1")),
                  r.indexOf("@") === -1 && r.indexOf(":") === -1)
                )
                  return new e(r);
                var t = r.split("@"),
                  n = this.extractLocation(t.pop()),
                  o = t.shift() || void 0;
                return new e(o, void 0, n[0], n[1], n[2], r);
              },
              this
            );
          },
          parseOpera: function(e) {
            return !e.stacktrace ||
              (e.message.indexOf("\n") > -1 &&
                e.message.split("\n").length > e.stacktrace.split("\n").length)
              ? this.parseOpera9(e)
              : e.stack
                ? this.parseOpera11(e)
                : this.parseOpera10(e);
          },
          parseOpera9: function(r) {
            for (
              var t = /Line (\d+).*script (?:in )?(\S+)/i,
                n = r.message.split("\n"),
                o = [],
                i = 2,
                a = n.length;
              i < a;
              i += 2
            ) {
              var s = t.exec(n[i]);
              s && o.push(new e(void 0, void 0, s[2], s[1], void 0, n[i]));
            }
            return o;
          },
          parseOpera10: function(r) {
            for (
              var t = /Line (\d+).*script (?:in )?(\S+)(?:: In function (\S+))?$/i,
                n = r.stacktrace.split("\n"),
                o = [],
                i = 0,
                a = n.length;
              i < a;
              i += 2
            ) {
              var s = t.exec(n[i]);
              s && o.push(new e(s[3] || void 0, void 0, s[2], s[1], void 0, n[i]));
            }
            return o;
          },
          parseOpera11: function(o) {
            var i = t(
              o.stack.split("\n"),
              function(e) {
                return !!e.match(n) && !e.match(/^Error created at/);
              },
              this
            );
            return r(
              i,
              function(r) {
                var t,
                  n = r.split("@"),
                  o = this.extractLocation(n.pop()),
                  i = n.shift() || "",
                  a =
                    i.replace(/<anonymous function(: (\w+))?>/, "$2").replace(/\([^\)]*\)/g, "") ||
                    void 0;
                i.match(/\(([^\)]*)\)/) && (t = i.replace(/^[^\(]+\(([^\)]*)\)$/, "$1"));
                var s = void 0 === t || "[arguments not available]" === t ? void 0 : t.split(",");
                return new e(a, s, o[0], o[1], o[2], r);
              },
              this
            );
          }
        };
      });
    },
    function(e, r, t) {
      var n, o, i;
      !(function(t, a) {
        "use strict";
        (o = []),
          (n = a),
          (i = "function" == typeof n ? n.apply(r, o) : n),
          !(void 0 !== i && (e.exports = i));
      })(this, function() {
        "use strict";
        function e(e) {
          return !isNaN(parseFloat(e)) && isFinite(e);
        }
        function r(e, r, t, n, o, i) {
          void 0 !== e && this.setFunctionName(e),
            void 0 !== r && this.setArgs(r),
            void 0 !== t && this.setFileName(t),
            void 0 !== n && this.setLineNumber(n),
            void 0 !== o && this.setColumnNumber(o),
            void 0 !== i && this.setSource(i);
        }
        return (
          (r.prototype = {
            getFunctionName: function() {
              return this.functionName;
            },
            setFunctionName: function(e) {
              this.functionName = String(e);
            },
            getArgs: function() {
              return this.args;
            },
            setArgs: function(e) {
              if ("[object Array]" !== Object.prototype.toString.call(e))
                throw new TypeError("Args must be an Array");
              this.args = e;
            },
            getFileName: function() {
              return this.fileName;
            },
            setFileName: function(e) {
              this.fileName = String(e);
            },
            getLineNumber: function() {
              return this.lineNumber;
            },
            setLineNumber: function(r) {
              if (!e(r)) throw new TypeError("Line Number must be a Number");
              this.lineNumber = Number(r);
            },
            getColumnNumber: function() {
              return this.columnNumber;
            },
            setColumnNumber: function(r) {
              if (!e(r)) throw new TypeError("Column Number must be a Number");
              this.columnNumber = Number(r);
            },
            getSource: function() {
              return this.source;
            },
            setSource: function(e) {
              this.source = String(e);
            },
            toString: function() {
              var r = this.getFunctionName() || "{anonymous}",
                t = "(" + (this.getArgs() || []).join(",") + ")",
                n = this.getFileName() ? "@" + this.getFileName() : "",
                o = e(this.getLineNumber()) ? ":" + this.getLineNumber() : "",
                i = e(this.getColumnNumber()) ? ":" + this.getColumnNumber() : "";
              return r + t + n + o + i;
            }
          }),
          r
        );
      });
    },
    function(e, r, t) {
      "use strict";
      function n(e) {
        return {}.toString
          .call(e)
          .match(/\s([a-zA-Z]+)/)[1]
          .toLowerCase();
      }
      function o(e, r) {
        return n(e) === r;
      }
      function i(e) {
        if (!o(e, "string")) throw new Error("received invalid input");
        for (
          var r = l, t = r.parser[r.strictMode ? "strict" : "loose"].exec(e), n = {}, i = 14;
          i--;

        )
          n[r.key[i]] = t[i] || "";
        return (
          (n[r.q.name] = {}),
          n[r.key[12]].replace(r.q.parser, function(e, t, o) {
            t && (n[r.q.name][t] = o);
          }),
          n
        );
      }
      function a(e) {
        var r = i(e);
        return (
          "" === r.anchor && (r.source = r.source.replace("#", "")),
          (e = r.source.replace("?" + r.query, ""))
        );
      }
      function s(e, r) {
        var t,
          n,
          i,
          a = o(e, "object"),
          u = o(e, "array"),
          c = [];
        if (a) for (t in e) e.hasOwnProperty(t) && c.push(t);
        else if (u) for (i = 0; i < e.length; ++i) c.push(i);
        for (i = 0; i < c.length; ++i)
          (t = c[i]),
            (n = e[t]),
            (a = o(n, "object")),
            (u = o(n, "array")),
            a || u ? (e[t] = s(n, r)) : (e[t] = r(t, n));
        return e;
      }
      function u(e) {
        return (e = String(e)), new Array(e.length + 1).join("*");
      }
      function c() {
        var e = new Date().getTime(),
          r = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(r) {
            var t = (e + 16 * Math.random()) % 16 | 0;
            return (e = Math.floor(e / 16)), ("x" === r ? t : (7 & t) | 8).toString(16);
          });
        return r;
      }
      t(9);
      var l = {
          strictMode: !1,
          key: [
            "source",
            "protocol",
            "authority",
            "userInfo",
            "user",
            "password",
            "host",
            "port",
            "relative",
            "path",
            "directory",
            "file",
            "query",
            "anchor"
          ],
          q: { name: "queryKey", parser: /(?:^|&)([^&=]*)=?([^&]*)/g },
          parser: {
            strict: /^(?:([^:\/?#]+):)?(?:\/\/((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:\/?#]*)(?::(\d*))?))?((((?:[^?#\/]*\/)*)([^?#]*))(?:\?([^#]*))?(?:#(.*))?)/,
            loose: /^(?:(?![^:@]+:[^:@\/]*@)([^:\/?#.]+):)?(?:\/\/)?((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:\/?#]*)(?::(\d*))?)(((\/(?:[^?#](?![^?#\/]*\.[^?#\/.]+(?:[?#]|$)))*\/?)?([^?#\/]*))(?:\?([^#]*))?(?:#(.*))?)/
          }
        },
        p = {
          isType: o,
          parseUri: i,
          parseUriOptions: l,
          redact: u,
          sanitizeUrl: a,
          traverse: s,
          typeName: n,
          uuid4: c
        };
      e.exports = p;
    },
    function(e, r) {
      !(function(e) {
        "use strict";
        e.console = e.console || {};
        for (
          var r,
            t,
            n = e.console,
            o = {},
            i = function() {},
            a = "memory".split(","),
            s = "assert,clear,count,debug,dir,dirxml,error,exception,group,groupCollapsed,groupEnd,info,log,markTimeline,profile,profiles,profileEnd,show,table,time,timeEnd,timeline,timelineEnd,timeStamp,trace,warn".split(
              ","
            );
          (r = a.pop());

        )
          n[r] || (n[r] = o);
        for (; (t = s.pop()); ) n[t] || (n[t] = i);
      })("undefined" == typeof window ? this : window);
    },
    function(e, r, t) {
      "use strict";
      function n(e) {
        a = e;
      }
      function o(e) {
        (this.name = "Connection Error"), (this.message = e), (this.stack = new Error().stack);
      }
      var i = t(8),
        a = null;
      (o.prototype = Object.create(Error.prototype)), (o.prototype.constructor = o);
      var s = {
        XMLHttpFactories: [
          function() {
            return new XMLHttpRequest();
          },
          function() {
            return new ActiveXObject("Msxml2.XMLHTTP");
          },
          function() {
            return new ActiveXObject("Msxml3.XMLHTTP");
          },
          function() {
            return new ActiveXObject("Microsoft.XMLHTTP");
          }
        ],
        createXMLHTTPObject: function() {
          var e,
            r = !1,
            t = s.XMLHttpFactories,
            n = t.length;
          for (e = 0; e < n; e++)
            try {
              r = t[e]();
              break;
            } catch (o) {}
          return r;
        },
        post: function(e, r, t, n) {
          if (!i.isType(t, "object")) throw new Error("Expected an object to POST");
          (t = a.stringify(t)), (n = n || function() {});
          var u = s.createXMLHTTPObject();
          if (u)
            try {
              try {
                var c = function() {
                  try {
                    if (c && 4 === u.readyState) {
                      c = void 0;
                      var e = a.parse(u.responseText);
                      200 === u.status
                        ? n(null, e)
                        : i.isType(u.status, "number") && u.status >= 400 && u.status < 600
                          ? (403 == u.status && console.error("[Rollbar]:" + e.message),
                            n(new Error(String(u.status))))
                          : n(new o("XHR response had no status code (likely connection failure)"));
                    }
                  } catch (r) {
                    var t;
                    (t = r && r.stack ? r : new Error(r)), n(t);
                  }
                };
                u.open("POST", e, !0),
                  u.setRequestHeader &&
                    (u.setRequestHeader("Content-Type", "application/json"),
                    u.setRequestHeader("X-Rollbar-Access-Token", r)),
                  (u.onreadystatechange = c),
                  u.send(t);
              } catch (l) {
                if ("undefined" != typeof XDomainRequest) {
                  "http:" === window.location.href.substring(0, 5) &&
                    "https" === e.substring(0, 5) &&
                    (e = "http" + e.substring(5));
                  var p = function() {
                      n(new o("Request timed out"));
                    },
                    f = function() {
                      n(new Error("Error during request"));
                    },
                    d = function() {
                      n(null, a.parse(u.responseText));
                    };
                  (u = new XDomainRequest()),
                    (u.onprogress = function() {}),
                    (u.ontimeout = p),
                    (u.onerror = f),
                    (u.onload = d),
                    u.open("POST", e, !0),
                    u.send(t);
                }
              }
            } catch (h) {
              n(h);
            }
        }
      };
      e.exports = { XHR: s, setupJSON: n, ConnectionError: o };
    }
  ]);
});
