import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'om-program-promotion-card',
  templateUrl: './program-promotion-card.component.html',
  styleUrls: ['../card/default-styling.scss', './program-promotion-card.component.scss'],
})
export class ProgramPromotionCardComponent {
  @Output() dismiss = new EventEmitter<MouseEvent>();
  @Output() imageClick = new EventEmitter<MouseEvent>();

  @Input() title: string;
  @Input() description: string;
  @Input() tag: string;
  @Input() imageUrl: string;
  @Input() canDismiss = true;

  dismissCard(event: MouseEvent) {
    this.dismiss.emit(event);
  }
}
