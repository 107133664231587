import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, of as observableOf, throwError as observableThrowError, combineLatest } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';

import { AuthService } from '@app/core/auth.service';
import { UserService } from '@app/core/user.service';
import { StateService } from '@app/shared';
import { Address } from '@app/shared/address';
import { ServiceArea } from '@app/shared/service-area';
import { ServiceAreaService } from '@app/shared/service-area.service';
import { EnterpriseRegistrationDetails, User } from '@app/shared/user';

import {
  WhitelistedEmployee_whitelistedEmployee,
  WhitelistedEmployee_whitelistedEmployee_dependents as WhitelistedDependent,
} from './__generated__/WhitelistedEmployee';
import { MembershipType } from './membership-type';
import { RegistrationNavigation } from './registration-navigation';
import { IRegistrationStep } from './registration-step';
import { InvalidConversionError } from './whitelisted-employee-errors';

@Injectable()
export class EnterpriseRegistration {
  stepNames: string[] = [];
  _patient: User;
  b2bCompany: any;
  whitelistedEmployee: WhitelistedEmployee_whitelistedEmployee;
  enterpriseConversionComplete = false;
  private registrationForm = new FormGroup({});

  constructor(
    private registrationNavigation: RegistrationNavigation,
    private userService: UserService,
    private authService: AuthService,
    private serviceAreaService: ServiceAreaService,
    private stateService: StateService,
  ) {
    // This block adds all of the registration navigation step child forms to the parent form in this class.
    // It is important to add all of the steps' forms in the constructor to account for steps which may be fast
    // forwarded over during the registration flow.
    Object.keys(this.registrationNavigation.steps).forEach((stepName: string) => {
      const childForm = this.registrationNavigation.steps[stepName].form;
      if (childForm) {
        this.form.addControl(stepName, childForm);
        childForm.setParent(this.form);
      }
    });
    this.setUser();
  }

  currentStep(): IRegistrationStep {
    if (this.stepNames.length === 0) {
      return null;
    }
    return this.registrationNavigation.getStep(this.currentStepName);
  }

  percentComplete(): number {
    return this.currentStep().progress;
  }

  setCurrentStep(stepName: string) {
    this.destroyStep();
    this.stepNames.push(stepName);
  }

  getStep(stepName: string): IRegistrationStep {
    return this.registrationNavigation.getStep(stepName);
  }

  destroyStep() {
    if (this.currentStep()) {
      this.currentStep().onDestroy();
    }
  }

  canGoBack(): boolean {
    return this.currentStep().canGoBack(this);
  }

  showLoginLink(): boolean {
    return this.currentStep().showLoginLink;
  }

  goBack() {
    this.destroyStep();
    this.stepNames.pop();
  }

  setServiceArea(serviceArea: ServiceArea) {
    this.patient.serviceArea = serviceArea;
    this.details.serviceAreaCode = serviceArea.code;
  }

  get serviceArea(): ServiceArea {
    return this.patient.serviceArea;
  }

  patchParams(params: { [k: string]: any }) {
    Object.keys(this.registrationNavigation.steps).forEach(stepName => {
      this.registrationNavigation.getStep(stepName).patchParams(params);
    });
  }

  redirectParams(): string {
    const { activationCode, employeeId, workEmail, b2bCompanyId } = this;
    const params = {
      b2b_company_id: (b2bCompanyId || '').toString(),
      discount_code: activationCode,
      employee_id: employeeId,
      email: workEmail || '',
    };
    return new URLSearchParams(params).toString();
  }

  // True if the eligible employee's company has dependents on their eligibility file.
  // In that case, we should validate dependent information against the eligibility file.
  hasEligibleDependents(): boolean {
    return !!this.whitelistedEmployee && this.whitelistedEmployee.b2bCompany.hasEligibleDependents;
  }

  // True if the eligible employee has any unregistered dependents on the eligibility file.
  // In that case, we should allow dependent registration and validate their information against the eligibility file.
  hasUnregisteredDependents(): boolean {
    return (
      !!this.whitelistedEmployee &&
      this.whitelistedEmployee.dependents.some((dependent: WhitelistedDependent) => !dependent.registered)
    );
  }

  whitelistedEmployeeRegistered(): boolean {
    return this.whitelistedEmployee && this.whitelistedEmployee.registered;
  }

  dependentQueryParams(reCaptchaToken: string): any {
    const { employeeId, b2bCompanyId, workEmail } = this;
    const { firstName, lastName, dob } = this.patient;
    return {
      firstName: firstName || '',
      lastName: lastName || '',
      dateOfBirth: dob,
      b2bCompanyId: b2bCompanyId ? b2bCompanyId.toString() : '',
      parentId: employeeId || workEmail,
      reCaptchaToken,
    };
  }

  submitAccountConversion(userService: UserService, captcha: any, includesDependent: boolean): Observable<any> {
    userService.getUser();

    try {
      this.setInferredMembershipType(this.patient, includesDependent);
    } catch (err) {
      return observableThrowError(err);
    }
    if (!this.details.membershipType) {
      // Need to continue to the membership selection step and have the user select that
      // before we can submit the enterprise conversion
      this.setCurrentStep('membershipSelection');
      return observableOf(true);
    } else {
      return this.serviceAreaIsValid$().pipe(
        take(1),
        switchMap(isValid => {
          if (isValid) {
            return this.sendConversionRequest$(userService, captcha);
          } else {
            this.setCurrentStep('serviceArea');
            return observableOf(true);
          }
        }),
        switchMap(wasConverted => {
          if (wasConverted) {
            return this.updateConvertedUser$(userService);
          }
        }),
        catchError(() => {
          throw new Error('enterpriseConversionError');
        }),
      );
    }
  }

  updateConvertedUser$(userService: UserService) {
    return combineLatest([this.userService.user$, this.stateService.states$]).pipe(
      switchMap(([_user, states]) => {
        const { phoneNumber, address, dob, gender, genderDetails, preferredName } = this.patient;
        const stateId = states.find(s => s.short_name === address.state).id;
        address.stateId = stateId;
        return this.userService
          .updateUserProfile({
            dob,
            sex: gender,
            address: Address.forApiV2(address),
            gender_details: genderDetails,
            phone_number: phoneNumber,
            preferred_name: preferredName,
          })
          .pipe(take(1));
      }),
      take(1),
      catchError(err => observableThrowError(err)),
    );
  }

  private sendConversionRequest$(userService: UserService, captcha: any): Observable<any> {
    return captcha.getToken().pipe(
      switchMap((conversionReCaptchaToken: string) =>
        userService.submitEnterpriseConversion(this.patient, conversionReCaptchaToken).pipe(
          map(() => {
            this.enterpriseConversionComplete = true;
            this.setCurrentStep('success');
            return this.patient;
          }),
          take(1),
          catchError((error: HttpErrorResponse) => {
            if (error.error.type === 'Membership::Generator::B2bMembership::InvalidB2bConversionError') {
              throw new InvalidConversionError();
            }
            throw new Error('enterpriseConversionError');
          }),
        ),
      ),
    );
  }

  private serviceAreaIsValid$(): Observable<boolean> {
    return this.serviceAreaService.getServiceAreas(this.b2bCompanyId).pipe(
      take(1),
      map(areas => areas.some(area => area.code === this.patient.serviceArea.code)),
    );
  }

  private setInferredMembershipType(loggedInPatient: User, includesDependent: boolean) {
    let inferredMembershipType;
    if (loggedInPatient.isPediatric()) {
      if (includesDependent) {
        inferredMembershipType = MembershipType.KIDS;
      } else {
        throw new Error('planDoesntSupportDependents');
      }
    } else {
      if (!includesDependent) {
        inferredMembershipType = MembershipType.PERSONAL;
      }
    }

    if (!this.details.membershipType && inferredMembershipType) {
      this.details.membershipType = inferredMembershipType;
      this.patchParams({ membershipType: inferredMembershipType });
    }
  }

  setUser() {
    if (this.authService.isLoggedIn()) {
      this.userService.getUser();
      this.userService.user$.subscribe(user => {
        // This is for the special case where a logged-in user arrives with a pre-populated activation code.
        // if an email is supplied in the form, we skip the email entry input and assume that their login email
        // is their work email, which is likely not true. So this forces them to input work email directly.
        const email = user.email;
        delete user.email;
        this.patchParams(user);
        user.email = email;
        this.patient = user;
      });
    } else {
      this.patient = new User();
    }
  }

  userIsComplete() {
    const user = this.patient;
    if (
      user.firstName &&
      user.lastName &&
      user.address.address1 &&
      user.phoneNumber &&
      user.dob &&
      user.gender &&
      user.email
    ) {
      return true;
    } else {
      return false;
    }
  }

  get form(): FormGroup {
    return this.registrationForm;
  }

  get currentStepName(): string {
    return this.stepNames[this.stepNames.length - 1];
  }

  get isWhitelisted(): boolean {
    return !!this.whitelistedEmployeeId;
  }

  // The terminology here is somewhat confusing...
  // This refers to the id of the whitelisted employee row in the database. NOT the employee id the user types in.
  get whitelistedEmployeeId(): number {
    return this.details.whitelistedEmployeeId;
  }

  get workEmail(): string {
    return this.details.workEmail;
  }

  get b2bCompanyId(): number {
    const b2bCompanyId = this.details.b2bCompanyId;
    return b2bCompanyId;
  }

  get activationCode(): string {
    return this.form.value.activationCode.activationCode.trim();
  }

  get b2bEmailDomain(): string {
    return this.details.workEmail.split('@')[1];
  }

  get employeeId(): string {
    return this.details.employeeId || '';
  }

  get canRetrieveCodeViaEmail(): boolean {
    return this.b2bCompany && (this.b2bCompany.b2bEmailDomains || []).includes(this.b2bEmailDomain);
  }

  get isAdultDependent(): boolean {
    return this.form.value.membershipSelection.membershipType === MembershipType.SPOUSE;
  }

  get patient(): User {
    return this._patient;
  }

  set patient(newPatient) {
    this._patient = newPatient;
  }

  get address(): Address {
    return this.patient.address;
  }

  get details(): EnterpriseRegistrationDetails {
    if (!this.patient) {
      this.patient = new User();
    }
    const details = this.patient.enterpriseRegistrationDetails || new EnterpriseRegistrationDetails();
    this.patient.enterpriseRegistrationDetails = details;
    return details;
  }
}
