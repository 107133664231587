import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { AccountActiveGuardService } from '@app/core/account-active-guard.service';
import { DocumentSignerGraphQL } from '@app/core/document-signer-graphql.service';
import { GuestGuardService } from '@app/core/guest-guard.service';
import { LegalDocumentsModule } from '@app/core/legal-documents';
import { LinksService } from '@app/core/links.service';
import { LoginGuardService } from '@app/core/login-guard.service';
import { MembershipService } from '@app/core/membership.service';
import { MobileAppRedirectGuardService } from '@app/core/mobile-app-redirect-guard.service';
import { NavigatorService } from '@app/core/navigator.service';
import { TosGuardService } from '@app/core/tos-guard.service';
import { PatientPreferencesService } from '@app/membership/patient-preferences.service';

import { AppointmentService } from '../appointment/appointment.service';
import { SharedModule } from '../shared/shared.module';
import {
  MembershipB2bRenewGuardService,
  MembershipConsumerRenewGuardService,
  MembershipOMNowRenewGuardService,
  MembershipRenewGuardService,
} from './../membership/membership-renew-guard.service';
import { AdultOnlyGuardService } from './adult-only-guard.service';
import { AnalyticsService } from './analytics.service';
import { ApiService } from './api.service';
import { APMService } from './apm.service';
import { AttemptedPathService } from './attempted-path.service';
import { AuthGuardService } from './auth-guard.service';
import { AuthInitializedGuardService } from './auth-initialized-guard.service';
import { AwsService } from './aws.service';
import { DateService } from './date.service';
import { DesignComponent } from './design/design.component';
import { DevRouteGuardService } from './dev-route-guard.service';
import { EnterpriseCorePlusPlanGuardService } from './enterprise-core-plus-plan-guard.service';
import { FitbitService } from './fitbit.service';
import { GraphqlService } from './graphql.service';
import { LoginFormComponent } from './login-form/login-form.component';
import { LoginService } from './login.service';
import { Login } from './login/login.component';
import { Logout } from './logout/logout.component';
import { MarkdownService } from './markdown.service';
import { ProviderService } from './provider.service';
import { RegistrationCompleteGuardService } from './registration-complete-guard.service';
import { RollbarService } from './rollbar.service';
import { StorageService } from './storage.service';
import { TosService } from './tos.service';
import { TosInterstitialComponent } from './tos/tos-interstitial.component';
import { UserAlertService } from './user-alert.service';
import { UserService } from './user.service';
import { WindowService } from './window.service';

@NgModule({
  declarations: [Login, LoginFormComponent, Logout, TosInterstitialComponent, DesignComponent],
  imports: [CommonModule, FormsModule, SharedModule, LegalDocumentsModule, RouterModule],
  providers: [
    APMService,
    AccountActiveGuardService,
    AdultOnlyGuardService,
    AnalyticsService,
    ApiService,
    AppointmentService,
    AttemptedPathService,
    AuthGuardService,
    AuthInitializedGuardService,
    AwsService,
    DateService,
    DevRouteGuardService,
    DocumentSignerGraphQL,
    EnterpriseCorePlusPlanGuardService,
    FitbitService,
    GraphqlService,
    GuestGuardService,
    LinksService,
    LoginGuardService,
    LoginService,
    MarkdownService,
    MembershipB2bRenewGuardService,
    MembershipConsumerRenewGuardService,
    MembershipOMNowRenewGuardService,
    MembershipRenewGuardService,
    MembershipService,
    MobileAppRedirectGuardService,
    NavigatorService,
    PatientPreferencesService,
    ProviderService,
    RegistrationCompleteGuardService,
    RollbarService,
    StorageService,
    TosGuardService,
    TosService,
    UserAlertService,
    UserService,
    WindowService,
  ],
  exports: [],
})
export class CoreModule {}
