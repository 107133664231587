<form [formGroup]="sexAndGenderForm">
  <div class="btn-group btn-group-toggle col-10 col-sm-6 p-0" ngbRadioGroup formControlName="sex" id="sex">
    <label ngbButtonLabel class="btn-outline-primary male">
      <input ngbButton type="radio" [value]="'male'" /> Male
    </label>
    <label ngbButtonLabel class="btn-outline-primary female">
      <input ngbButton type="radio" [value]="'female'" /> Female
    </label>
  </div>

  <div class="py-2" *ngIf="!showAdditionalInfo">
    <a href="" (click)="showInfo($event)" class="gender-details"> + Add gender information </a>
  </div>

  <div class="py-2 mt-4" *ngIf="showAdditionalInfo">
    <label> Gender Information (optional) </label>
    <textarea
      formControlName="genderDetails"
      autofocus
      #genderDetailsInput
      cols="40"
      rows="4"
      class="form-control"
    ></textarea>
  </div>
</form>
