import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

import { AttestSelfPayIsPrimaryInsurance } from '@app/account/profile/__generated__/AttestSelfPayIsPrimaryInsurance';

@Injectable()
export class AttestSelfPayIsPrimaryInsuranceGraphQL extends Mutation<AttestSelfPayIsPrimaryInsurance, null> {
  document = gql`
    mutation AttestSelfPayIsPrimaryInsurance {
      attestSelfPayIsPrimaryInsurance(input: {}) {
        success
        errors
        patient {
          id
          profileInfo {
            insurancesManuallyVerifiedAt
            onlyHasSelfPay
          }
        }
      }
    }
  `;
}
