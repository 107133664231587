import each from 'lodash-es/each';
import moment from 'moment-timezone';

import { Office } from '../shared/office';
import { Provider } from '../shared/provider';

export class Appointment {
  static readonly fieldMapping = {
    id: 'id',
    differentOnsiteTimezone: 'is_different_onsite_timezone',
    officeId: 'office_id',
    providerId: 'provider_id',
    reason: 'reason',
    remote: 'is_remote',
    startTime: 'start_at',
    appointmentTypeId: 'appointment_type_id',
    appointmentTypeName: 'appointment_type_name',
    duration: 'display_duration',
    remoteMeetingLink: 'remote_meeting_link',
  };

  id: number;
  differentOnsiteTimezone: boolean;
  officeId: number;
  providerId: number;
  reason: string;
  remote: boolean;
  office: Office;
  provider: Provider;
  startTime: moment.Moment;
  endTime: moment.Moment;
  timezone: string;
  appointmentTypeId: number;
  appointmentTypeName: string;
  duration: number;
  remoteMeetingLink: string;

  static fromApiV2(response): Appointment {
    const appointment = new Appointment();
    each(Appointment.fieldMapping, (value, key) => {
      appointment[key] = response[value];
    });

    appointment.timezone = response.timezone_tzinfo;
    appointment.startTime = moment(appointment.startTime).tz(appointment.timezone);

    return appointment;
  }

  static fromGraphQL(object: any): Appointment {
    const appointment = new Appointment();
    appointment.id = object.id;
    appointment.provider = Provider.fromGraphQL(object.provider);
    appointment.startTime = object.startAt;
    return appointment;
  }
}
