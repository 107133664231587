import { Pipe, PipeTransform } from '@angular/core';

import { PhoneNumberFormatter } from './phone-number-formatter';

@Pipe({
  name: 'phoneNumber',
})
export class PhoneNumberPipe implements PipeTransform {
  transform(number: string) {
    return new PhoneNumberFormatter(number).display;
  }
}
