import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { AuthService } from '@app/core/auth.service';
import { UserService } from '@app/core/user.service';
import { OfficeService } from '@app/shared/office.service';

import { ServiceAreaService } from '../../../shared/service-area.service';
import { EnterpriseRegistration } from '../enterprise-registration';
import { EnterpriseRegistrationAnalyticsService } from '../enterprise-registration-analytics.service';
import { IRegistrationStep, RegistrationStep } from '../registration-step';
import { ServiceAreaSelectionStepComponent } from './service-area-selection-step.component';

@Injectable()
export class ServiceAreaConfig extends RegistrationStep implements IRegistrationStep {
  GA_LABEL = 'Where_Are_You_Located_Step';

  MODULE = 'Service Area Page';
  component = ServiceAreaSelectionStepComponent;
  progress = 62.5;

  form: FormGroup = this.formBuilder.group({
    serviceArea: ['', Validators.required],
  });

  constructor(
    private enterpriseRegistrationAnalyticsService: EnterpriseRegistrationAnalyticsService,
    private serviceAreaService: ServiceAreaService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private authService: AuthService,
    private officeService: OfficeService,
  ) {
    super();
  }

  initComponent(component: ServiceAreaSelectionStepComponent) {
    component.form = this.form;
    this.officeService.getRoundedOfficeCount().subscribe(count => {
      component.numberOfOffices = count;
    });
    this.trackPageView();
  }

  submit(state: EnterpriseRegistration, captcha: any): Observable<any> {
    if (this.form.valid) {
      const result = this.serviceAreaService.getServiceAreas().pipe(
        take(1),
        switchMap(serviceAreas => {
          const selectedServiceArea = serviceAreas.find(area => area.code === this.form.value.serviceArea);

          state.setServiceArea(selectedServiceArea);
          this.trackSubmission(state);
          if (this.authService.isLoggedIn() && state.userIsComplete()) {
            return state.submitAccountConversion(this.userService, captcha, state.b2bCompany.includesDependent);
          } else {
            state.setCurrentStep('createAccount');
            return observableOf(true);
          }
        }),
      );
      return result;
    }
    return observableThrowError(new Error());
  }

  patchParams(params: { [k: string]: any }) {
    const { serviceArea } = params;
    if (serviceArea) {
      this.form.patchValue({ serviceArea: serviceArea.code });
      this.shouldFastForward = false;
    }
  }

  private trackSubmission({ isWhitelisted: isWhitelist, serviceArea: { code: serviceArea } }: EnterpriseRegistration) {
    this.enterpriseRegistrationAnalyticsService.regInputSubmitted({
      isWhitelist,
      serviceArea,
      module: this.MODULE,
    });
  }

  private trackPageView() {
    this.enterpriseRegistrationAnalyticsService.trackGoogleEvent(this.GA_ACTION, this.GA_LABEL);
  }
}
