<svg
  version="1.1"
  id="Layer_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 560.6 69.4"
  style="max-width: 100%; max-height: 100%"
  xml:space="preserve"
>
  <title>One Medical</title>
  <path
    class="st0"
    d="M305.2,17.1c-6.8,0-12.1,3.9-14.9,10.5c-2.2-6.7-7.5-10.5-14-10.5s-11.3,3.9-13.8,10.3v-8.8h-10.4V68h10.4V38.4
    c0-7.6,3.8-12.9,10-12.9c5.8,0,8.8,3.9,8.8,11.5v31h10.3V37.6c0-7.1,3.8-12.1,10.1-12.1c5.8,0,8.5,3.9,8.5,11.8V68h10.3V35.5
    C320.5,24,314.5,17.1,305.2,17.1z M198.4,17.1c-15,0-24.6,10.7-24.6,25.8c0,16,10.9,26.5,26.6,26.5c7.9,0,14.3-2.8,18.4-6.7v-6.4
    c-3.8,3.6-9.6,5.7-15.5,5.7c-10.7,0-18.2-6.5-18.6-19h35.6v-3.3C220.2,25.4,211.7,17.1,198.4,17.1z M185,37
    c1.3-7.9,5.7-13.2,13.3-13.2c6.8,0,11.5,4.5,11.7,13.2H185z M92.6,17.1c-14.2,0-24.7,11.4-24.7,26.2s10.4,26.1,24.7,26.1
    c14.4,0,24.8-11.3,24.8-26.1S107,17.1,92.6,17.1z M92.6,62.6C84.1,62.6,79,55,79,43.3S84.1,24,92.6,24c8.7,0,13.8,7.6,13.8,19.3
    S101.2,62.6,92.6,62.6z M150.3,17.1c-8.1,0-13.6,4.5-16.3,11.3v-9.8h-10.3V68H134V40.8c0-9.2,4.6-15.1,12.7-15.1
    c7.4,0,10.5,4.4,10.5,13.7V68h10.3V37.1C167.5,24.5,160.7,17.1,150.3,17.1z M416.6,27.8c-3.1-6.8-8.7-10.7-16.2-10.7
    c-12.7,0-22.7,11.4-22.7,26.6c0,14.9,9.3,25.7,21.7,25.7c7.6,0.2,14.5-4.5,17.2-11.7V68h10.3V0.9h-10.3V27.8z M402.5,61.9
    c-8.2,0-13.6-7.3-13.6-18.4c0-11.3,5.5-19,14-19s14,7.6,14,18.5C416.8,54.1,411,61.9,402.5,61.9z M480.9,62.1c-11,0-17-8.2-17-19
    c0-11,6.5-18.6,16.7-18.6c5.5,0,10.1,2.1,14.1,6.9v-7.7c-3.6-4-9.1-6.6-16.3-6.6c-15,0-25.6,11.4-25.6,26.2s10.6,26.1,25.5,26.1
    c6.4,0.1,12.6-2.4,17-7.1v-6.8C491.7,59.7,486.4,62.1,480.9,62.1L480.9,62.1z M351.5,17.1c-15,0-24.8,10.7-24.8,25.8
    c0,16,11,26.5,26.7,26.5c7.9,0,14.3-2.8,18.3-6.7v-6.4c-3.7,3.6-9.6,5.7-15.5,5.7c-10.6,0-18.1-6.5-18.6-19h35.6v-3.3
    C373.3,25.4,364.7,17.1,351.5,17.1L351.5,17.1z M338,37c1.2-7.9,5.7-13.2,13.2-13.2c6.8,0,11.6,4.5,11.8,13.2H338z M520.8,17.1
    c-6.7-0.2-13.2,2.2-18.2,6.6v7.8c3.8-4.5,9.5-7.1,15.4-7c7.9,0,12.8,4.3,12.8,13.2v2h-9.3c-14.6,0-21.8,6.1-21.8,14.8
    c0,8.4,6.6,14.8,15.9,14.8c7.6,0,12.7-3.8,15.2-9.3v8h10.3V37.2C541.2,24.5,533.8,17.1,520.8,17.1z M530.8,49.4
    c0,7.9-4.5,12.7-11.7,12.7c-5.8,0-8.9-3.1-8.9-8c0-5.4,3.7-8.7,12-8.7h8.7L530.8,49.4z M441.3,0c-3.4,0-6.2,2.8-6.2,6.2
    s2.8,6.2,6.2,6.2c3.4,0,6.2-2.8,6.2-6.2c0,0,0,0,0,0C447.5,2.8,444.7,0,441.3,0z M436.2,68h10.3V18.6h-10.3L436.2,68z M550.2,0.9V68
    h10.4V0.9H550.2z M6.2,37.1c-3.4,0-6.2,2.8-6.2,6.2c0,3.4,2.8,6.2,6.2,6.2s6.2-2.8,6.2-6.2c0,0,0,0,0,0C12.4,39.9,9.6,37.1,6.2,37.1
    C6.2,37.1,6.2,37.1,6.2,37.1z M24.7,37.1c-3.4,0-6.2,2.8-6.2,6.2c0,3.4,2.8,6.2,6.2,6.2c3.4,0,6.2-2.8,6.2-6.2c0,0,0,0,0,0
    C30.9,39.9,28.1,37.1,24.7,37.1z M24.7,55.6c-3.4,0-6.2,2.8-6.2,6.2c0,3.4,2.8,6.2,6.2,6.2s6.2-2.8,6.2-6.2c0,0,0,0,0,0
    C30.9,58.4,28.1,55.6,24.7,55.6z M24.7,18.6c-3.4,0-6.2,2.8-6.2,6.2c0,3.4,2.8,6.2,6.2,6.2c3.4,0,6.2-2.8,6.2-6.2c0,0,0,0,0,0
    C30.9,21.4,28.2,18.6,24.7,18.6C24.7,18.6,24.7,18.6,24.7,18.6L24.7,18.6z M43.2,37.1c-3.4,0-6.2,2.8-6.2,6.2c0,3.4,2.8,6.2,6.2,6.2
    c3.4,0,6.2-2.8,6.2-6.2c0,0,0,0,0,0C49.4,39.9,46.6,37.1,43.2,37.1C43.2,37.1,43.2,37.1,43.2,37.1z"
  />
</svg>
