import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'om-name-input',
  templateUrl: './name-input.component.html',
  styleUrls: ['../form-input.scss'],
})
export class NameInputComponent {
  constructor(public formBuilder: FormBuilder) {}

  @Input() hasPreferredOption = true;
  @Input() firstNameLabel: string;
  @Input() lastNameLabel: string;
  @Input() isDisabled = false;
  @Input() nameForm: FormGroup;
  @Input() enableAutoFocus = false;

  showingPreferredName = false;

  showPreferred($event) {
    this.showingPreferredName = true;
    $event.preventDefault();
  }
}
