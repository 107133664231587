import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'om-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [trigger('fadeInOut', [state('void', style({ opacity: 0 })), transition('void <=> *', animate(200))])],
})
export class ToastComponent {
  static timeoutMs = 6000;

  message: string;
  visible = false;
  private timeout: NodeJS.Timer;

  constructor() {}

  show(message: string): void {
    this.message = message;
    this.visible = true;

    this.timeout = setTimeout(() => {
      this.close();
    }, ToastComponent.timeoutMs);
  }

  close() {
    this.visible = false;
    this.message = undefined;

    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = undefined;
    }
  }
}
