import { Injectable } from '@angular/core';

import { ConfigService } from '../../core/config.service';
declare const Stripe: stripe.StripeStatic;

@Injectable()
export class StripeService {
  private stripe: stripe.Stripe;
  private elements: stripe.elements.Elements;

  constructor(public configService: ConfigService) {
    const stripeKey = configService.json.stripePublishableKey;
    if (!stripeKey) {
      console.error('Stripe key is not defined in this environment');
      return;
    }

    this.stripe = Stripe(stripeKey);
    this.elements = this.stripe.elements();
  }

  createElement(type: stripe.elements.elementsType, options: stripe.elements.ElementsOptions): stripe.elements.Element {
    return this.elements.create(type, options);
  }

  createToken(element: stripe.elements.Element): Promise<stripe.TokenResponse> {
    return this.stripe.createToken(element);
  }
}
