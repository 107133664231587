import { Injectable } from '@angular/core';
import { fromEvent as observableFromEvent, Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

export interface WindowSize {
  width: number;
  height: number;
}

export interface WindowScroll {
  x: number;
  y: number;
}

interface RedirectOptions {
  newTab?: boolean;
}

declare global {
  interface Window {
    redirect: Function;
  }
}

@Injectable()
export class WindowService {
  scrollStream: Observable<WindowScroll>;
  windowSize: WindowSize;
  appInstallChoiceStream: ReplaySubject<any>;

  constructor() {
    this.windowSize = { width: window.innerWidth, height: window.innerHeight };
    const windowScrollStream = observableFromEvent<Event>(window, 'scroll');
    this.appInstallChoiceStream = new ReplaySubject();
    const installPromptStream = observableFromEvent<Event>(window, 'beforeinstallprompt');

    this.scrollStream = windowScrollStream.pipe(
      map((event): WindowScroll => ({ x: window.pageXOffset, y: window.pageYOffset })),
    );

    installPromptStream.subscribe((event: any) => {
      event.userChoice.then(choice => {
        this.appInstallChoiceStream.next(choice);
      });
    });
  }

  getLocationSearch() {
    return window.location.search;
  }

  getLocationOrigin() {
    return window.location.origin;
  }

  getWindowSize() {
    return this.windowSize;
  }

  getFrames() {
    return window.frames;
  }

  getURL() {
    return window.URL;
  }

  getLocationPathname() {
    return window.location.pathname;
  }

  isAndroid(): boolean {
    return /Android/.test(this.getUserAgent());
  }

  isIos(): boolean {
    const userAgent = this.getUserAgent();
    return /iPad/.test(userAgent) || /iPhone/.test(userAgent);
  }

  isMobile(): boolean {
    return this.isAndroid() || this.isIos();
  }

  isWebKitView(): boolean {
    const isWebKit = (window as any).webkit && (window as any).webkit.messageHandlers;
    return this.isIos() && isWebKit;
  }

  redirect(location: string, options: RedirectOptions = {}) {
    // Adding a method on window that redirects allows us to stub the redirect functionality in
    // cypress tests, allowing us to test external redirects
    if (!window.redirect) {
      window.redirect = loc => {
        window.location.href = loc;
      };
    }

    const { newTab } = options;
    if (newTab) {
      window.open(location, '_blank');
    } else {
      window.redirect(location);
    }
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  private getUserAgent(): string {
    return navigator.userAgent;
  }
}
