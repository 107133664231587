import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { InlineForm } from '../inline-form/inline-form.component';

@Component({
  selector: 'om-delete-confirmation-inline-form',
  templateUrl: './delete-confirmation-inline-form.component.html',
})
export class DeleteConfirmationInlineFormComponent {
  @Input() inlineForm: InlineForm;
  @Input() formGroup: FormGroup;
  @Input() modalTitle: string;
  @Input() modalBody: string;
  @Output() formSubmit = new EventEmitter<any>();
  @Output() formDelete = new EventEmitter<void>();
  @ViewChild('deleteConfirmationModal') deleteConfirmationModal;

  constructor(private modalService: NgbModal) {}

  showDeleteConfirmationModal() {
    const deleteModal = this.modalService.open(this.deleteConfirmationModal, {
      centered: true,
      backdropClass: 'no-transition',
      size: 'sm',
    });
    deleteModal.result
      .then(confirmed => {
        if (confirmed) {
          this.formDelete.emit();
        } else {
          this.inlineForm.submitting = false;
        }
      })
      .catch(() => (this.inlineForm.submitting = false));
  }
}
