import { SafeHtml } from '@angular/platform-browser';

import { Provider } from '../shared/provider';

export class LabOrderContent {
  fastingRequired: boolean;
  numberOfTests: number;
  testsOrdered: string[];
  appointmentNeeded: boolean;
}

export class VisitFollowUpOrderContent {
  visitReason: string;
  visitWithProvider: Provider;
  appointmentType: string;
  appointmentTypeId: number;
}

export class BasicFollowUpOrderContent {
  problem: string;
  feedbackOptions: string[];
  feedback: string;
}

export class GenericFollowUpOrderContent {
  question: string;
  message: string;
  title: string;
}

export class VaccineOrderContent {
  isAutomated: boolean;
  name: string;
  step: string;
  patientEduText: SafeHtml;
  walkin: boolean;
  isSeries: boolean;
  isFluVaccine: boolean;
  information: SafeHtml;
  instruction: string;
  title: string;
}

export class SurveyOrderContent {
  isFormstack: boolean;
  surveyId?: number;
  formId?: number;
  instructions: string;
}

export class ConsultOrderContent {
  providerImageUrl: string;
  providerName: string;
  consultantDisplayName: string;
  consultantPhoneNumber: string;
  consultantSpecialtyName: string;
  consultantFacilityName: string;
  consultantAddress1: string;
  consultantAddress2: string;
  consultantCity: string;
  consultantState: string;
  consultantZip: string;
  consultantLatitude: string;
  consultantLongitude: string;
}

export class ProcedureOrderContent {
  providerImageUrl: string;
  providerName: string;
  consultantDisplayName: string;
  consultantPhoneNumber: string;
  consultantSpecialtyName: string;
  consultantFacilityName: string;
  consultantAddress1: string;
  consultantAddress2: string;
  consultantCity: string;
  consultantState: string;
  consultantZip: string;
  consultantLatitude: string;
  consultantLongitude: string;
}
