import { Component, Input } from '@angular/core';

import { RegistrationStepDirective } from '../registration-step.directive';

@Component({
  selector: 'om-employee-id-step',
  styleUrls: ['../../../shared/form-input.scss'],
  templateUrl: './employee-id-step.component.html',
})
export class EmployeeIdStepComponent extends RegistrationStepDirective {
  @Input() errorMessage = '';
  @Input() noB2bCompanyIdError = false;
  hasError = false;
  submitting = false;

  onError(err: Error) {
    this.submitting = false;
    this.hasError = true;
  }

  onSubmit() {
    this.submitting = true;
    this.form.controls.employeeId.markAsTouched();
    this.form.controls.employeeId.markAsDirty();
    this.submit.emit(this.form.value);
  }
}
