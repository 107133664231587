<div class="d-flex justify-content-between">
  <div *ngIf="pageState | isLoadingState" class="flex-grow-1">
    <h5 class="mb-2 d-block primary-text-placeholder"></h5>
    <p class="mb-0 secondary-text-placeholder"></p>
  </div>
  <div *ngIf="!(pageState | isLoadingState)" class="d-flex justify-content-start">
    <div class="d-flex align-items-center mr-3" *ngIf="icon" data-cy="icon">
      <ng-container *ngTemplateOutlet="icon.templateRef"></ng-container>
    </div>
    <div>
      <p class="mb-1" *ngIf="tertiaryText" data-cy="tertiary-text">
        <ng-container *ngTemplateOutlet="tertiaryText.templateRef"></ng-container>
      </p>
      <h5 *ngIf="primaryText" class="mb-0" data-cy="primary-text">
        <ng-container *ngTemplateOutlet="primaryText.templateRef"></ng-container>
      </h5>
      <p class="mb-1" *ngIf="secondaryText" data-cy="secondary-text">
        <ng-container *ngTemplateOutlet="secondaryText.templateRef"></ng-container>
      </p>
    </div>
  </div>
  <div class="d-inline-flex align-items-center ml-3" data-cy="action-container">
    <ng-content select="omgui-action" *ngIf="action"></ng-content>
    <div *ngIf="linkCaret">
      <om-svg-caret-right></om-svg-caret-right>
    </div>
  </div>
</div>
<div class="d-flex justify-content-start flex-grow-1 mt-3" *ngIf="expandableContent && expanded" @expandCollapse>
  <div class="mr-3 invisible" aria-hidden="true" *ngIf="icon">
    <ng-container *ngTemplateOutlet="icon.templateRef"></ng-container>
  </div>
  <div class="flex-grow-1">
    <ng-container *ngTemplateOutlet="expandableContent.templateRef"></ng-container>
  </div>
</div>
