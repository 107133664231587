import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import includes from 'lodash-es/includes';
import map from 'lodash-es/map';
import max from 'lodash-es/max';
import min from 'lodash-es/min';
import sortBy from 'lodash-es/sortBy';
import without from 'lodash-es/without';

@Component({
  selector: 'om-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnChanges {
  static MAX_DISPLAYED_PAGES = 9;
  static MAX_RESPONSIVE_DISPLAYED_PAGES = 5;
  @Input() currentPage: number;
  @Input() totalPages: number;
  @Output() pageOutput = new EventEmitter();
  @Output() goBack = new EventEmitter();
  @Output() goForward = new EventEmitter();
  pages: number[];
  responsivePages: number[];
  onFirstPage: boolean;
  onLastPage: boolean;

  constructor() {}

  ngOnChanges() {
    this.pages = this.buildPagesSet(PaginationComponent.MAX_DISPLAYED_PAGES);
    this.responsivePages = this.buildPagesSet(PaginationComponent.MAX_RESPONSIVE_DISPLAYED_PAGES);
    this.onFirstPage = this.currentPage === 1;
    this.onLastPage = this.currentPage === this.totalPages;
  }

  buildPagesSet(maxPages: number): number[] {
    if (this.totalPages > maxPages) {
      let pages = [this.currentPage];
      while (pages.length < maxPages) {
        const maxPage = max(pages);
        const minPage = min(pages);
        if (pages.length < maxPages && maxPage < this.totalPages) {
          pages.push(maxPage + 1);
        }
        if (pages.length < maxPages && minPage > 1) {
          pages.push(minPage - 1);
        }
      }

      pages = this.injectFirstPage(pages);
      pages = this.injectLastPage(pages, this.totalPages);

      return sortBy(pages);
    } else {
      const pages = new Array(this.totalPages);
      return map(pages, (value, index) => index + 1);
    }
  }

  goToPage(page) {
    this.pageOutput.emit(page);
  }

  back() {
    this.goBack.emit();
  }

  forward() {
    this.goForward.emit();
  }

  isHiddenForSmallViews(page: number): boolean {
    return !includes(this.responsivePages, page);
  }

  private injectFirstPage(pages: number[]): number[] {
    if (!includes(pages, 1)) {
      const minPage = min(pages);
      pages = without(pages, minPage);
      pages.push(1);
    }
    return pages;
  }

  private injectLastPage(pages: number[], lastPage: number): number[] {
    if (!includes(pages, lastPage)) {
      const maxPage = max(pages);
      pages = without(pages, maxPage);
      pages.push(lastPage);
    }
    return pages;
  }
}
