import { Component, ComponentFactory, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { ComponentType } from '@app/component-type';
import { CardDirective } from '@app/shared/card/card.directive';
import { User } from '@app/shared/user';

export interface RemoveCardEvent {
  component: ComponentType;
  storageKey: string;
}

@Component({
  selector: 'om-card',
  templateUrl: './card.component.html',
  styleUrls: [],
})
export class CardComponent implements OnInit {
  @Input() card: ComponentFactory<any>;
  @Input() user: User;
  @Output() removeCard = new EventEmitter<RemoveCardEvent>();
  @ViewChild(CardDirective, { static: true }) cardDirective: CardDirective;

  constructor() {}

  ngOnInit() {
    const viewContainerRef = this.cardDirective.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(this.card);
    componentRef.instance.user = this.user;
    componentRef.instance.removeCard = this.removeCard;
  }
}
