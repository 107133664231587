import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'om-validation-x',
  templateUrl: './validation-x.component.html',
})
export class ValidationXComponent implements OnInit {
  @Input() color = '#D54859';
  @Input() size = 10;

  constructor() {}

  ngOnInit() {}
}
