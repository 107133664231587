export const fontFaces = currentLocation => `
@font-face {
  font-family: 'Sofia Pro';
  src: url('${currentLocation}/assets/fonts/sofiapro-ultralight.woff2') format('woff2'), url('${currentLocation}/assets/fonts/sofiapro-ultralight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Sofia Pro';
  src: url('${currentLocation}/assets/fonts/sofiapro-light.woff2') format('woff2'), url('${currentLocation}/assets/fonts/sofiapro-light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Sofia Pro';
  src: url('${currentLocation}/assets/fonts/sofiapro-light-italic.woff2') format('woff2'), url('${currentLocation}/assets/fonts/sofiapro-light-italic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Sofia Pro';
  src: url('${currentLocation}/assets/fonts/sofiapro-regular.woff2') format('woff2'), url('${currentLocation}/assets/fonts/sofiapro-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Sofia Pro';
  src: url('${currentLocation}/assets/fonts/sofiapro-regular-italic.woff2') format('woff2'), url('${currentLocation}/assets/fonts/sofiapro-regular-italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Sofia Pro';
  src: url('${currentLocation}/assets/fonts/sofiapro-semibold.woff2') format('woff2'), url('${currentLocation}/assets/fonts/sofiapro-semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Sofia Pro';
  src: url('${currentLocation}/assets/fonts/sofiapro-semibold-italic.woff2') format('woff2'), url('${currentLocation}/assets/fonts/sofiapro-semibold-italic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Sofia Pro';
  src: url('${currentLocation}/assets/fonts/sofiapro-bold.woff2') format('woff2'), url('${currentLocation}/assets/fonts/sofiapro-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Sofia Pro';
  src: url('${currentLocation}/assets/fonts/sofiapro-bold-italic.woff2') format('woff2'), url('${currentLocation}/assets/fonts/sofiapro-bold-italic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}
`;

export const cssOverrides = `
:root {
  --font-family: 'Sofia Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  --font-default-color: #1A1A1A;
  --default-border-color: #7A726A;
  --default-button-background-color: #068466;
  --error-color: #D54859;
}
.fsBody {
  font-family: var(--font-family) !important;
  color: var(--font-default-color) !important;
}
.fsSectionHeader {
  background: transparent !important;
  font-size: 22px !important;
  font-weight: 200 !important;
  line-height: 30px !important;
}
.fsSectionHeading {
  font-size: 40px !important;
  font-weight: 400 !important;
  line-height: 42px !important;
  color: var(--font-default-color) !important;
}
.fsLabel {
  font-size: 22px !important;
  color: var(--font-default-color) !important;
}
.fsOptionLabel {
  font-size: 20px !important;
  line-height: 42px !important;
  color: var(--font-default-color) !important;
}
.fsRowBody input[type=text], .fsForm textarea {
  border-color: var(--default-border-color) !important;
  color: var(--font-default-color) !important;
  margin-top: 7px;
  margin-bottom: 7px;
}
.fsRowBody input[type=checkbox], .fsRowBody input[type=radio],
.fsRowBody input[type=checkbox]:before, .fsRowBody input[type=radio]:before {
  height: 22px !important;
  width: 22px !important;
  border-color: var(--default-border-color) !important;
  top: 11px !important;
}
.fsRowBody input[type=radio]:checked:before {
  border-width: 7px !important;
  border-color: var(--default-border-color) !important;
}
.fsNextButton, .fsPreviousButton, .fsSubmit input.fsSubmitButton, input[type="submit"] {
  background: var(--default-button-background-color) !important;
  border: 1px solid var(--default-button-background-color) !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  font-size: 20px !important;
  line-height: 40px !important;
  min-width: 200px;
}
fieldset {
  padding: 10px !important;
}
.fsError {
  border: 1px solid var(--error-color) !important;
  color: var(--error-color) !important;
}
.fsValidationError {
  background-color: transparent !important;
  box-shadow: 0 0 0 1px transparent, 0 0 0 1px var(--error-color);
  -moz-box-shadow: 0 0 0 1px transparent, 0 0 0 1px var(--error-color);
  -webkit-box-shadow: 0 0 0 1px transparent, 0 0 0 1px var(--error-color);
}
.fsValidationError .fsLabel, .fsValidationError .fsRequiredLabel, .fsValidationError .fsRequiredMarker {
  color: var(--error-color) !important;
}

.fsSubmit input.fsSubmitButton, input[type="submit"] {
  width: 100% !important;
}
`;
