import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { fromEvent, race, Subject } from 'rxjs';
import { debounceTime, map, startWith, takeUntil, tap } from 'rxjs/operators';

import { DetectResizeService } from '../detect-resize/detect-resize.service';

const defaultScrollByPx = 400;
const defaultBottomPadding = 48;
@Component({
  selector: 'om-view-more',
  templateUrl: './view-more.component.html',
  styleUrls: ['./view-more.component.scss'],
})
export class ViewMoreComponent implements AfterViewInit, OnDestroy {
  @ViewChild('contentWrapper') contentWrapperElementRef: ElementRef;

  @Input() scrollByPx = defaultScrollByPx;
  @Input() bottomPadding = defaultBottomPadding;

  atTop = true;
  atBottom = false;
  viewMoreEnabled = false;

  private get contentWrapper() {
    return this.contentWrapperElementRef.nativeElement;
  }

  private get wrapperOverflows() {
    return this.contentWrapper.scrollHeight > this.contentWrapper.clientHeight + this.bottomPadding;
  }

  private destroy$ = new Subject();
  private disabled$ = new Subject();

  constructor(private detectResizeService: DetectResizeService, private ref: ChangeDetectorRef) {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit() {
    this.detectResizeService
      .getResizeEvents$(this.contentWrapper, 0)
      .pipe(startWith(null), takeUntil(this.destroy$))
      .subscribe(() => {
        this.wrapperOverflows ? this.enableViewMore() : this.disableViewMore();
        this.ref.detectChanges();
      });
  }

  private disableViewMore() {
    this.disabled$.next();
    this.viewMoreEnabled = false;
  }

  private enableViewMore() {
    this.viewMoreEnabled = true;
    this.adjustWithScrolling();
  }

  private adjustWithScrolling() {
    fromEvent(this.contentWrapper, 'scroll')
      .pipe(
        debounceTime(100),
        map(() => ({
          atTop: this.contentWrapper.scrollTop === 0,
          atBottom:
            this.contentWrapper.scrollTop >=
            this.contentWrapper.scrollHeight - this.contentWrapper.offsetHeight - this.bottomPadding,
        })),
        tap(({ atTop, atBottom }) => {
          this.atTop = atTop;
          this.atBottom = atBottom;
        }),
        takeUntil(race(this.destroy$, this.disabled$)),
      )
      .subscribe(() => {
        this.ref.detectChanges();
      });
  }

  viewMore() {
    this.contentWrapper.scroll(0, this.contentWrapper.scrollTop + this.scrollByPx);
  }
}
