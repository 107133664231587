<div class="container mt-xl-10 mt-2">
  <form class="col-12 col-lg-10 offset-lg-1 px-lg-5 px-0 d-flex flex-column" [formGroup]="form">
    <h1 class="text-center">Who is this membership for?</h1>
    <p class="text-center mb-5">Select the option that best describes who the membership is for.</p>
    <small class="text-danger mb-2 text-center" *ngIf="hasError && errorMessage" [innerHTML]="errorMessage"></small>
    <om-routing-group
      formControlName="membershipType"
      [options]="options"
      (change)="onSubmit($event)"
    ></om-routing-group>
  </form>

  <ng-template #typeNotAvailable>
    <om-modal-template
      heading="This membership type is not available."
      subheader="There are no additional memberships associated with your account. Contact your employer benefits team to resolve the issue."
      primaryCallToActionLabel="Close"
      (close)="modalService.dismissAll()"
      (primaryCallToAction)="modalService.dismissAll()"
    >
      <om-svg-exclamation></om-svg-exclamation>
    </om-modal-template>
  </ng-template>

  <ng-template #personalAlreadyRegistered>
    <om-modal-template
      heading="An account already exists for that membership type."
      subheader="There is an account already associated with the work email you provided. Log in or contact our membership advisors for help."
      primaryCallToActionLabel="Log In"
      primaryClasses="modal-footer pt-0 px-md-5 pb-4 border-0 justify-content-center"
      (close)="modalService.dismissAll()"
      (primaryCallToAction)="logIn()"
    >
      <om-svg-exclamation></om-svg-exclamation>
      <small custom class="d-block text-center pb-4">
        Lost access to your account? <a href="mailto:hello@onemedical.com">hello@onemedical.com</a>
      </small>
    </om-modal-template>
  </ng-template>
</div>
