import { AppointmentBookingStateCache } from './appointment-booking-session-graphql.service';

export class AppointmentBookingSession {
  id: number;
  appointmentBookingStateCache: AppointmentBookingStateCache;

  constructor() {}

  static fromGraphQL(response) {
    const abs = new AppointmentBookingSession();
    abs.id = response.id;
    abs.appointmentBookingStateCache = response.appointmentBookingStateCache;

    return abs;
  }
}
