import { Injectable } from '@angular/core';
import { Query, gql, Apollo } from 'apollo-angular';

import { WhitelistedEmployee, WhitelistedEmployeeVariables } from './__generated__/WhitelistedEmployee';

@Injectable()
export class WhitelistedEmployeeGraphQL extends Query<WhitelistedEmployee, WhitelistedEmployeeVariables> {
  constructor(apollo: Apollo) {
    super(apollo);
  }

  document = gql`
    query WhitelistedEmployee($workEmail: String, $b2bCompanyId: String, $employeeId: String, $reCaptchaToken: String) {
      whitelistedEmployee(
        workEmail: $workEmail
        b2bCompanyId: $b2bCompanyId
        employeeId: $employeeId
        reCaptchaToken: $reCaptchaToken
      ) {
        id
        employeeId
        registered
        workEmail
        dependents {
          id
          registered
        }

        b2bCompany {
          id
          hasEligibleDependents
          includesDependent
        }
      }
    }
  `;
}
