import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private _toastMessage$ = new Subject<string>();
  toastMessage$ = this._toastMessage$.asObservable();

  constructor() {}

  push(message: string) {
    this._toastMessage$.next(message);
  }
}
