<div class="row m-0 py-lg-3" attr.data-cy="{{ cypressHandle() }}">
  <div class="align-self-center px-0 h-5 w-5 mx-lg-3" [class.d-none]="!this.hasIcon() || selected">
    <ng-template #icon></ng-template>
  </div>

  <div class="align-self-center px-0 h-5 w-5 mx-lg-3" *ngIf="this.selected && this.hasIcon()">
    <om-circle-with-checkmark class="primary-fill h-100 w-100"> </om-circle-with-checkmark>
  </div>

  <div *ngIf="this.hasPngIcon()" class="align-self-center px-0 h-5 w-5 mx-lg-3">
    <img class="png-icon" [src]="option.pngIconUrl" />
  </div>

  <div class="col align-self-center pr-0">
    <div class="d-inline-block col-md-12">
      <p class="lead mb-0 mt-0" data-cy="routing-option-header">
        <strong>{{ option.header }}</strong>
      </p>
      <div class="subtitle" data-cy="routing-option-subheader">{{ option.subheader }}</div>
      <span class="text-muted" *ngIf="option.description" data-cy="routing-option-description">
        {{ option.description }}
      </span>
    </div>
  </div>
</div>
