import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import isNil from 'lodash-es/isNil';
import pickBy from 'lodash-es/pickBy';

import { State } from '@app/app.reducer';
import { AnalyticsService } from '@app/core/analytics.service';
import { ConfigService } from '@app/core/config.service';
import { MP_EVENT_PAGE_VIEWED, MP_EVENT_REG_INPUT_SUBMITTED } from '@app/core/mixpanel.constants';
import { MixpanelService } from '@app/core/mixpanel.service';

export interface RegInputErroredProperties {
  error: string;
  formField: string;
  isWhitelist?: boolean;
  module: string;
}

export interface RegInputSubmittedProperties {
  isWhitelist?: boolean;
  isLoggedIn?: boolean;
  isPedsShown?: boolean;
  companyId?: number;
  serviceArea?: string;
  companyCode?: string;
  module: string;
  moduleVariant?: string;
}

export interface BasicEventProperties {
  module: string;
  moduleVariant?: string;
  isWhitelist?: boolean;
}

declare const ga: any;
declare const fbq: any;

@Injectable()
export class EnterpriseRegistrationAnalyticsService extends AnalyticsService {
  static FLOW = 'Registration';
  static OMNOWFLOW = 'OM Now Navigation';
  static POST_REGISTRATION_FLOW = 'Post Registration';
  static DIRECT_SIGNUP_FLOW = 'Direct Sign Up';
  static MEMBERSHIP_TYPE = 'Enterprise';
  static VERSION = 'Enterprise Registration Q12020';

  constructor(config: ConfigService, store: Store<State>, mixpanel: MixpanelService) {
    super(mixpanel, store);

    if (config.json.gaAccount) {
      ga('create', config.json.gaAccount, 'auto');
      ga('send', 'pageview', location.pathname);
    }

    if (config.json.facebookAccount) {
      fbq('init', config.json.facebookAccount);
      fbq('track', 'PageView');

      fbq.push(['addPixelId', config.json.facebookAccount]);
      fbq.push(['track', 'PixelInitialized', {}]);
    }

    if (config.json.linkedinPartnerId) {
      (<any>window)._linkedin_data_partner_id = config.json.linkedinPartnerId;
      (function () {
        const s = document.getElementsByTagName('script')[0];
        const b = document.createElement('script');
        b.type = 'text/javascript';
        b.async = true;
        b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
        s.parentNode.insertBefore(b, s);
      })();
    }
  }

  regInputErrored(props: RegInputErroredProperties) {
    return this.trackWithDefaultProperties('Input Error Encountered', {
      error: props.error,
      flow: EnterpriseRegistrationAnalyticsService.FLOW,
      form_field: props.formField,
      is_whitelist: props.isWhitelist,
      module: props.module,
      om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
    });
  }

  regInputSubmitted({
    serviceArea: service_area,
    isWhitelist: is_whitelist,
    module,
    companyCode: company_code,
    moduleVariant: module_variant,
  }: RegInputSubmittedProperties) {
    const trackingParams = pickBy(
      {
        flow: EnterpriseRegistrationAnalyticsService.FLOW,
        flow_version: EnterpriseRegistrationAnalyticsService.VERSION,
        is_whitelist,
        module,
        om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
        service_area,
        company_code,
        module_variant,
      },
      v => !isNil(v),
    );

    return this.trackWithDefaultProperties(MP_EVENT_REG_INPUT_SUBMITTED, trackingParams);
  }

  sendCompanyCodeViewed() {
    return this.trackWithDefaultProperties('Send Company Code Viewed', {
      flow: EnterpriseRegistrationAnalyticsService.FLOW,
      is_whitelist: false,
      module: 'Company Code Page',
      om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
    });
  }

  pageViewed(props: BasicEventProperties) {
    return this.trackBasicEvent(MP_EVENT_PAGE_VIEWED, props);
  }

  loginClicked(props: BasicEventProperties) {
    return this.trackBasicEvent('Login Clicked', props);
  }

  redirectToHomeClicked(props: BasicEventProperties) {
    return this.trackBasicEvent('Continue Flow Clicked', props);
  }

  redirectToConsentFormClicked(props: BasicEventProperties) {
    return this.trackBasicEvent('Complete Consent Form Clicked', props);
  }

  membershipSelectionStepViewed(props: RegInputSubmittedProperties) {
    const { isWhitelist, isLoggedIn, isPedsShown, companyId, module } = props;

    return this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, {
      flow: EnterpriseRegistrationAnalyticsService.FLOW,
      is_whitelist: isWhitelist,
      is_logged_in: isLoggedIn,
      is_peds_shown: isPedsShown,
      company_id: companyId,
      module,
      om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
    });
  }

  myBenefitSelected(props: RegInputSubmittedProperties) {
    const { isWhitelist, module } = props;
    return this.trackWithDefaultProperties('My Benefit Selected', {
      flow: EnterpriseRegistrationAnalyticsService.FLOW,
      is_whitelist: isWhitelist,
      module,
      om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
    });
  }

  spouseBenefitSelected(props: RegInputSubmittedProperties) {
    const { isWhitelist, module } = props;
    return this.trackWithDefaultProperties('Spouse/Dependent Selected', {
      flow: EnterpriseRegistrationAnalyticsService.FLOW,
      is_whitelist: isWhitelist,
      module,
      om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
    });
  }

  childBenefitSelected(props: RegInputSubmittedProperties) {
    const { isWhitelist, isLoggedIn, companyId, module } = props;
    return this.trackWithDefaultProperties('Child Selected', {
      flow: EnterpriseRegistrationAnalyticsService.FLOW,
      is_whitelist: isWhitelist,
      is_logged_in: isLoggedIn,
      company_id: companyId,
      module,
      om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
    });
  }

  invalidAddressSelected(error: string, isWhitelist: boolean) {
    return this.trackWithDefaultProperties('Invalid Address Selected', {
      error,
      flow: 'Registration',
      form_field: 'Address',
      is_whitelist: isWhitelist,
      module: 'Account Setup Page',
      om_membership_type: 'Enterprise',
    });
  }

  continueAsAVirtualMemberClicked() {
    return this.trackWithDefaultProperties('Continue As Virtual Member Clicked', {
      flow: EnterpriseRegistrationAnalyticsService.OMNOWFLOW,
      module: 'Service Area Page',
      submodule: 'Virtual Care Modal',
    });
  }

  directSignupStartedPostRegistration() {
    return this.trackWithDefaultProperties('Direct Sign Up Started', {
      flow: EnterpriseRegistrationAnalyticsService.DIRECT_SIGNUP_FLOW,
      module: 'Enterprise Registration Confirmation Page',
      om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
      is_whitelist: this.isWhitelist,
      is_logged_in: true,
    });
  }

  directSignupTypeSelected(type: string) {
    return this.trackWithDefaultProperties('Direct Sign Up Type Selected', {
      flow: EnterpriseRegistrationAnalyticsService.DIRECT_SIGNUP_FLOW,
      module: 'Direct Sign Up Selection Page',
      direct_sign_up_type: type,
      is_logged_in: true,
      is_whitelist: this.isWhitelist,
      is_pediatrics: type === 'Child',
    });
  }

  directSignupSelectionPageViewed() {
    return this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, {
      flow: EnterpriseRegistrationAnalyticsService.DIRECT_SIGNUP_FLOW,
      module: 'Direct Sign Up Selection Page',
      is_whitelist: this.isWhitelist,
      is_pediatrics: true,
    });
  }

  adultDirectSignupPageViewed() {
    return this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, {
      flow: EnterpriseRegistrationAnalyticsService.DIRECT_SIGNUP_FLOW,
      module: 'Dependent Information Page',
      direct_sign_up_type: 'Spouse',
      is_logged_in: true,
      is_whitelist: this.isWhitelist,
    });
  }

  directSignupSubmitted() {
    return this.trackWithDefaultProperties('Direct Sign Up Submitted', {
      flow: EnterpriseRegistrationAnalyticsService.DIRECT_SIGNUP_FLOW,
      module: 'Dependent Information Page',
      direct_sign_up_type: 'Spouse',
      is_logged_in: true,
      is_whitelist: this.isWhitelist,
    });
  }

  directSignupCanceled() {
    return this.trackWithDefaultProperties('Direct Sign Up Canceled', {
      flow: EnterpriseRegistrationAnalyticsService.DIRECT_SIGNUP_FLOW,
      module: 'Dependent Information Page',
      direct_sign_up_type: 'Spouse',
      is_logged_in: true,
      is_whitelist: this.isWhitelist,
    });
  }

  trackAnotherDependentClicked() {
    return this.trackWithDefaultProperties('Register Another Dependent Clicked', {
      flow: EnterpriseRegistrationAnalyticsService.DIRECT_SIGNUP_FLOW,
      module: 'Direct Sign Up Confirmation Page',
      direct_sign_up_type: 'Spouse',
      is_logged_in: true,
      is_whitelist: this.isWhitelist,
    });
  }

  directSignupGenderDetailsExpanded() {
    return this.trackWithDefaultProperties('Show Gender Details Expanded', {
      flow: EnterpriseRegistrationAnalyticsService.DIRECT_SIGNUP_FLOW,
      module: 'Dependent Information Page',
      direct_sign_up_type: 'Spouse',
      is_logged_in: true,
      is_whitelist: this.isWhitelist,
    });
  }

  directSignupConfirmationPageViewed() {
    return this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, {
      flow: EnterpriseRegistrationAnalyticsService.DIRECT_SIGNUP_FLOW,
      module: 'Direct Sign Up Confirmation Page',
      direct_sign_up_type: 'Spouse',
      is_logged_in: true,
      is_whitelist: this.isWhitelist,
    });
  }

  trackGoogleEvent(action: string, label: string) {
    ga('send', 'event', 'EntSignupForm', action, label, null);
  }

  trackFacebookEvent(action: string) {
    fbq('track', action);
  }

  private trackBasicEvent(eventName: string, props: BasicEventProperties) {
    return this.trackWithDefaultProperties(
      eventName,
      pickBy(
        {
          flow: this.flowForModule(props.module),
          flow_version: EnterpriseRegistrationAnalyticsService.VERSION,
          is_whitelist: props.isWhitelist,
          module: props.module,
          module_variant: props.moduleVariant,
          om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
        },
        v => !isNil(v),
      ),
    );
  }

  private flowForModule(moduleName: string) {
    if (moduleName === 'Enterprise Registration Confirmation Page') {
      return EnterpriseRegistrationAnalyticsService.POST_REGISTRATION_FLOW;
    }

    return EnterpriseRegistrationAnalyticsService.FLOW;
  }
}
