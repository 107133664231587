export class PhoneNumberFormatter {
  get display(): string {
    return this.phoneNumber ? this.formatPhoneNumber() : null;
  }

  get tenDigitNumber() {
    return this.extractDigits() ? this.extractDigits().substr(0, 10) : null;
  }

  constructor(private phoneNumber: string) {}

  private formatPhoneNumber() {
    const extractedDigits = this.extractDigits();
    if (extractedDigits.length <= 0) {
      return '';
    } else if (extractedDigits.length <= 3) {
      return '(' + extractedDigits;
    } else if (extractedDigits.length <= 6) {
      return '(' + extractedDigits.substr(0, 3) + ') ' + extractedDigits.substr(3);
    } else {
      return (
        '(' + extractedDigits.substr(0, 3) + ') ' + extractedDigits.substr(3, 3) + '-' + extractedDigits.substr(6, 4)
      );
    }
  }

  private extractDigits() {
    if (this.phoneNumber) {
      return this.phoneNumber.replace(/[^0-9]/g, '');
    }
  }
}
