<div class="modal-header">
  <h5 class="modal-title">Select Self Pay</h5>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">
      <om-close-x></om-close-x>
    </span>
  </button>
</div>
<div class="modal-body">
  <div class="container-fluid"
    >Selecting self pay means your claims will not be sent to insurance. You will be fully responsible for the cost of
    all services received, and payment will be collected at the time of your visit. This option is intended only for
    uninsured patients.</div
  >
  <div class="row px-3 mt-4 d-flex justify-content-end">
    <button (click)="close()" class="btn btn-outline-primary btn-lg mr-2">Cancel</button>

    <button
      (click)="onConfirm()"
      class="btn btn-primary btn-lg"
      data-cy="self-pay-modal-confirm-button"
      [disabled]="isSaving"
    >
      Confirm
    </button>
  </div>
</div>
