import { Component, Input } from '@angular/core';

import { DocumentSigner } from '@app/core/tos.service';
import { User } from '@app/shared/user';

import { RegistrationStepDirective } from '../registration-step.directive';

@Component({
  selector: 'om-enterprise-terms-of-service',
  templateUrl: './terms-of-service-step.component.html',
})
export class TermsOfServiceStepComponent extends RegistrationStepDirective {
  @Input() user: User;
  @Input() signer: DocumentSigner;

  onSubmit() {
    this.submit.emit();
  }
}
