import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'moment',
  pure: false,
})
export class MomentPipe implements PipeTransform {
  constructor() {}

  transform(date: string | Date, format: string) {
    return moment(date).format(format);
  }
}
