import {
  Component,
  ComponentFactoryResolver,
  HostBinding,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

import { IRoutingGroupOption } from './routing-group-option.interface';

@Component({
  selector: 'om-routing-group-option',
  templateUrl: './routing-group-option.component.html',
  styleUrls: ['./routing-group-option.component.scss'],
})
export class RoutingGroupOptionComponent implements OnInit {
  @HostBinding() class = 'list-group-item list-group-item-action';
  @HostBinding('class.selected') @Input() selected = false;
  @Input() option: IRoutingGroupOption;
  @ViewChild('icon', { read: ViewContainerRef, static: true }) icon: ViewContainerRef;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  ngOnInit(): void {
    if (this.hasIcon()) {
      this.loadComponent();
    }
  }

  loadComponent() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.option.iconComponent);
    this.icon.createComponent(componentFactory);
  }

  hasIcon() {
    return !!this.option.iconComponent;
  }

  hasPngIcon() {
    return !!this.option.pngIconUrl;
  }

  cypressHandle() {
    return `${this.option.value}-group-option`;
  }
}
