import { Injectable } from '@angular/core';
import { combineLatest, ReplaySubject } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { UserService } from '@app/core/user.service';
import { Features } from '@app/shared/active-feature-flag-collection';
import { FeatureFlagService } from '@app/shared/feature-flag.service';

@Injectable({
  providedIn: 'root',
})
export class BookVisitCtaService {
  private _cta$ = new ReplaySubject<string>(1);
  readonly cta$ = this._cta$.asObservable();

  constructor(private featureFlagService: FeatureFlagService, private userService: UserService) {
    this.userService.getUser();

    combineLatest([
      this.featureFlagService.featureActive$(Features.GET_CARE_BOOK_VISIT_CTA).pipe(take(1)),
      this.userService.user$.pipe(map(user => user.isVirtual)),
    ]).subscribe({
      next: ([bookVisitCtaActive, userIsVirtual]) => {
        if (userIsVirtual || !bookVisitCtaActive) {
          this._cta$.next('Get Care');
        } else {
          this._cta$.next('Book Visit');
        }
      },
    });
  }
}
