<label
  class="btn btn-outline-primary"
  [class.active]="isSelected(option.value)"
  [class.focus]="isFocused(option.value)"
  *ngFor="let option of options"
  [attr.data-cy]="option.value"
>
  <input autofocus type="radio" [value]="option.value" [formControl]="formControl" (focus)="focus()" (blur)="blur()" />
  {{ option.label }}
</label>
