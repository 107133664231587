<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="24" height="24" fill="none"></rect>
  <path
    d="M13.4401 6.65607L5.86279 14.2334L9.74123 18.1118L17.3186 10.5345L13.4401 6.65607Z"
    fill="currentColor"
  ></path>
  <path
    d="M4.07831 18.5063C3.77359 19.5728 4.38303 20.2076 5.47495 19.9029L8.95386 18.9126L5.06865 15.002L4.07831 18.5063Z"
    fill="currentColor"
  ></path>
  <path
    d="M19.5428 6.1648L17.8161 4.43804C17.232 3.85399 16.2671 3.85399 15.6576 4.43804L14.2102 5.88547L18.0954 9.77067L19.5428 8.32324C20.1523 7.7138 20.1523 6.74885 19.5428 6.1648Z"
    fill="currentColor"
  ></path>
</svg>
