import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { LinksService } from '@app/core/links.service';
import { MembershipAnalyticsService } from '@app/membership/membership-analytics.service';

import { Membership } from '../../core/membership';
import { MembershipService } from '../../core/membership.service';
import { MembershipExpirationHelper } from '../membership-expiration-helper';

@Component({
  selector: 'om-expired-membership-banner',
  templateUrl: './expired-membership-banner.component.html',
  styleUrls: ['./expired-membership-banner.component.scss'],
})
export class ExpiredMembershipBannerComponent implements OnInit, OnDestroy {
  path: string;
  membership: Membership;
  closed = false;
  formattedExpiryString: string;
  membershipExpiresSoon: boolean;
  expirationDate: string;
  trackExpiredMembershipBannerClicked = this.analyticsService.expiredMembershipBannerClicked.bind(
    this.analyticsService,
  );

  trackExpiringB2bMembershipBannerClicked = this.analyticsService.expiringB2bMembershipBannerClicked.bind(
    this.analyticsService,
  );

  trackReactivateMembershipBannerClicked = this.analyticsService.reactivateMembershipBannerClicked.bind(
    this.analyticsService,
  );

  trackInvalidCCMembershipBannerClicked = this.analyticsService.invalidCCMembershipBannerClicked.bind(
    this.analyticsService,
  );

  private closeSubscriptions = new Subject();

  constructor(
    private membershipService: MembershipService,
    private links: LinksService,
    private analyticsService: MembershipAnalyticsService,
    private router: Router,
  ) {
    router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.path = router.url;
    });
  }

  ngOnInit() {
    this.membershipService.membership$.pipe(takeUntil(this.closeSubscriptions)).subscribe((membership: Membership) => {
      this.membership = membership;
      const membershipExpirationHelper = new MembershipExpirationHelper(this.membership);
      this.formattedExpiryString = membershipExpirationHelper.formattedExpiry();
      this.membershipExpiresSoon = membershipExpirationHelper.expiresSoon();
      this.expirationDate = membershipExpirationHelper.expirationDateString();
    });

    this.membershipService.getMembership();
  }

  canDisplayExpiredBanner() {
    return (
      this.path !== '/membership/renew/consumer' &&
      this.path !== '/membership/renew/enterprise' &&
      this.path !== '/membership/renew/omnow' &&
      this.path !== '/membership/settings'
    );
  }

  canDisplayAutoRenewBanner() {
    return this.path !== '/membership/settings';
  }

  ngOnDestroy(): void {
    this.closeSubscriptions.next();
    this.closeSubscriptions.complete();
  }
}
