import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Observable, of as observableOf, throwError as observableThrowError, zip } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { AuthService } from '@app/core/auth.service';
import { UserService } from '@app/core/user.service';
import { StateService } from '@app/shared';
import { Address } from '@app/shared/address';
import { SendAppLinkGraphql } from '@app/shared/download-app-card/send-app-link-graphql.service';
import { User } from '@app/shared/user';

import { ValidateEmailService } from '../../validate-email.service';
import { ValidatePasswordService } from '../../validate-password.service';
import { EnterpriseRegistration } from '../enterprise-registration';
import { EnterpriseRegistrationAnalyticsService } from '../enterprise-registration-analytics.service';
import { IRegistrationStep, RegistrationStep } from '../registration-step';
import { CreateAccountStepComponent } from './create-account-step.component';

@Injectable()
export class CreateAccountConfig extends RegistrationStep implements IRegistrationStep {
  GA_LABEL = 'Create_Your_Account_Step';

  MODULE = 'Account Creation Page';
  captchaNeeded = true;
  component = CreateAccountStepComponent;
  componentInstance: CreateAccountStepComponent;
  progress = 75;
  address: Address;

  form = this.formBuilder.group({
    logInEmail: ['', Validators.required],
    password: ['', Validators.required],
  });

  constructor(
    private enterpriseRegistrationAnalyticsService: EnterpriseRegistrationAnalyticsService,
    private emailService: ValidateEmailService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private passwordService: ValidatePasswordService,
    private sendAppLinkGraphQL: SendAppLinkGraphql,
    private authService: AuthService,
    private stateService: StateService,
  ) {
    super();
  }

  canGoBack() {
    return true;
  }

  initComponent(component: CreateAccountStepComponent) {
    component.form = this.form;
    this.componentInstance = component;
    this.trackPageView();
  }

  onDestroy() {
    this.form.controls.logInEmail.clearValidators();
    this.form.controls.password.clearValidators();
  }

  submit(state: EnterpriseRegistration, captcha: any): Observable<any> {
    if (this.form.invalid) {
      return observableThrowError(new Error());
    }
    const { logInEmail, password } = this.form.value;
    state.patient.email = logInEmail;
    state.patient.password = password;
    return this.createNewUser(captcha, logInEmail, state, password);
  }

  private createNewUser(captcha: any, logInEmail: any, state: EnterpriseRegistration, password: any) {
    const uniqueness = captcha.getToken().pipe(
      switchMap((reCaptchaToken: string) =>
        this.emailService.validateUniqueness(logInEmail, reCaptchaToken).pipe(
          map(() => true),
          catchError((err: string) => {
            this.componentInstance.emailError = err;
            this.trackUniquenessError(state);
            return observableOf(false);
          }),
        ),
      ),
    );
    const complexity = this.passwordService.validateComplexity(logInEmail, password).pipe(
      map(() => true),
      catchError((err: string) => {
        this.componentInstance.passwordError = err;
        return observableOf(false);
      }),
    );

    return zip(uniqueness, complexity).pipe(
      switchMap(([unique, complex]) => {
        if (unique && complex) {
          this.trackSubmission(state);
          return captcha.getToken().pipe(
            switchMap((reCaptchaToken: string) => this.userService.createUser(state.patient, reCaptchaToken)),
            catchError(err => {
              this.componentInstance.passwordError = err;
              return observableThrowError(err);
            }),
          );
        } else {
          throw new Error();
        }
      }),
      tap((user: User) => {
        this.finishSubmit(state, user);
      }),
    );
  }

  private finishSubmit(state: EnterpriseRegistration, user: User) {
    const { phoneNumber, textAppToPhone } = state.form.value.accountSetUp;
    if (textAppToPhone) {
      this.sendAppLinkGraphQL
        .mutate({
          input: {
            phoneNumber: phoneNumber,
          },
        })
        .subscribe();
    }
    this.enterpriseRegistrationAnalyticsService.trackFacebookEvent('CompleteRegistration');
    state.setCurrentStep('termsOfService');
  }

  private trackSubmission(state: EnterpriseRegistration) {
    this.enterpriseRegistrationAnalyticsService.regInputSubmitted({
      isWhitelist: state.isWhitelisted,
      module: this.MODULE,
    });
  }

  private trackPageView() {
    this.enterpriseRegistrationAnalyticsService.trackGoogleEvent(this.GA_ACTION, this.GA_LABEL);
  }

  private trackUniquenessError(state: EnterpriseRegistration) {
    this.enterpriseRegistrationAnalyticsService.regInputErrored({
      error: 'Email Already Exists',
      formField: 'Login Email',
      isWhitelist: state.isWhitelisted,
      module: this.MODULE,
    });
  }
}
