import { Injectable } from '@angular/core';

import { AnalyticsService } from '@app/core/analytics.service';
import { Membership } from '@app/core/membership';
import { MP_EVENT_PAGE_VIEWED } from '@app/core/mixpanel.constants';

@Injectable()
export class MembershipAnalyticsService extends AnalyticsService {
  private settingsFlowAndModule = {
    flow: 'Account Update',
    module: 'MyOne Membership Settings Page',
  };

  renewScreenViewed() {
    return this.trackWithDefaultProperties('Renew Screen Viewed');
  }

  renewScreenPayAmf() {
    return this.trackWithDefaultProperties('Renew Screen Pay AMF Tapped');
  }

  renewScreenActivateSponsoredBenefit() {
    return this.trackWithDefaultProperties('Activate Sponsored Benefit Link Tapped');
  }

  settingsUpdateActivation() {
    return this.trackWithDefaultProperties('Update Activation Code Clicked');
  }

  settingsCancelMembership() {
    return this.trackWithDefaultProperties('Cancel Membership Clicked');
  }

  settingsCancelAutoRenewal() {
    return this.trackWithDefaultProperties('Cancel Auto Renewal Clicked');
  }

  settingsReactivateMembership() {
    return this.trackWithDefaultProperties('Reactivate Membership Clicked');
  }

  settingsConvertToSponsored() {
    return this.trackWithDefaultProperties(
      'Activate Employer Sponsored Membership Clicked',
      this.settingsFlowAndModule,
    );
  }

  settingsConvertTrialMembership() {
    return this.trackWithDefaultProperties('Trial to Consumer Conversion Started', this.settingsFlowAndModule);
  }

  settingsConvertToPersonal() {
    return this.trackWithDefaultProperties('Convert to Personal Membership Clicked', this.settingsFlowAndModule);
  }

  settingsMembershipReactivated() {
    return this.trackWithDefaultProperties('Membership Reactivated');
  }

  settingsPageViewed() {
    return this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, this.settingsFlowAndModule);
  }

  billingInfoUpdateStarted() {
    return this.trackWithDefaultProperties('Update Billing Info', this.settingsFlowAndModule);
  }

  billingInfoUpdated() {
    return this.trackWithDefaultProperties('Billing Info Updated', this.settingsFlowAndModule);
  }

  expiredMembershipBannerClicked() {
    return this.trackWithDefaultProperties('Update Expired Membership Banner Clicked', {
      flow: 'Renew Membership',
    });
  }

  reactivateMembershipBannerClicked() {
    return this.trackWithDefaultProperties('Update Reactivate Membership Banner Clicked', {
      flow: 'Reactivate Membership',
    });
  }

  expiringB2bMembershipBannerClicked() {
    return this.trackWithDefaultProperties('Update Expiring Membership Banner Clicked', {
      flow: 'Account Update',
    });
  }

  invalidCCMembershipBannerClicked() {
    return this.trackWithDefaultProperties('Invalid Credit Card Banner Clicked', {
      flow: 'Account Update',
    });
  }

  giftCardRedeemed() {
    return this.track(MP_EVENT_PAGE_VIEWED, {
      ...this.defaultProperties,
      flow: 'Post Gift Registration',
      module: 'MyOne Membership Settings Page',
      submodule: 'Gift Membership Extension Modal',
    });
  }

  activateEmployerSponsoredMembership() {
    return this.trackWithDefaultProperties('Activate Employer Sponsored Membership Clicked', {
      ...this.defaultProperties,
      flow: 'Renew Membership',
      module: 'Renew Page',
      submodule: null,
    });
  }

  convertToPersonalMembership() {
    return this.trackWithDefaultProperties('Convert to Personal Membership Clicked', {
      ...this.defaultProperties,
      flow: 'Renew Membership',
      module: 'Renew Page',
      submodule: null,
    });
  }

  requestMedicalRecords() {
    return this.trackWithDefaultProperties('Request Medical Records Release Clicked', {
      ...this.defaultProperties,
      flow: 'Renew Membership',
      module: 'Renew Page',
      submodule: null,
    });
  }

  renewConsumerMemberBillingInfo() {
    return this.trackWithDefaultProperties('Billing Info Updated', {
      ...this.defaultProperties,
      flow: 'Renew Membership',
      module: 'Renew Page',
      submodule: null,
    });
  }
}
