import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

interface Address {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
}

@Component({
  selector: 'om-address-display',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <address>
      {{ address.address1 }} <br />
      <ng-container *ngIf="address.address2?.length > 0"> {{ address.address2 }} <br /> </ng-container>
      {{ address.city }}, {{ address.state }} {{ address.zip }}
    </address>
  `,
})
export class AddressDisplayComponent {
  @Input() address: Address;
}
