<svg
  width="100%"
  height="100%"
  viewBox="0 0 27 27"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <g id="Components" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="*MultipleChoice" class="top" transform="translate(-94.000000, -204.000000)" stroke="white">
      <g id="Group-5" transform="translate(21.000000, 99.000000)">
        <g id="Group-4" transform="translate(0.000000, 47.000000)">
          <g id="With-Icons" transform="translate(74.000000, 56.000000)">
            <g id="Cell-with-Icon">
              <g id="Group" transform="translate(0.000000, 3.000000)">
                <circle id="Oval-23" stroke="currentColor" fill="currentColor" cx="12.5" cy="12.5" r="12.5"></circle>
                <polyline id="Check" points="7 13.29145 10.333 16.90245 18.583 9.07445"></polyline>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
