import { Injectable } from '@angular/core';
import lodashMap from 'lodash-es/map';
import { BehaviorSubject, Observable, of as observableOf, zip } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

import { Provider } from '../shared/provider';
import { User } from '../shared/user';
import { ApiService } from './api.service';
import { UserService } from './user.service';

@Injectable()
export class ProviderService {
  private _bookableProviders$ = new BehaviorSubject<Array<Provider>>([]);
  bookableProviders$ = this._bookableProviders$.asObservable();

  patientProviderTeam$: Observable<Provider[]> = this.userService.user$.pipe(
    first(),
    map(this.getProviderTeamsIdsByUser),
    switchMap(providerIds => this.getProvidersByIds(providerIds)),
  );

  constructor(private userService: UserService, private apiService: ApiService) {}

  getProviderById(providerId) {
    return this.apiService
      .get(`/api/v2/patient/providers/${providerId}.json`)
      .pipe(map(provider => Provider.fromApiV2(provider)));
  }

  getBookableProvidersForServiceArea(serviceAreaId: number) {
    this.apiService
      .get(`/api/v2/patient/providers/patient_bookable`, false, { service_area_id: serviceAreaId })
      .pipe(map((rawProviders: any) => lodashMap(rawProviders, rawProvider => Provider.fromApiV2(rawProvider))))
      .subscribe(providers => this._bookableProviders$.next(providers));
  }

  getBookableProvidersForReason(reason: string, serviceAreaId: number) {
    const apiParams = {
      reason: reason,
      service_area_id: serviceAreaId,
    };

    this.apiService.post('/api/v2/patient/quickumls/get_providers_by_reason', apiParams, false).subscribe(response => {
      const providers = response['providers'];
      if (providers.length > 0) {
        this._bookableProviders$.next(providers.map(provider => Provider.fromApiV2(provider)));
      }
    });
  }

  private getProvidersByIds(providerIds: number[]): Observable<Provider[]> {
    if (providerIds.length < 1) {
      return observableOf([]);
    }
    const providersObsArray = providerIds.map(pid => this.getProviderById(pid));
    return zip(...providersObsArray);
  }

  private getProviderTeamsIdsByUser(user: User) {
    if (user.provider == null || user.provider.id == null) {
      return [];
    }
    return [user.provider.id].concat(
      user.provider.colleagues == null ? [] : user.provider.colleagues.map(provider => provider.id),
    );
  }
}
