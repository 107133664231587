import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, of as observableOf, Subject, throwError as observableThrowError } from 'rxjs';

import { Address } from '@app/shared/address';

import { EnterpriseRegistration } from '../enterprise-registration';
import { EnterpriseRegistrationAnalyticsService } from '../enterprise-registration-analytics.service';
import { IRegistrationStep, RegistrationStep } from '../registration-step';
import { AccountSetUpStepComponent } from './account-set-up-step.component';

@Injectable()
export class AccountSetUpConfig extends RegistrationStep implements IRegistrationStep {
  GA_LABEL = 'Account_Setup_Step';

  MODULE = 'Account Setup Page';
  component = AccountSetUpStepComponent;
  progress = 37.5;
  showLoginLink = true;

  form: FormGroup = this.formBuilder.group({
    address: this.formBuilder.group(Address.buildBasicAddressControlsConfig()),
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    phoneNumber: '',
    preferredName: '',
    textAppToPhone: true,
  });

  private destroy$ = new Subject();

  constructor(
    private enterpriseRegistrationAnalyticsService: EnterpriseRegistrationAnalyticsService,
    private formBuilder: FormBuilder,
  ) {
    super();
  }

  initComponent(component: AccountSetUpStepComponent) {
    component.form = this.form;
    this.trackPageView();
  }

  onDestroy() {
    this.destroy$.next();
    this.form.controls.phoneNumber.clearValidators();
  }

  patchParams(params: { [k: string]: any }) {
    const { firstName, lastName, address, phoneNumber, preferredName } = params;
    if (firstName) {
      this.form.patchValue({ firstName });
    }
    if (lastName) {
      this.form.patchValue({ lastName });
    }
    if (address) {
      this.form.patchValue({ address });
    }
    if (phoneNumber) {
      this.form.patchValue({ phoneNumber });
    }
    if (preferredName) {
      this.form.patchValue({ preferredName });
    }
  }

  submit(state: EnterpriseRegistration): Observable<any> {
    if (this.form.controls.address.invalid) {
      this.enterpriseRegistrationAnalyticsService.invalidAddressSelected('', state.isWhitelisted);
    }

    if (this.form.valid) {
      state.patient.address = this.form.controls.address.value;
      state.patient.firstName = this.form.controls.firstName.value;
      state.patient.lastName = this.form.controls.lastName.value;
      state.patient.preferredName = this.form.controls.preferredName.value;
      state.patient.phoneNumber = this.form.controls.phoneNumber.value;
      state.setCurrentStep('dobSex');
      this.trackSubmission(state);
      return observableOf(true);
    }
    return observableThrowError(new Error());
  }

  private trackPageView() {
    this.enterpriseRegistrationAnalyticsService.trackGoogleEvent(this.GA_ACTION, this.GA_LABEL);
  }

  private trackSubmission(state: EnterpriseRegistration) {
    this.enterpriseRegistrationAnalyticsService.regInputSubmitted({
      module: this.MODULE,
      isWhitelist: state.isWhitelisted,
    });
  }
}
