import { Component, HostBinding, Input } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface ToggleOption {
  label: string;
  value: any;
}

@Component({
  selector: 'om-toggle',
  templateUrl: './toggle.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ToggleComponent,
      multi: true,
    },
  ],
})
export class ToggleComponent implements ControlValueAccessor {
  @Input() classes = '';
  @HostBinding('class') get hostClasses() {
    return this.classes.concat(' btn-group btn-group-toggle');
  }

  @Input() options: ToggleOption[];
  @Input() focusOnInit = false;
  formControl = new FormControl('');
  focused = this.focusOnInit;
  onTouched = () => {};

  isSelected(value) {
    return this.formControl.value === value;
  }

  isFocused(value) {
    return this.formControl.value === value && this.focused;
  }

  focus() {
    this.focused = true;
  }

  blur() {
    this.focused = false;
  }

  writeValue(value: any) {
    this.formControl.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: (value: any) => void) {
    this.formControl.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    if (disabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }

  markAsTouchedAndDirty() {
    this.formControl.markAsTouched();
    this.formControl.markAsDirty();
  }

  get valid() {
    return this.formControl.value !== '';
  }
}
