import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'om-arrow-next',
  templateUrl: './arrow-next.component.html',
})
export class ArrowNextComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
