<div>
  <button
    class="btn btn-block btn-primary {{ this.buttonClass }}"
    [disabled]="disabled"
    type="submit"
    aria-label="submit"
    data-cy="submit"
    [hidden]="submitting"
  >
    {{ this.label }}
  </button>
  <om-spinner class="d-block text-center {{ this.buttonClass }}" [hidden]="!submitting" size="extra-small"></om-spinner>
</div>
