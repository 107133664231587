<div class="payment-modal">
  <div class="modal-header border-0">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-content px-5 pb-5 border-0">
    <ng-container *ngIf="this.showSuccess; then successView; else formView"></ng-container>

    <ng-template #formView>
      <h2 class="text-center mb-3 modal-title">{{ modalTitle }}</h2>
      <div *ngIf="!inFreeTrial" class="modal-subheader mb-4">
        Please enter your payment info to change from employer sponsored to a personal membership.
      </div>

      <form class="form-inline" [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
        <div class="stripe-input-container">
          <om-label-wrapper class="" label="Payment info">
            <om-stripe-credit-card
              formControlName="creditCard"
              class="my-2 d-block credit-card-input"
            ></om-stripe-credit-card>
          </om-label-wrapper>
          <div *ngIf="formErrors.creditCard">
            <span class="error-message"> {{ formErrors.creditCard }} </span>
          </div>
        </div>

        <div *ngIf="!isShowingDiscount && coupon" class="discount-code-success mt-3" [@expand]="'expanded'">
          Discount code applied.<br />
          {{ coupon.id }}: {{ coupon.description }}
        </div>
        <a href="#" class="my-2" (click)="showDiscountField($event)" *ngIf="!isShowingDiscount">{{
          discountCodeButtonText
        }}</a>

        <div *ngIf="isShowingDiscount" class="my-3 discount-code-section">
          <div class="form-inline">
            <om-label-wrapper class="discount-code-form" inputWrapperClass="mr-2" label="Enter code">
              <input
                formControlName="couponCode"
                type="text"
                class="form-control form-control-md mt-2"
                maxlength="25"
              />
            </om-label-wrapper>
            <button
              type="button"
              class="btn btn-outline-primary apply-button"
              (click)="validateDiscount()"
              [disabled]="couponCode.length === 0 || isApplyingDiscount"
            >
              {{ isApplyingDiscount ? 'Applying' : 'Apply' }}
            </button>
          </div>
          <div class="form-error mt-2" *ngIf="formErrors['discountCode']" [@expand]="'expanded'">
            {{ formErrors['discountCode'] }}
          </div>
        </div>

        <div *ngIf="formErrors.submitError" class="mt-4 clearfix">
          <span class="error-message"> {{ formErrors.submitError }} </span>
        </div>
        <div class="col-12 col-lg-12 col-xl-12 p-0"
          ><om-submit-button
            [disabled]="form.invalid || !validStripePaymentInfo || submitting"
            class="col-4"
            buttonClass="btn-lg"
            label="Submit"
            [submitting]="submitting"
          ></om-submit-button
        ></div>
        <div class="payment-terms px-4 py-4">
          <div *ngIf="membershipCost" class="details mb-2">
            <div class="cost">
              <span *ngIf="fullMembershipCost != membershipCost" class="full">
                $<span>{{ fullMembershipCost }}</span>
              </span>
              $<span>{{ membershipCost % 1 ? membershipCost.toFixed(2) : membershipCost }}</span>
            </div>
            <div class="fee-description">Annual Membership Fee</div>
            <div class="valid-until">Valid Until {{ validUntil }}</div>
          </div>
          <p class="fine-print my-0">
            When you click “Submit” you will be charged for the first year{{ chargeDateText }}. The full
            <a href="https://www.onemedical.com/membership/">annual membership fee</a> renews yearly as a recurring
            charge to your credit card on file unless you cancel, which you can do on the membership settings page. We
            will notify you prior to any fee change.
          </p>
        </div>
      </form>
    </ng-template>

    <ng-template #successView>
      <ng-container *ngIf="inFreeTrial; then trialSuccess; else paymentSuccess"></ng-container>

      <ng-template #paymentSuccess>
        <div class="signup-success">
          <h2 class="my-2">Congrats!</h2>
          <div class="modal-subheader my-4"
            >You now have a personal membership, {{ firstName }}! Your membership is valid through
            {{ validUntil }}.</div
          >
        </div>
        <img class="healthcare-for-all" src="/assets/images/healthcare-for-all.png" />
        <div *ngIf="isRenew" class="col-12 col-lg-12 col-xl-12 p-0"
          ><om-submit-button
            buttonClass="btn-lg col-4 offset-4"
            label="Go Home"
            (click)="navigateHome()"
          ></om-submit-button
        ></div>
      </ng-template>

      <ng-template #trialSuccess>
        <div class="signup-success">
          <om-svg aria-hidden="true" image="logo_confetti"></om-svg>
          <h2 class="my-2">All set, {{ firstName }}!</h2>
          <div class="modal-subheader my-4"
            >Congrats, your annual membership will start{{ chargeDateText }}, right when your free trial ends.</div
          >
          <div class="col-12 col-lg-12 col-xl-12 p-0"
            ><om-submit-button
              class="col-4"
              buttonClass="btn-lg"
              label="Close"
              (click)="closeModal()"
            ></om-submit-button
          ></div>
        </div>
      </ng-template>
    </ng-template>
  </div>
</div>
