import { ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'omgui-checkbox',
  templateUrl: './omgui-checkbox.component.html',
  styleUrls: ['./omgui-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OmguiCheckboxComponent),
      multi: true,
    },
  ],
})
export class OmguiCheckboxComponent implements ControlValueAccessor {
  /**
   * @ignore
   */
  private static globalUniqueId = 0;

  /**
   * @ignore
   */
  private uniqueId = `omgui-checkbox-${++OmguiCheckboxComponent.globalUniqueId}`;

  /**
   * @ignore
   */
  private _inputId = this.uniqueId;
  /** A unique id for the input. Will get translated to an id attribute that can be targeted with a label. */
  @Input() set inputId(id: string) {
    this._inputId = id || this.uniqueId;
  }

  get inputId() {
    return this._inputId;
  }

  /** The input name. */
  @Input() name: string;

  /** The input value. */
  @Input() value: string;

  /** An optional label to display after the checkbox. */
  @Input() label: string;

  /** Whether or not the input should be disabled. */
  @Input() disabled = false;

  /**
   * @ignore
   */
  _checked = false;

  /** The current boolean value of whether the input is checked */
  get checked(): boolean {
    return this._checked;
  }

  /**
   * @ignore
   */
  private _controlValueAccessorChangeFn: (value: any) => void = () => {};
  /**
   * @ignore
   */
  private _controlValueAccessorTouchFn: () => void = () => {};

  constructor(private _changeDetectorRef: ChangeDetectorRef) {}

  /**
   * @ignore
   */
  registerOnChange(fn: any) {
    this._controlValueAccessorChangeFn = fn;
  }

  /**
   * @ignore
   */
  registerOnTouched(fn: any) {
    this._controlValueAccessorTouchFn = fn;
  }

  /**
   * @ignore
   */
  writeValue(value: boolean) {
    this._checked = value;
    this._changeDetectorRef.detectChanges();
  }

  /**
   * @ignore
   */
  handleChange(value) {
    this._controlValueAccessorChangeFn(value);
  }

  /**
   * @ignore
   */
  handleTouched() {
    this._controlValueAccessorTouchFn();
  }

  /**
   * @ignore
   */
  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
