<div class="col-12 row p-0 m-0">
  <div class="col-12 col-xl-6">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <h2>Verify your email.</h2>
      <div class="mb-4">
        <p class="lh-175"> Enter the code we’ve sent to {{ preferredEmail }} to verify your One Medical account. </p>
      </div>
      <div class="mb-4">
        <om-label-wrapper class="" label="" [hasError]="hasError()" [errorMessage]="errorMessage">
          <input
            type="text"
            class="form-control text-field mb-3 col-9"
            placeholder="Code"
            formControlName="verificationCode"
            required="true"
            autofocus
            (keypress)="filterNonNumeric($event)"
            (paste)="filterPaste($event)"
            maxlength="6"
          />
        </om-label-wrapper>
      </div>
      <input type="hidden" formControlName="skip" />
      <div class="d-flex mb-4">
        <om-submit-button class="col-4 p-0" label="Continue" [submitting]="submitting"></om-submit-button>
        <button type="button" class="btn btn-link" aria-label="Skip for now" (click)="onSkip()"> Skip for now </button>
      </div>
      <div class="col-12 p-0 lh-175">
        Didn't get the email?
        <a href="#" aria-label="Resend email" (click)="onResendEmail($event)"> Resend email </a>
        <br />
        Need help? Contact
        <a href="mailto:techsupport@onemedical.com" aria-label="Contact technical support"
          >techsupport@onemedical.com</a
        >
      </div>
    </form>
  </div>
  <div class="col-xl-6 d-none d-xl-flex justify-content-center">
    <img class="col-10" src="/assets/images/email-verification.svg" />
  </div>
</div>
