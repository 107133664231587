import { Component, forwardRef, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'om-sex-gender-input',
  templateUrl: './sex-gender-input.component.html',
  styleUrls: ['../form-input.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SexGenderInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SexGenderInputComponent),
      multi: true,
    },
  ],
})
export class SexGenderInputComponent implements OnInit, ControlValueAccessor, Validator {
  showAdditionalInfo = false;
  sexAndGenderForm: FormGroup;

  constructor(public formBuilder: FormBuilder) {}

  ngOnInit() {
    this.sexAndGenderForm = this.formBuilder.group({
      sex: [null, Validators.required],
      genderDetails: [null],
    });
  }

  showInfo($event) {
    this.showAdditionalInfo = true;
    $event.preventDefault();
  }

  writeValue(val: any): void {
    if (val) {
      this.sexAndGenderForm.setValue(val, { emitEvent: false });
    }
  }

  registerOnChange(fn: any): void {
    this.sexAndGenderForm.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {}

  validate(control: AbstractControl): ValidationErrors | null {
    return this.sexAndGenderForm.valid
      ? null
      : { invalidForm: { valid: false, message: 'Sex and Gender fields are invalid' } };
  }
}
