import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NavigatorService {
  get enabled(): boolean {
    return !!navigator.geolocation;
  }

  getCurrentPosition(
    successCallback: PositionCallback,
    errorCallback?: PositionErrorCallback,
    options?: PositionOptions,
  ): void {
    navigator.geolocation.getCurrentPosition(successCallback, errorCallback, options);
  }
}
