import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FlashService } from '@app/shared/flash.service';

import { NativeAppService } from '../native-app.service';
import { ToastService } from '../toast.service';
import { ToastComponent } from '../toast/toast.component';

@Component({
  selector: 'om-logged-in-wrapper',
  templateUrl: './logged-in-wrapper.component.html',
  styleUrls: ['./logged-in-wrapper.component.scss'],
})
export class LoggedInWrapperComponent implements OnInit, OnDestroy {
  notice?: string;
  closed = false;
  shouldHideNavigation = false;
  onHomePage = false;
  private destroy$ = new Subject<void>();
  @ViewChild(ToastComponent) toastComponent: ToastComponent;

  constructor(
    private nativeAppService: NativeAppService,
    private route: ActivatedRoute,
    private router: Router,
    private flashService: FlashService,
    private toastService: ToastService,
  ) {}

  ngOnInit() {
    this.initToast();

    this.notice = this.route.snapshot.queryParams.notice;
    this.router.navigate([], {
      queryParams: {
        notice: null,
      },
      queryParamsHandling: 'merge',
    });
    if (this.router.url === '/') {
      this.onHomePage = true;
    }
    this.shouldHideNavigation = this.nativeAppService.shouldHideNavigation(this.route);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initToast() {
    this.toastService.toastMessage$.pipe(takeUntil(this.destroy$)).subscribe({
      next: message => this.toastComponent.show(message),
    });
  }
}
