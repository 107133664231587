import {
  AfterViewInit,
  Component,
  ContentChild,
  ContentChildren,
  Directive,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TemplateRef,
  Optional,
} from '@angular/core';

import { cardContentAnimation, enterAnimation } from '../page-state/animations';
import { PageState } from '../page-state/page-state';

@Directive({
  selector: '[om-card-list-empty-state]',
})
export class CardListEmptyStateDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}
@Directive({
  selector: '[om-card-list-row]',
})
export class CardListRowDirective {
  @Input() link: string;

  constructor(@Optional() public templateRef: TemplateRef<any>) {}
}

@Directive({
  selector: '[om-card-list-header]',
})
export class CardListHeaderDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({
  selector: '[om-card-list-header-link]',
})
export class CardListHeaderLinkDirective {
  @Output() actionClicked = new EventEmitter<void>();

  constructor(public templateRef: TemplateRef<any>) {}

  handleClick(event: MouseEvent) {
    event.preventDefault();
    this.actionClicked.emit();
  }
}

@Directive({
  selector: 'om-card-list-section',
})
export class CardListSectionDirective {
  @ContentChild(CardListHeaderDirective) header: CardListHeaderDirective;
  @ContentChildren(CardListRowDirective) rows: QueryList<CardListRowDirective>;
  constructor() {}
}

@Component({
  selector: 'om-card-list',
  templateUrl: './card-list.component.html',
  styleUrls: ['../../shared/card.scss', './card-list.component.scss'],
  animations: [enterAnimation, cardContentAnimation],
})
export class CardListComponent {
  @ContentChild(CardListHeaderDirective) header: CardListHeaderDirective;
  @ContentChild(CardListHeaderLinkDirective) headerLink: CardListHeaderLinkDirective;
  @ContentChild(CardListEmptyStateDirective) emptyState: CardListEmptyStateDirective;
  @ContentChildren(CardListRowDirective) rows: QueryList<CardListRowDirective>;
  @ContentChildren(CardListSectionDirective) sections: QueryList<CardListSectionDirective>;
  @Input() pageState: PageState = PageState.SUCCESS;
  @Input() useLoadingSkeleton = false;

  readonly successState = PageState.SUCCESS;

  get bottomBorderHidden() {
    return !this.rows?.last?.link;
  }

  isEmptyState() {
    return this.rows.length === 0 && this.sections.toArray().every(section => section.rows.length === 0);
  }
}
