import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AnalyticsService } from '@app/core/analytics.service';
import { FeatureFlagSelectors } from '@app/core/feature-flags/feature-flag.selectors';
import { FeatureFlags } from '@app/core/feature-flags/feature-flags';

@Component({
  selector: 'om-login',
  providers: [],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
// Component controller
export class Login implements OnInit, OnDestroy {
  private familyPromotionEnabled$ = this.featureFlagSelectors.getFeatureFlag(
    FeatureFlags.CONSUMER_PEDIATRIC_REGISTRATION_FAMILY_PROMOTIONAL_PRICING,
    false,
  );

  private destroy$ = new Subject();

  signInText: string;

  constructor(
    private title: Title,
    private activatedRoute: ActivatedRoute,
    private analyticsService: AnalyticsService,
    private featureFlagSelectors: FeatureFlagSelectors,
  ) {}

  ngOnInit() {
    this.title.setTitle('Login');
    this.setSignInText();
    this.activatedRoute.queryParams.subscribe(params => {
      this.analyticsService.trackLoginPageView(params);
    });
  }

  setSignInText() {
    const defaultSignInText = 'Please log in to your One Medical account';
    const familyPromotionSignInText =
      'Sign in to create an account for your kid at a special price of $75 for their first year (normally $199).';

    this.signInText = defaultSignInText;

    this.familyPromotionEnabled$.pipe(takeUntil(this.destroy$)).subscribe(familyPromoEnabled => {
      const isFamilyPromo = this.activatedRoute.snapshot.queryParams.familyPromo;
      this.signInText = isFamilyPromo && familyPromoEnabled ? familyPromotionSignInText : defaultSignInText;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
