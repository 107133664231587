import each from 'lodash-es/each';

export class AppointmentCancellationReason {
  constructor() {}

  static readonly fieldMapping = {
    id: 'id',
    displayReason: 'display_reason',
  };

  id: number;
  displayReason: string;

  static fromApiV2(response): AppointmentCancellationReason {
    const reason = new AppointmentCancellationReason();
    each(AppointmentCancellationReason.fieldMapping, (value, key) => {
      reason[key] = response[value];
    });

    return reason;
  }

  toApiV2() {
    const response = {};
    each(AppointmentCancellationReason.fieldMapping, (value, key) => {
      response[value] = this[key];
    });

    return response;
  }
}
