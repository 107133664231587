<svg width="16" height="13" xmlns="http://www.w3.org/2000/svg">
  <!-- Generator: Sketch 49.3 (51167) - http://www.bohemiancoding.com/sketch -->
  <title>Icons / Next</title>
  <desc>Created with Sketch.</desc>

  <g>
    <title>background</title>
    <rect fill="none" id="canvas_background" height="390" width="582" y="-1" x="-1" />
  </g>
  <g>
    <title>Layer 1</title>
    <g fill-rule="evenodd" fill="none" id="Icons-/-Back">
      <path
        id="svg_1"
        fill-rule="nonzero"
        fill="#CFCFCF"
        d="m3.18747,5.38816l11.42492,0c0.50293,0 0.91064,0.36377 0.91064,0.8125c0,0.44873 -0.40771,0.8125 -0.91064,0.8125l-12.02361,0l4.46212,4.46211c0.3488,0.34881 0.3488,0.91433 0,1.26313c-0.3488,0.3488 -0.91432,0.3488 -1.26313,0l-5.52617,-5.52617c-0.19494,-0.19494 -0.28093,-0.45757 -0.25797,-0.71223c-0.01662,-0.18436 0.02386,-0.37289 0.12144,-0.5369c0.07048,-0.20601 0.23025,-0.37726 0.43907,-0.47786l5.22363,-5.22364c0.34881,-0.3488 0.91433,-0.3488 1.26313,0c0.3488,0.3488 0.3488,0.91432 0,1.26313l-3.86343,3.86343z"
      />
    </g>
  </g>
</svg>
