import { Component, Input } from '@angular/core';

@Component({
  selector: 'om-svg-gift-box-icon',
  templateUrl: './svg-gift-box-icon.component.html',
})
export class SvgGiftBoxIcon {
  @Input() fill = '#A8C47C';

  constructor() {}
}
