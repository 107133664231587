import { Injectable } from '@angular/core';

import { AnalyticsService } from '@app/core/analytics.service';

@Injectable()
export class FooterAnalyticsService extends AnalyticsService {
  readonly submodule = 'Footer';
  flow = '';
  module = '';

  setFlowAndModule(homePage: boolean) {
    this.flow = homePage ? 'Home Page Navigation' : '';
    this.module = homePage ? 'MyOne Home Page' : '';
  }

  trackFooterLink(linkClicked: string) {
    return this.trackWithDefaultProperties('Footer Link Clicked', {
      flow: this.flow,
      module: this.module,
      submodule: this.submodule,
      link_clicked: linkClicked,
    });
  }
}
