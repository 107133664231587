import { ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'omgui-clearable-text-input',
  templateUrl: 'omgui-clearable-text-input.component.html',
  styleUrls: ['omgui-clearable-text-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OmguiClearableTextInputComponent),
      multi: true,
    },
  ],
})
export class OmguiClearableTextInputComponent implements ControlValueAccessor {
  /** A clue to show in the text input when there is no text. */
  @Input() placeholder = '';

  /** A unique id for the input. Will get translated to an id attribute that can be targeted with a label. */
  @Input() inputId: string;

  /** @ignore */
  _inputText: string;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  /** @ignore */
  private onChange: (value: any) => void = () => {};

  /** @ignore */
  private onTouch: () => void = () => {};

  /** @ignore */
  registerOnChange(fn: (value: any) => void) {
    this.onChange = fn;
  }

  /** @ignore */
  registerOnTouched(fn: () => void) {
    this.onTouch = fn;
  }

  /** @ignore */
  writeValue(value: string): void {
    this._inputText = value;
    this.changeDetectorRef.detectChanges();
  }

  /** @ignore */
  handleChange(value) {
    this.onChange(value);
  }

  /** @ignore */
  handleTouched() {
    this.onTouch();
  }

  /** @ignore */
  clearInput() {
    this._inputText = null;
    this.handleChange(null);
  }
}
