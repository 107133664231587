import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  DependentAlreadyRegisteredError,
  NoWhitelistedDependentFoundError,
} from '@app/registration/enterprise/whitelisted-employee-errors';
import { DateInputComponent } from '@app/shared/date-input/date-input.component';
import { ToggleComponent, ToggleOption } from '@app/shared/toggle/toggle.component';

import { RegistrationStepDirective } from '../registration-step.directive';

@Component({
  selector: 'om-dob-sex-step',
  styleUrls: ['../../../shared/form-input.scss'],
  templateUrl: './dob-sex-step.component.html',
})
export class DobSexStepComponent extends RegistrationStepDirective {
  @ViewChild('dateOfBirth', { static: true }) dateOfBirth: DateInputComponent;
  @ViewChild('sex', { static: true }) sex: ToggleComponent;
  @ViewChild('genderDetails') genderDetails: HTMLInputElement;
  @ViewChild('dependentNotFound', { static: true }) dependentNotFound;
  @ViewChild('dependentRegistered', { static: true }) dependentRegistered;

  minimumAge: number;
  showGenderDetails = false;
  sexOptions: ToggleOption[] = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
  ];

  constructor(public modalService: NgbModal, private router: Router) {
    super();
  }

  onSubmit() {
    this.submitting = true;
    this.dateOfBirth.markAsTouchedAndDirty();
    this.form.controls.sex.markAsTouched();
    this.form.controls.sex.markAsDirty();
    this.submit.emit(this.form.value);
  }

  showSexError() {
    const control = this.form.controls.sex;
    return control.dirty && control.touched && control.invalid;
  }

  onError(err: Error) {
    this.submitting = false;
    if ((err as DependentAlreadyRegisteredError).dependentAlreadyRegistered) {
      this.modalService.open(this.dependentRegistered);
    } else if ((err as NoWhitelistedDependentFoundError).noWhitelistedDependentFound) {
      this.modalService.open(this.dependentNotFound);
    }
  }

  logIn() {
    this.modalService.dismissAll();
    this.router.navigateByUrl('/login');
  }

  get showMinorError(): boolean {
    const { errors } = this.form.controls.dob;
    return !!errors && !!errors.age && !errors.age.valid;
  }
}
