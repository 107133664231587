import { Component, OnInit } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';

import { AttemptedPathService } from '@app/core/attempted-path.service';
import { DocumentSignerGraphQL, DocumentSignerResponse } from '@app/core/document-signer-graphql.service';
import { DocumentSigner } from '@app/core/tos.service';

@Component({
  selector: 'om-tos',
  templateUrl: './tos-interstitial.component.html',
})
export class TosInterstitialComponent implements OnInit {
  documentSigner: DocumentSigner;

  constructor(
    private attemptedPathService: AttemptedPathService,
    private documentSignerGraphql: DocumentSignerGraphQL,
  ) {}

  ngOnInit() {
    this.documentSignerGraphql.watch().valueChanges.subscribe((result: ApolloQueryResult<DocumentSignerResponse>) => {
      this.documentSigner = result.data.patient.documentSigner;
    });
  }

  continue() {
    this.attemptedPathService.navigateToAttemptedPath();
  }
}
