import each from 'lodash-es/each';

export class Hearabout {
  id: number;
  name: string;

  constructor(attrs) {
    this.setAttributes(attrs);
  }

  private setAttributes(attrs): void {
    each(['id', 'name'], attrName => {
      if (attrs[attrName]) {
        this[attrName] = attrs[attrName];
      }
    });
  }
}
