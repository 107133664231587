<button type="button" class="close font-weight-light" aria-label="Close" (click)="closeClick()">
  <om-close-x aria-hidden="true"></om-close-x>
</button>
<ngb-carousel
  *ngIf="carouselModalConfig.carouselCards"
  [interval]="0"
  [showNavigationArrows]="false"
  [wrap]="false"
  (slide)="onSlide($event)"
  #carousel
>
  <ng-template
    *ngFor="let carouselCard of carouselModalConfig.carouselCards; index as i"
    [id]="'carousel-' + i"
    ngbSlide
  >
    <div class="img-wrapper"> <img [src]="carouselCard.image" alt="{{ carouselCard.header }}" /> </div>
    <div class="carousel-caption">
      <h3 [innerHtml]="carouselCard.header"></h3> <p>{{ carouselCard.caption }}</p>
    </div>
  </ng-template>
</ngb-carousel>
<a
  *ngIf="!isFirstSlide()"
  class="carousel-control carousel-control-prev ng-star-inserted"
  role="button"
  (click)="backButtonClick()"
>
  <span>Back</span> <span class="sr-only">Back</span>
</a>
<a
  *ngIf="!isLastSlide()"
  class="carousel-control carousel-control-next ng-star-inserted"
  role="button"
  (click)="nextButtonClick()"
>
  <span>Next</span> <span class="sr-only">Next</span>
</a>
<a
  *ngIf="isLastSlide() && carouselModalConfig.ctaRoute"
  class="carousel-control carousel-control-next cta btn btn-primary"
  role="button"
  (click)="getCtaClick()"
>
  <span>{{ carouselModalConfig.ctaText }}</span> <span class="sr-only">{{ carouselModalConfig.ctaText }}</span>
</a>
