<svg
  width="15px"
  height="15px"
  viewBox="0 0 15 15"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <title>close</title>
  <g id="V1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="V1-Release---Typed-Growing-LIst" transform="translate(-1015.000000, -638.000000)">
      <g id="Form-/-Field-/-Input-With-Next-/-Inactive" transform="translate(410.000000, 527.000000)">
        <g id="Form-/-Field-/-Input-/-Inactive" transform="translate(0.000000, 108.000000)">
          <g id="close" transform="translate(605.000000, 3.000000)">
            <circle id="Oval-17" fill="currentColor" cx="7.5" cy="7.5" r="7.5"></circle>
            <g id="Group-2" transform="translate(5.400000, 5.400000)" stroke="#FFFFFF">
              <path d="M4.2,0 L0,4.2" id="Stroke-1"></path>
              <path d="M4.2,4.2 L0,0" id="Stroke-3"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
