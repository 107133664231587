import { ValidationFn } from 'ngrx-forms';
import { pattern, required } from 'ngrx-forms/validation';

import {
  MP_EVENT_GIFT_REG_SUCCESSFULLY_SUBMITTED,
  MP_EVENT_REG_SUCCESSFULLY_SUBMITTED,
} from '@app/core/mixpanel.constants';
import { noSequentialCharacters } from '@app/shared/ngrx-forms-custom-validators/no-sequential-characters';

export enum RegisterSteps {
  BasicInfo = 0,
  Location = 1,
  PersonalInfo = 2,
  TermsOfService = 3,
  Payment = 4,
  Confirmation = 5,
  Restart = 6,
}

export const REGISTER_MP_FLOW_VERSION = 'cons_reg_2020';

export const REGISTER_GA_PAGE_VIEW_EVENT = 'page view';

export enum RegisterFlows {
  Paid = 'Paid',
  FreeTrial = 'FreeTrial',
  Gifting = 'Gifting',
  Referral = 'Referral',
  Family = 'Family',
}

export enum RegisterDiscountTypes {
  FreeTrial = 'FreeTrial',
  Regular = 'Regular',
  Referral = 'Referral',
  Enterprise = 'Enterprise',
  Gift = 'Gift',
}

export const RegisterAnalyticsConfig = {
  Paid: {
    BasicInfo_mp_module: 'Account Creation Page',
    Location_mp_module: 'Address Page',
    PersonalInfo_mp_module: 'Phone DOB Sex Page',
    TermsOfService_mp_module: 'Legal Document Page',
    Payment_mp_module: 'Billing Page',
    Confirmation_mp_module: 'Registration Confirmation Page',
    ga_category: 'ConSignupForm',
    registration_success_mp_event: MP_EVENT_REG_SUCCESSFULLY_SUBMITTED,
    flow: 'Registration',
    post_reg_mp_flow: 'Post Registration',
  },
  FreeTrial: {
    BasicInfo_mp_module: 'Free Trial Account Creation Page',
    Location_mp_module: 'Free Trial Address Page',
    PersonalInfo_mp_module: 'Free Trial Phone DOB Sex Page',
    TermsOfService_mp_module: 'Free Trial Legal Document Page',
    Payment_mp_module: 'Free Trial Billing Page',
    Confirmation_mp_module: 'Free Trial Registration Confirmation Page',
    ga_category: 'FreeTrialSignupForm',
    registration_success_mp_event: `Free Trial ${MP_EVENT_REG_SUCCESSFULLY_SUBMITTED}`,
    flow: 'Registration',
    post_reg_mp_flow: 'Post Registration',
  },
  Gifting: {
    BasicInfo_mp_module: 'Gift Account Creation Page',
    Location_mp_module: 'Address Page',
    PersonalInfo_mp_module: 'Phone DOB Sex Page',
    TermsOfService_mp_module: 'Legal Document Page',
    Payment_mp_module: 'Billing Page',
    Confirmation_mp_module: 'Registration Confirmation Page',
    ga_category: 'GiftingSignupForm',
    registration_success_mp_event: MP_EVENT_GIFT_REG_SUCCESSFULLY_SUBMITTED,
    flow: 'Gift Registration',
    post_reg_mp_flow: 'Gift Registration',
  },
  Referral: {
    BasicInfo_mp_module: 'Account Creation Page',
    Location_mp_module: 'Phone DOB Sex Page',
    PersonalInfo_mp_module: 'Personal Info Page',
    TermsOfService_mp_module: 'Legal Document Page',
    Payment_mp_module: 'Billing Page',
    Confirmation_mp_module: 'Registration Confirmation Page',
    ga_category: 'ReferralSignupForm',
    registration_success_mp_event: MP_EVENT_REG_SUCCESSFULLY_SUBMITTED,
    flow: 'Registration',
    post_reg_mp_flow: 'Post Registration',
  },
  Family: {
    BasicInfo_mp_module: 'Account Creation Page',
    Location_mp_module: 'Phone DOB Sex Page',
    PersonalInfo_mp_module: 'Personal Info Page',
    TermsOfService_mp_module: 'Legal Document Page',
    Payment_mp_module: 'Billing Page',
    Confirmation_mp_module: 'Registration Confirmation Page',
    ga_category: 'ConSignupForm',
    registration_success_mp_event: MP_EVENT_REG_SUCCESSFULLY_SUBMITTED,
    flow: 'Family Registration',
    post_reg_mp_flow: 'Post Registration',
  },
};

/*
Password validation regex breakdown for posterity - to make it easy for anyone to add more requirements:
- begin with ^ and end with $ to match the whole string entered
- three groups in parens starting with ? - lookahead groups validating specific requirements (more on that below)
- .* - match any character
- Lookahead groups:
  ... are groups in format "VALID/INVALID when SUBSTRING contains PATTERN".
  Positive lookahead is specified with ?=, then comes string to match (in our case it's full string - specified with .*), then comes pattern.
  Negative lookahead is specified with ?!, then comes string to match, then comes pattern.
  So in this regex -
  - Must have 1 lowercase character: (?=.*[a-z])
    - ?= means positive lookahead, .* means in full string, [a-z] is the pattern
  - Must have 1 uppercase character: (?=.*[A-Z])
    - ?= means positive lookahead, .* means in full string, [A-Z] is the pattern
  - No characters repeated more than 2 times: (?!.*(.)\1{2})
    - ?! means negative lookahead (match of the pattern is found in substring will invalidate full regex), .* means in full string, (.)\1{2} means 3 repeating characters

   Great resource for learning to groke regular expressions - https://regex101.com/
 */
export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?!.*(.)\1{2}).*$/;

export const passwordValidators: ValidationFn<string>[] = [required, pattern(passwordRegex), noSequentialCharacters(3)];
