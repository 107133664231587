<div class="text-center mt-4"><om-logo class="logo mx-auto"></om-logo></div>

<om-flash></om-flash>
<om-terms-of-service
  [beneficiaryLoggedIn]="!!documentSigner"
  [signOnBehalf]="!!documentSigner"
  [signer]="documentSigner"
  (nextAction)="continue()"
>
</om-terms-of-service>
