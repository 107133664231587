<form [formGroup]="nameForm">
  <div class="row d-flex flex-sm-row flex-column justify-content-between flex-wrap">
    <div class="col-md-6 order-md-0">
      <label for="first-name" data-cy="firstNameLabel">{{ firstNameLabel }}</label>
      <input
        type="text"
        class="form-control"
        formControlName="firstName"
        name="first-name"
        id="first-name"
        placeholder="Enter first name"
        [attr.disabled]="isDisabled ? '' : null"
        [attr.aria-disabled]="isDisabled"
        [attr.autofocus]="enableAutoFocus ? true : null"
      />
      <div class="form-group mt-2" *ngIf="showingPreferredName && hasPreferredOption">
        <label for="preferred-name" data-cy="preferredNameLabel">Preferred Name</label>
        <input
          #preferredNameInput
          id="preferred-name"
          class="form-control"
          formControlName="preferredName"
          placeholder="Enter preferred name"
          name="preferred name"
          aria-label="Preferred first name"
          [attr.disabled]="isDisabled ? '' : null"
          [attr.aria-disabled]="isDisabled"
        />
      </div>
    </div>
    <div class="col my-2 order-md-2" *ngIf="hasPreferredOption && !showingPreferredName">
      <a href="" (click)="showPreferred($event)" class="show-preferred" data-cy="preferredNamePrompt">
        + Add a Preferred Name (optional)
      </a>
    </div>
    <div [class.mt-3]="!hasPreferredOption" [class.mt-0]="hasPreferredOption" class="col-md-6 order-md-1 mt-md-0">
      <label for="last-name" data-cy="lastNameLabel">{{ lastNameLabel }}</label>
      <input
        type="text"
        class="form-control"
        formControlName="lastName"
        name="last-name"
        id="last-name"
        placeholder="Enter last name"
        [attr.disabled]="isDisabled ? '' : null"
        [attr.aria-disabled]="isDisabled"
      />
    </div>
  </div>
</form>
