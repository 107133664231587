import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbCarousel, NgbSlideEvent, NgbSlideEventDirection } from '@ng-bootstrap/ng-bootstrap';

export interface CarouselCard {
  image: string;
  header: string;
  caption: string;
  name?: string;
}

export interface CarouselModalConfig {
  carouselCards: CarouselCard[];
  ctaRoute?: string;
  ctaText?: string;
}

@Component({
  selector: 'om-carousel-modal',
  templateUrl: './carousel-modal.component.html',
  styleUrls: ['./carousel-modal.component.scss'],
})
export class CarouselModalComponent implements OnInit {
  @Input() carouselModalConfig: CarouselModalConfig;
  @ViewChild('carousel') carousel: NgbCarousel;
  @Output() slide: EventEmitter<NgbSlideEvent> = new EventEmitter();
  @Output() ctaEvent: EventEmitter<any> = new EventEmitter();
  firstSlide = 'carousel-0';
  lastSlide = '';

  constructor(private activeModal: NgbActiveModal, private router: Router) {}

  ngOnInit() {
    this.lastSlide = `carousel-${this.carouselModalConfig.carouselCards.length - 1}`;
    this.slide.emit({ prev: '', current: this.firstSlide, direction: NgbSlideEventDirection.LEFT, paused: false });
  }

  onSlide(event: NgbSlideEvent) {
    this.slide.emit(event);
  }

  backButtonClick() {
    this.carousel.prev();
  }

  nextButtonClick() {
    this.carousel.next();
  }

  closeClick() {
    this.activeModal.dismiss();
  }

  getCtaClick() {
    this.ctaEvent.emit();
    this.router.navigate([this.carouselModalConfig.ctaRoute]).then(() => this.activeModal.close());
  }

  isFirstSlide() {
    if (!this.carousel) {
      return true;
    }
    return this.carousel.activeId === this.firstSlide;
  }

  isLastSlide() {
    if (!this.carousel) {
      return false;
    }
    return this.carousel.activeId === this.lastSlide;
  }
}
