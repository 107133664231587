import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import camelCase from 'lodash-es/camelCase';
import find from 'lodash-es/find';
import { combineLatest } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { Membership } from '@app/core/membership';
import { MembershipService } from '@app/core/membership.service';
import { DocumentSigner } from '@app/core/tos.service';
import { WindowService } from '@app/core/window.service';
import { SendAppLinkGraphql } from '@app/shared/download-app-card/send-app-link-graphql.service';
import { ServiceArea } from '@app/shared/service-area';
import { ServiceAreaService } from '@app/shared/service-area.service';

import { AuthService } from '../core/auth.service';
import { ConfigService } from '../core/config.service';
import { UserService } from '../core/user.service';
import { User } from '../shared/user';
import { EnterpriseRegistration } from './enterprise/enterprise-registration';
import { RegInputEnteredProperties, RegistrationAnalyticsService } from './registration-analytics.service';
import { RegistrationService } from './registration.service';

@Injectable({
  providedIn: 'root',
})
export class RegistrationStateService {
  patient: User;
  planId: number;
  currentQuestionIndex = 0;
  additionalStepsPostAccountCreation = 2;
  questions: Array<string>;
  allowBackButton = false;
  accountCreationQuestionIndex: number;
  regComplete = false;
  hasRegCompleteError = false;
  registerOnBehalf = false;
  membershipType: string;
  pendingEnterpriseRegistrationUUID: string;
  documentSigner: DocumentSigner;
  nonmemberRegistration = false;
  serviceAreas: ServiceArea[];
  textAppToPhone = false;
  isFamilyPromoAvailable = false;

  standardQuestions = [
    'first-name',
    'last-name',
    'email',
    'password',
    'service-area',
    'terms-of-service',
    'date-of-birth',
    'phone-number',
    'gender',
    'hearabout',
    'address',
  ];

  loggedOutPediatricQuestions = ['pediatric-service-area'];

  generalPediatricQuestions = [
    'child-intake',
    'guardian-confirmation',
    'child-account-details',
    'on-behalf-terms-of-service',
  ];

  consumerPediatricQuestions = [
    'child-intake',
    'guardian-confirmation',
    'child-account-details',
    'on-behalf-terms-of-service',
    'collect-payment-method',
  ];

  constructor(
    private authService: AuthService,
    private config: ConfigService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private registrationAnalyticsService: RegistrationAnalyticsService,
    private registrationService: RegistrationService,
    private userService: UserService,
    private windowService: WindowService,
    private membershipService: MembershipService,
    private enterpriseRegistration: EnterpriseRegistration,
    private serviceAreaService: ServiceAreaService,
    private sendAppLinkGraphQL: SendAppLinkGraphql,
  ) {
    this.questions = this.standardQuestions;
    this.accountCreationQuestionIndex = this.questions.indexOf('service-area');

    // this.patient = this.enterpriseRegistration.patient;
    this.patient = new User();

    this.serviceAreaService.getServiceAreas(this.enterpriseRegistration.b2bCompanyId).subscribe(serviceAreas => {
      this.serviceAreas = serviceAreas;
      if (serviceAreas.length === 1) {
        this.enterpriseRegistration.setServiceArea(serviceAreas[0]);
        this.currentQuestionIndex = 1;
      }
    });

    this.activatedRoute.queryParamMap
      .pipe(
        map(params => params.get('enterprise_reg_id')),
        filter(enterprise_reg_id => enterprise_reg_id !== null),
      )
      .subscribe(enterprise_reg_id => {
        this.initializeEnterpriseAdult(enterprise_reg_id);
      });
  }

  setMembershipType(membershipType: string) {
    this.membershipType = membershipType;
  }

  get isConsumerMembership(): boolean {
    return this.membershipType.toUpperCase() === 'CONSUMER';
  }

  setUUID(pendingEnterpriseRegistrationUUID: string) {
    this.pendingEnterpriseRegistrationUUID = pendingEnterpriseRegistrationUUID;
  }

  regInputEntered(props: Partial<RegInputEnteredProperties>) {
    const propsWithMemType = {
      ...props,
      membershipType: this.membershipType,
    } as RegInputEnteredProperties;
    this.registrationAnalyticsService.regInputEntered(propsWithMemType);
  }

  nextQuestion() {
    this.currentQuestionIndex++;
    this.updateAllowBackButton();

    if (this.currentQuestionIndex === this.questions.length) {
      this.markRegComplete();
    }
  }

  previousQuestion() {
    if (this.allowBackButton && (this.currentQuestionIndex === 0 || this.canSkipServiceAreaQuestion())) {
      window.history.back();
    } else {
      const currentQuestion = this.questions[this.currentQuestionIndex];
      this.registrationAnalyticsService.previousQuestionClicked(currentQuestion);

      this.currentQuestionIndex--;
      this.updateAllowBackButton();
    }
  }

  setAllowBackButton(value: boolean) {
    this.allowBackButton = value;
  }

  /*
    Prevents users from going back if on first question or any question after account creation
                      ▼ account creation step [4]
    | 0 | 1 | 2 | 3 | 4 | 5 | 6 |
    | n | y | y | y | y | n | n |
  */
  private updateAllowBackButton() {
    const onFirstQuestion = this.currentQuestionIndex === 0 || this.canSkipServiceAreaQuestion();
    const onQuestionAfterAcctCreation = this.currentQuestionIndex > this.accountCreationQuestionIndex;

    this.allowBackButton = !onFirstQuestion && !onQuestionAfterAcctCreation && !this.regComplete;
  }

  get currentQuestion() {
    return this.questions[this.currentQuestionIndex];
  }

  get progress() {
    return (
      (100 * (this.currentQuestionIndex + 1)) /
      (this.accountCreationQuestionIndex + this.additionalStepsPostAccountCreation)
    );
  }

  get isLoggedIn() {
    return this.authService.isLoggedIn();
  }

  setCurrentQuestion() {
    const personalInfoQuestions = this.questions.slice(this.accountCreationQuestionIndex + 2);
    const unansweredQuestion = find(personalInfoQuestions, question => {
      const formattedQuestion = camelCase(question);
      if (formattedQuestion === 'dateOfBirth') {
        return this.patient.dob == null;
      } else if (formattedQuestion === 'gender') {
        return this.patient.rawGender == null;
      } else if (formattedQuestion === 'address') {
        return this.patient.address.id == null;
      } else if (formattedQuestion === 'hearabout') {
        return this.patient.hearaboutId == null;
      } else {
        return this.patient[formattedQuestion] == null;
      }
    });

    if (unansweredQuestion) {
      this.currentQuestionIndex = this.questions.indexOf(unansweredQuestion);
    } else {
      this.markRegComplete();
    }

    this.updateAllowBackButton();
  }

  initializeRegisteringOnBehalf(enterprise: boolean) {
    this.registerOnBehalf = true;
    this.regComplete = false;

    this.setupRegistrationType(enterprise);

    if (this.authService.isLoggedIn()) {
      this.setLoggedInRegistrationStateAttributes();
    } else {
      this.setLoggedOutRegistrationStateAttributes();
    }

    this.accountCreationQuestionIndex = this.questions.indexOf('child-account-details');
  }

  createPediatricUser() {
    return this.authService.isLoggedIn()
      ? this.userService.createPediatricUserForPatient(this.patient, this.planId)
      : this.createPediatricUserForNonmember();
  }

  /* Completely resets the reg state. Clears entered data, progress, etc.  */
  resetState() {
    this.currentQuestionIndex = this.serviceAreas.length === 1 ? 1 : 0;
  }

  private createPediatricUserForNonmember() {
    if (this.pendingEnterpriseRegistrationUUID) {
      return this.userService.createPediatricUserForLegacyEnterpriseNonmember(
        this.patient,
        this.planId,
        this.pendingEnterpriseRegistrationUUID,
      );
    } else if (this.enterpriseRegistration.b2bCompanyId) {
      return this.userService.createPediatricUserForEnterpriseNonmember(
        this.patient,
        this.patient.enterpriseRegistrationDetails,
      );
    } else {
      return this.userService.createPediatricUserForConsumerNonmember(this.patient);
    }
  }

  private setPatient(patient: User) {
    if (!!this.patient.pendingEnterpriseRegId && !patient.pendingEnterpriseRegId) {
      patient.pendingEnterpriseRegId = this.patient.pendingEnterpriseRegId;
    }

    this.patient = patient;
  }

  private initializeEnterpriseAdult(enterprise_reg_id) {
    this.patient.pendingEnterpriseRegId = enterprise_reg_id;

    if (this.authService.isLoggedIn()) {
      this.userService.user$.subscribe(user => {
        this.setPatient(user);
        this.setCurrentQuestion();
      });
    }
  }

  private markRegComplete() {
    this.registrationService.markRegComplete(this.patient.pendingEnterpriseRegId).subscribe(
      response => {
        this.regComplete = true;

        if (this.registerOnBehalf) {
          this.redirectToChildRegComplete();
          if (this.textAppToPhone) {
            this.textAppLinkToPhone();
          }
        } else {
          this.redirectToConfirmation();
        }
      },
      error => {
        this.hasRegCompleteError = true;
      },
    );
  }

  private textAppLinkToPhone() {
    this.sendAppLinkGraphQL.mutate({ input: { phoneNumber: this.patient.phoneNumber } }).subscribe();
  }

  private redirectToChildRegComplete() {
    this.router.navigate(['/registration/child-registration-complete']);
  }

  private redirectToConfirmation() {
    this.windowService.redirect(`${this.config.json.myoneServer}/pt/registration/enterprise_confirmation`);
  }

  private setupRegistrationType(enterprise: boolean) {
    if (enterprise) {
      const startedInEnterpriseReg = !!this.enterpriseRegistration.b2bCompanyId;
      this.patient = startedInEnterpriseReg ? this.enterpriseRegistration.patient : new User();
      this.setupEnterprisePediatricRegistration();
    } else {
      this.patient = new User();
      this.setupConsumerPediatricRegistration();
    }
  }

  private setLoggedInRegistrationStateAttributes() {
    this.userService.getUser();
    this.membershipService.getMembership();
    this.nonmemberRegistration = false;
    combineLatest([this.membershipService.membership$, this.userService.user$])
      .pipe(take(1))
      .subscribe(([membership, user]: [Membership, User]) => {
        this.patient.serviceArea = user.serviceArea;
        if (membership.isB2b()) {
          this.planId = membership.planId;
        }
      });
  }

  private setLoggedOutRegistrationStateAttributes() {
    this.nonmemberRegistration = true;
    this.questions = this.loggedOutPediatricQuestions.concat(this.questions);
  }

  private setupEnterprisePediatricRegistration() {
    this.questions = this.generalPediatricQuestions;
    this.membershipType = 'Enterprise';
  }

  private setupConsumerPediatricRegistration() {
    this.questions = this.consumerPediatricQuestions;
    this.membershipType = 'Consumer';
    this.additionalStepsPostAccountCreation = 3;
  }

  private canSkipServiceAreaQuestion() {
    return this.currentQuestionIndex === 1 && this.serviceAreas.length === 1;
  }
}
