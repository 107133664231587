import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

import { SendAppLink, SendAppLinkVariables } from '@app/shared/download-app-card/__generated__/SendAppLink';

@Injectable({
  providedIn: 'root',
})
export class SendAppLinkGraphql extends Mutation<SendAppLink, SendAppLinkVariables> {
  document = gql`
    mutation SendAppLink($input: SendAppLinkInput!) {
      sendAppLink(input: $input) {
        success
        errors
      }
    }
  `;
}
