import { Injectable } from '@angular/core';
import { Query, gql, Apollo } from 'apollo-angular';

export interface CommonConcern {
  displayName: string;
  name: string;
}

export interface CommonConcernsResponse {
  topics: CommonConcern[];
}

@Injectable({
  providedIn: 'root',
})
export class CommonConcernsGraphQL extends Query<CommonConcernsResponse> {
  constructor(apollo: Apollo) {
    super(apollo);
  }

  document = gql`
    query CommonConcerns {
      topics {
        displayName
        name
      }
    }
  `;
}
