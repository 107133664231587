<div class="value-prop-container" [class.value-prop-container-FreeTrial]="registerFlows.FreeTrial == regFlow">
  <div [ngSwitch]="regFlow">
    <div class="free-trial-tos" *ngSwitchCase="registerFlows.FreeTrial">
      <div>
        <h2 class="free-trial-amf">$0</h2>
        <span class="text-left"
          ><h5>{{ trialDays }}-Day Free Trial</h5>
          <div>
            <span>Valid Until {{ planRenewsOn }}</span></div
          ></span
        >
      </div>
      <hr />

      <small *ngIf="coupon && coupon.skipCreditCard"
        >Your membership will deactivate at trial end. New members only. One Medical reserves the right to cancel or
        limit the promotion in its sole discretion at any time. Void where prohibited.
      </small>

      <small *ngIf="coupon && !coupon.skipCreditCard"
        >We require your payment information to initiate the free trial, but you won’t be charged until after the
        {{ trialDays }}-Day Free Trial period. We’ll remind you prior to charging your card and your One Medical
        Membership will renew automatically at ${{ amf }}/year until you cancel. Cancel anytime. New members only. One
        Medical reserves the right to cancel or limit the promotion in its sole discretion at any time. Void where
        prohibited.
        <span *ngIf="trialAutoRenew"
          >Free trial offer void in Washington D.C. Washington D.C. residents not eligible for free trial offer.<br /></span
      ></small>
    </div>
    <div *ngSwitchDefault>
      <h5 class="text-center">Annual Membership Fee</h5>
      <div *ngIf="coupon" class="text-center mb-4">
        <span class="discount-amf">${{ amf }}</span
        ><h2 class="display-inline">${{ getTotal() }}</h2>
      </div>
      <div *ngIf="!coupon" class="text-center mb-4">
        <h2 class="display-inline">${{ getTotal() }}</h2
        ><span>/year</span>
      </div>

      <dl class="row col-12 mb-4">
        <!-- needs "row" for title & description to be side-by-side, col-12 cancels out row negative margin -->
        <dt class="checkmark-wrapper">
          <om-svg-checkmark color="#004D49" width="16" height="13"></om-svg-checkmark>
        </dt>
        <dd class="mb-3">Same/next-day appointments, in person or over video, that start on time</dd>
        <dt class="checkmark-wrapper">
          <om-svg-checkmark color="#004D49" width="16" height="13"></om-svg-checkmark>
        </dt>
        <dd class="mb-3">24/7 Video Chats with our virtual medical team at no extra cost</dd>
        <dt class="checkmark-wrapper">
          <om-svg-checkmark color="#004D49" width="16" height="13"></om-svg-checkmark>
        </dt>
        <dd class="mb-3">85+ convenient locations with drop-in lab services</dd>
        <dt class="checkmark-wrapper">
          <om-svg-checkmark color="#004D49" width="16" height="13"></om-svg-checkmark>
        </dt>
        <dd class="mb-3">Easy appointment booking and Rx renewals in the app</dd>
        <dt class="checkmark-wrapper">
          <om-svg-checkmark color="#004D49" width="16" height="13"></om-svg-checkmark>
        </dt>
        <dd class="mb-3">Experts to help navigate the healthcare maze</dd>
      </dl>
    </div>
  </div>

  <om-submit-button
    buttonClass="limited-width"
    class=""
    [label]="buttonLabel"
    [disabled]="disabled"
    [submitting]="submitting"
  ></om-submit-button>
</div>
