<nav aria-label="Page navigation">
  <ul class="pagination justify-content-center">
    <li class="page-item" [class.disabled]="onFirstPage">
      <button type="button" class="page-link" (click)="back()">Newer</button>
    </li>
    <li
      *ngFor="let page of pages"
      class="page-item d-sm-block"
      [class.active]="page == currentPage"
      [class.d-none]="isHiddenForSmallViews(page)"
    >
      <button type="button" class="page-link" (click)="goToPage(page)"> {{ page }} </button>
    </li>
    <li class="page-item" [class.disabled]="onLastPage">
      <button type="button" class="page-link" (click)="forward()">Older</button>
    </li>
  </ul>
</nav>
