import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

import { WindowService } from '@app/core/window.service';
import { AppStoreRedirectService } from '@app/shared';

@Injectable()
export class MobileAppRedirectGuardService implements CanActivate {
  constructor(private appStoreRedirectService: AppStoreRedirectService, private windowService: WindowService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const mobileRedirectUrl = this.appStoreRedirectService.mobileRedirectUrl;
    if (mobileRedirectUrl) {
      this.windowService.redirect(mobileRedirectUrl);
    }

    return true;
  }
}
