import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppStoreRedirectComponent } from '@app/app-store-redirect/app-store-redirect.component';
import { AppointmentJoinComponent } from '@app/appointment/appointment-join/appointment-join.component';
import { AccountActiveGuardService } from '@app/core/account-active-guard.service';
import { GuestGuardService } from '@app/core/guest-guard.service';
import { LoginGuardService } from '@app/core/login-guard.service';
import { MobileAppRedirectGuardService } from '@app/core/mobile-app-redirect-guard.service';
import { TosGuardService } from '@app/core/tos-guard.service';

import { AuthGuardService } from './core/auth-guard.service';
import { DesignComponent } from './core/design/design.component';
import { DevRouteGuardService } from './core/dev-route-guard.service';
import { Login } from './core/login/login.component';
import { Logout } from './core/logout/logout.component';
import { RegistrationCompleteGuardService } from './core/registration-complete-guard.service';
import { TosInterstitialComponent } from './core/tos/tos-interstitial.component';
import { LoggedInWrapperComponent } from './shared/logged-in-wrapper/logged-in-wrapper.component';

const appRoutes: Routes = [
  { path: 'logout', component: Logout },
  { path: 'login', component: Login, canActivate: [LoginGuardService] },
  { path: 'terms-of-service', component: TosInterstitialComponent, canActivate: [AuthGuardService] },
  {
    path: 'appointments/:id/join',
    component: AppointmentJoinComponent,
    canActivate: [MobileAppRedirectGuardService, AuthGuardService],
  },
  // auth required pages
  {
    path: '',
    component: LoggedInWrapperComponent,
    // NOTE: If you need to add guards to top level, make sure to handle logged out case by returning false - see guards
    // already in place for examples. Otherwise subsequent guards may get errors from 1life leading to duplicative redirect
    // to login, leading to inconsistent / dropped / unexpected redirect to attempted path after login
    canActivate: [AuthGuardService, RegistrationCompleteGuardService, TosGuardService],
    children: [
      {
        path: '',
        loadChildren: () => import('@app/home/home.module').then(m => m.HomeModule),
        canActivate: [AccountActiveGuardService, GuestGuardService],
      },
      {
        path: 'account',
        loadChildren: () => import('@app/account/account.module').then(m => m.AccountModule),
      },
      {
        path: 'appointments',
        loadChildren: () => import('@app/appointment/appointment.module').then(m => m.AppointmentModule),
        canActivate: [GuestGuardService],
      },

      {
        path: 'billing',
        loadChildren: () => import('@app/billing/billing.module').then(m => m.BillingModule),
      },
      {
        path: 'connect-account',
        loadChildren: () => import('@app/connect-account/connect-account.module').then(m => m.ConnectAccountModule),
      },
      {
        path: 'health-record',
        loadChildren: () => import('@app/health-record/health-record.module').then(m => m.HealthRecordModule),
        canActivate: [GuestGuardService],
      },
      {
        path: 'membership',
        loadChildren: () => import('@app/membership/membership.module').then(m => m.MembershipModule),
      },
      {
        path: 'messages',
        loadChildren: () => import('@app/message/message.module').then(m => m.MessageModule),
      },
      {
        path: 'pharmacy',
        loadChildren: () => import('@app/pharmacy/pharmacy.module').then(m => m.PharmacyModule),
        canActivate: [AccountActiveGuardService, GuestGuardService],
      },
      {
        path: 'prescription',
        loadChildren: () => import('@app/prescription/prescription.module').then(m => m.PrescriptionModule),
        canActivate: [AccountActiveGuardService, GuestGuardService],
      },
      {
        path: 'survey',
        loadChildren: () => import('@app/survey/survey.module').then(m => m.SurveyModule),
        canActivate: [GuestGuardService],
      },
      {
        path: 'care-plan',
        loadChildren: () => import('@app/task/task.module').then(m => m.TaskModule),
        canActivate: [GuestGuardService],
      },
      {
        path: 'appointment-booking-sessions',
        redirectTo: 'appointments/appointment-booking-sessions',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'health-information-release',
    loadChildren: () =>
      import('@app/health-information-release/health-information-release.module').then(
        m => m.HealthInformationReleaseModule,
      ),
  },
  { path: 'profile', redirectTo: '/health-record/blood-pressure', pathMatch: 'full' },
  {
    path: 'profile/blood-pressure',
    redirectTo: '/health-record/blood-pressure',
    pathMatch: 'full',
  },
  {
    path: 'registration',
    loadChildren: () => import('@app/registration/registration.module').then(m => m.RegistrationModule),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('@app/registration/consumer/consumer-registration.module').then(m => m.ConsumerRegistrationModule),
  },
  {
    path: 'gift',
    loadChildren: () => import('@app/gift/gift.module').then(m => m.GiftModule),
  },
  { path: 'app-store-redirect', component: AppStoreRedirectComponent },
  {
    path: 'design',
    component: DesignComponent,
    canActivate: [DevRouteGuardService],
  },
  {
    path: 'style-guide',
    loadChildren: () => import('@app/style-guide/style-guide.module').then(m => m.StyleGuideModule),
    canActivate: [DevRouteGuardService],
  },
  // Provides a way for external links on mobile devices to open in browser instead of the app
  // For iOS this is handled by an entry in src/.well-known/apple-app-site-association
  // For Android this is handled by the app, which includes /appointments/*
  {
    path: 'shift-redirect',
    // Omitting leading slash and using pathMatch full to preserve query params
    redirectTo: 'appointments/shift',
    pathMatch: 'full',
  },
  {
    path: 'mindset-redirect',
    redirectTo: 'appointments/mindset',
    pathMatch: 'full',
  },
  {
    path: 'tasks',
    redirectTo: 'care-plan/action-items',
    pathMatch: 'prefix',
  },
  { path: 'after-visit-summaries', redirectTo: 'care-plan/after-visit-summaries', pathMatch: 'full' },
  { path: 'after-visit-summaries', redirectTo: 'care-plan/after-visit-summaries', pathMatch: 'prefix' },

  { path: '**', redirectTo: '/', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
