<div class="d-flex align-items-center">
  <input
    [id]="inputId"
    type="text"
    class="form-control common-form-control clear-button-space"
    [placeholder]="placeholder"
    [(ngModel)]="_inputText"
    (ngModelChange)="handleChange($event)"
    (blur)="handleTouched()"
  />
  <button class="btn" aria-label="Clear input field" type="button" [hidden]="!_inputText" (click)="clearInput()">
    <om-x-with-circle circleFillColor="#918F8D" widthPx="24"></om-x-with-circle>
  </button>
</div>
