import {
  AfterContentInit,
  Component,
  ContentChild,
  ContentChildren,
  Directive,
  Input,
  OnInit,
  QueryList,
  TemplateRef,
} from '@angular/core';

import { cardContentAnimation, enterAnimation } from '../page-state/animations';
import { isSuccessState, PageState } from '../page-state/page-state';

@Directive({
  selector: '[om-card-table-empty-state]',
})
export class CardTableEmptyStateDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({
  selector: '[om-card-table-col]',
})
export class CardTableColDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({
  selector: '[om-card-table-row-footer]',
})
export class CardTableRowFooterDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({
  selector: 'om-card-table-header',
})
export class CardTableHeaderDirective {
  @ContentChildren(CardTableColDirective) cols: QueryList<CardTableColDirective>;

  constructor() {}

  templateRefFor(index: number): TemplateRef<any> {
    if (this.cols == null) {
      return undefined;
    }

    const colsArray = this.cols.toArray();
    if (index >= colsArray.length) {
      return undefined;
    }

    return colsArray[index].templateRef;
  }
}

@Directive({
  selector: 'om-card-table-row',
})
export class CardTableRowDirective implements AfterContentInit {
  @ContentChildren(CardTableColDirective) cols: QueryList<CardTableColDirective>;
  @ContentChild(CardTableRowFooterDirective) footer: CardTableRowFooterDirective;
  @Input() link: string;
  ready = false;
  multiColumns = false;

  constructor() {}

  ngAfterContentInit() {
    this.multiColumns = this.cols.length > 2;
    this.ready = true;

    this.cols.changes.subscribe(() => {
      this.multiColumns = this.cols.length > 2;
    });
  }
}

@Component({
  selector: 'om-card-table',
  templateUrl: './card-table.component.html',
  styleUrls: ['../../shared/card.scss'],
  animations: [enterAnimation, cardContentAnimation],
})
export class CardTableComponent implements OnInit {
  @ContentChild(CardTableHeaderDirective) header: CardTableHeaderDirective;
  @ContentChild(CardTableEmptyStateDirective) emptyState: CardTableEmptyStateDirective;
  @ContentChildren(CardTableRowDirective) rows: QueryList<CardTableRowDirective>;
  @Input() pageState: PageState = PageState.SUCCESS;
  isSuccessState = isSuccessState;

  constructor() {}

  ngOnInit() {}
}
