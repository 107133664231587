import { Component, OnInit } from '@angular/core';

import { AnalyticsService } from '@app/core/analytics.service';
import { AuthService } from '@app/core/auth.service';

@Component({
  selector: 'om-logout',
  template: '',
})
export class Logout implements OnInit {
  constructor(private authService: AuthService, private analyticsService: AnalyticsService) {}

  ngOnInit() {
    this.authService.logout();
    this.analyticsService.resetMixpanelId();
  }
}
