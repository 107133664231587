import { Component, Input } from '@angular/core';

@Component({
  selector: 'om-svg-pill-bottle',
  templateUrl: './svg-pill-bottle.component.html',
})
export class SvgPillBottleComponent {
  @Input() widthPx = 48;
  @Input() heightPx = 48;
}
