import { Component, OnInit } from '@angular/core';

import { environment } from '../../../environments/environment';
import { WindowService } from '../window.service';

@Component({
  selector: 'om-design',
  template: ``,
})
export class DesignComponent implements OnInit {
  constructor(private windowService: WindowService) {}

  ngOnInit() {
    if (environment.designSystemUrl) {
      this.windowService.redirect(environment.designSystemUrl);
    } else {
      this.windowService.redirect('/');
    }
  }
}
