import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'om-location-on-map',
  templateUrl: './location-on-map.component.html',
})
export class LocationOnMapComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
