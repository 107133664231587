import each from 'lodash-es/each';
import get from 'lodash-es/get';
export class AppointmentType {
  get id(): number {
    return this.firstAppointmentSelected ? this.firstVisitAppointmentTypeId : this._id;
  }

  constructor() {}

  private static readonly mappingGraphQL = {
    _id: 'id',
    description: 'description',
    displayedDuration: 'displayedDuration',
    name: 'name',
    displayName: 'displayName',
    firstVisitAppointmentTypeId: 'firstVisitAppointmentType.id',
    isSpecialty: 'isSpecialty',
    position: 'position',
    firstAppointmentSelected: 'firstAppointmentSelected',
    remote: 'remote',
  };

  private static readonly mappingApiV2 = {
    _id: 'id',
    description: 'description',
    longDescription: 'long_description',
    displayedDuration: 'displayed_duration',
    name: 'name',
    displayName: 'display_name',
    firstVisitAppointmentTypeId: 'first_visit_appointment_type_id',
    isSpecialty: 'is_specialty',
    position: 'position',
    firstAppointmentSelected: 'first_appointment_selected',
    remote: 'remote',
  };

  description: string;
  longDescription: string;
  displayedDuration: number;
  name: string;
  displayName: string;
  hasAdditionalQuestion: boolean;
  isSpecialty: boolean;
  position: number;
  selected = false;
  firstAppointmentSelected = false;
  firstVisitAppointmentTypeId: number;
  remote = false;

  private _id: number;

  static fromApiV2(response): AppointmentType {
    const appointmentType = this.initializeAppointmentType(this.mappingApiV2, response);
    return appointmentType;
  }

  static fromGraphQL(response): AppointmentType {
    const appointmentType = this.initializeAppointmentType(this.mappingGraphQL, response);
    return appointmentType;
  }

  private static initializeAppointmentType(mapping, response) {
    const appointmentType = new AppointmentType();
    each(mapping, (value, key) => {
      appointmentType[key] = get(response, value);
    });
    const { _id, firstVisitAppointmentTypeId } = appointmentType;

    appointmentType.hasAdditionalQuestion = firstVisitAppointmentTypeId != null;

    // graphQL id's return as strings so let's make sure they are all numbers!
    appointmentType._id = _id ? parseInt('' + _id, 10) : _id;
    appointmentType.firstVisitAppointmentTypeId =
      firstVisitAppointmentTypeId != null ? parseInt('' + firstVisitAppointmentTypeId, 10) : null;
    return appointmentType;
  }

  toApiV2() {
    const response = {};
    each(AppointmentType.mappingApiV2, (value, key) => {
      response[value] = this[key];
    });
    return response;
  }
}
