import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';

interface NewRelicInfo {
  applicationID: string;
  beacon: string;
  errorBeacon: string;
  licenseKey: string;
  sa: number;
}

interface NewRelicLoaderConfig {
  accountID: string;
  agentID: string;
  applicationID: string;
  licenseKey: string;
  trustKey: string;
}

interface NewRelicEnvironment {
  loader_config: NewRelicLoaderConfig;
  info: NewRelicInfo;
}

declare global {
  interface Window {
    NREUM: NewRelicEnvironment;
  }
}

@Injectable({
  providedIn: 'root',
})
export class APMService {
  init() {
    if (!environment.newRelic) {
      return;
    }

    window.NREUM.loader_config = {
      accountID: environment.newRelic.accountID,
      agentID: environment.newRelic.applicationID,
      applicationID: environment.newRelic.applicationID,
      licenseKey: environment.newRelic.licenseKey,
      trustKey: environment.newRelic.accountID,
    };
    window.NREUM.info = {
      applicationID: environment.newRelic.applicationID,
      beacon: 'bam.nr-data.net',
      errorBeacon: 'bam.nr-data.net',
      licenseKey: environment.newRelic.licenseKey,
      sa: 1,
    };
  }
}
