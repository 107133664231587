import { Component, Input, OnInit } from '@angular/core';

import { MapStyles } from './map-styles';

@Component({
  selector: 'om-styled-map',
  templateUrl: './styled-map.component.html',
  styleUrls: ['./styled-map.component.scss'],
})
export class StyledMapComponent implements OnInit {
  @Input() latitude: number;
  @Input() longitude: number;
  @Input() zoom: number;

  styles = MapStyles.MARKETING_MAP_STYLE;
  icon = '/assets/images/icons/pin-icon.svg';

  constructor() {}

  ngOnInit() {}
}
