import { Injectable } from '@angular/core';
import debounce from 'lodash-es/debounce';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { AnalyticsService } from '@app/core/analytics.service';

import { ApiService } from '../core/api.service';
import { ActiveFeatureFlagCollection, Features } from './active-feature-flag-collection';

@Injectable()
export class FeatureFlagService {
  private _activeFeatureFlags$ = new BehaviorSubject<ActiveFeatureFlagCollection>(null);
  readonly activeFeatureFlags$ = this._activeFeatureFlags$.asObservable().pipe(filter(flags => flags != null));

  private readonly _debouncedGetActiveFeatureFlags;

  constructor(private apiService: ApiService, private analyticsService: AnalyticsService) {
    this._debouncedGetActiveFeatureFlags = debounce(this._getActiveFeatureFlags.bind(this), 1000, {
      leading: true,
    });
  }

  getActiveFeatureFlags(force = false): void {
    if (force) {
      this._getActiveFeatureFlags();
    } else if (!this._activeFeatureFlags$.getValue()) {
      this._debouncedGetActiveFeatureFlags();
    }
  }

  private _getActiveFeatureFlags(): Subscription {
    return this.apiService.get('/api/v2/features').subscribe(response => {
      const featureFlags = ActiveFeatureFlagCollection.fromApiV2(response);
      this._activeFeatureFlags$.next(featureFlags);
      this.analyticsService.trackFeatureFlags(featureFlags.activeFlags);
    });
  }

  featureActive$(feature: Features): Observable<boolean> {
    this.getActiveFeatureFlags();
    return this.activeFeatureFlags$.pipe(map((flags: ActiveFeatureFlagCollection) => flags.isFeatureActive(feature)));
  }
}
