import { Injectable } from '@angular/core';
import { throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ApiService } from '../core/api.service';

@Injectable()
export class ValidateEmailService {
  constructor(private apiService: ApiService) {}

  validateUniqueness(email: string, recaptchaToken: string) {
    return this.apiService
      .post('/api/v2/public/validate_emails', {
        email: email,
        'g-recaptcha-response': recaptchaToken,
      })
      .pipe(catchError(this.handleError));
  }

  private handleError(error) {
    const message = 'This email is invalid or already in use.';

    return observableThrowError(message);
  }
}
