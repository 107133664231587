import { Component, Input, ViewChild } from '@angular/core';

import { EmailInputComponent } from '@app/shared/email-input/email-input.component';

import { RegistrationStepDirective } from '../registration-step.directive';

@Component({
  selector: 'om-work-email-step',
  templateUrl: './work-email-step.component.html',
})
export class WorkEmailStepComponent extends RegistrationStepDirective {
  @ViewChild('workEmail', { static: false }) workEmail: EmailInputComponent;
  @Input() errorMessage = '';
  @Input() shownView = '';
  @Input() experimentLoading = true;
  @Input() numberOfOffices: number;
  hasError = false;

  onLogIn(e: Event) {
    e.preventDefault();
    this.login.emit();
  }

  onError(err: Error) {
    this.submitting = false;
    this.hasError = true;
  }

  onSubmit() {
    this.workEmail.markAsTouchedAndDirty();

    // triggering the blur and focus forces the email input to update and validate.
    this.workEmail.blur();
    this.workEmail.focus();
    this.submitting = true;
    this.submit.emit(this.form.value);
  }
}
