<svg fill="none" [attr.width]="width" [attr.height]="height" viewBox="0 0 24 15" xmlns="http://www.w3.org/2000/svg">
  <path
    clip-rule="evenodd"
    d="m20.6331 6.89043.0079-.14888.3775-.28036c.0517-.03834.0815-.09636.0815-.16119 0-.06763-.0327-.12815-.0882-.16596l-.115-.07843-.0893-.10687c-2.6305-3.14985-5.3648-4.77065-8.2302-4.93556-.1251-.00877-.2509-.01318-.3773-.01318-.0187.00002-.0187.00002-.0498.00014-.0241-.00012-.0241-.00012-.0482-.00014-3.01042 0-5.98529 1.65668-8.93948 5.0547l-.09426.0948c-.04383.03854-.06826.0914-.06826.1505 0 .06872.03387.13021.09082.16773l.10266.06764.08322.09049c2.85753 3.1071 5.66226 4.73684 8.4261 4.95124.1644.0152.3303.0229.4972.0229.0218 0 .0218 0 .0584-.0002.0272.0002.0272.0002.0545.0002 2.8687 0 5.6354-1.542 8.3202-4.70957z"
    fill-rule="evenodd"
    [attr.stroke]="color"
    stroke-width="2"
  />
  <path d="m23 0h-21v7h21z" fill="#fff" />
  <g [attr.fill]="color">
    <path d="m13 12c0-.5523-.4477-1-1-1s-1 .4477-1 1v2c0 .5523.4477 1 1 1s1-.4477 1-1z" />
    <path
      clip-rule="evenodd"
      d="m20.0688 7.48301-.3688.37756c-1.4712.61149-1.9337.61149-1.3874 0 .5189-.58095.8932-1.13157 1.1228-1.65188.0385-.18382.129-.35891.2717-.50161.3905-.39053 1.0237-.39053 1.4142 0l2.1213 2.12132c.3906.39052.3906 1.02369 0 1.41421-.3905.39053-1.0236.39053-1.4142 0z"
      fill-rule="evenodd"
    />
    <path
      clip-rule="evenodd"
      d="m4.47098 6.06002c.14202.26313.41919.64312.83151 1.13995.49795.6.2947.88172-.60974.84517l-.6872-.68676-1.88423 1.88423c-.39052.39053-1.02369.39053-1.414212 0-.390524-.39052-.390524-1.02369 0-1.41421l2.121322-2.12132c.39052-.39053 1.02369-.39053 1.41421 0 .1036.10359.17971.22426.22834.35294z"
      fill-rule="evenodd"
    />
    <path
      d="m8.24264 11.1213c.39053-.3905.39053-1.0237 0-1.41419-.39052-.39053-1.02368-.39053-1.41421 0l-2.12132 2.12129c-.39052.3906-.39052 1.0237 0 1.4142.39052.3906 1.02369.3906 1.41421 0z"
    />
    <path
      d="m17.8284 13.2426c.3906.3906 1.0237.3906 1.4142 0 .3906-.3905.3906-1.0237 0-1.4142l-1.4142-1.4142c-.3905-.3905-1.0237-.3905-1.4142 0s-.3905 1.0237 0 1.4142z"
    />
  </g>
</svg>
