import { Component, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';

import { AnalyticsService } from '@app/core/analytics.service';
import { UserService } from '@app/core/user.service';
import { Features } from '@app/shared/active-feature-flag-collection';

import { FeatureFlagService } from '../feature-flag.service';

@Component({
  selector: 'om-direct-signup-banner',
  templateUrl: './full-width-centered-banner.component.html',
  styleUrls: ['./full-width-centered-banner.component.scss'],
})
export class DirectSignupBannerComponent implements OnInit {
  constructor(
    private analyticsService: AnalyticsService,
    private userService: UserService,
    private featureFlagService: FeatureFlagService,
  ) {}

  text: string;
  cta = 'Get Started';
  icon: null;
  linkTo = '/registration/referrals';

  ngOnInit() {
    combineLatest([this.userService.user$, this.featureFlagService.activeFeatureFlags$])
      .pipe(take(1))
      .subscribe(([user, featureFlags]) => {
        this.text = 'Register your spouse or dependents';
        if (
          user.whitelistedEmployee &&
          !user.hasUnregisteredDependents &&
          !featureFlags.isFeatureActive(Features.DISABLE_ENTERPRISE_PEDS_REGISTRATION)
        ) {
          this.text = 'Register dependents';
        }
      });

    this.userService.getUser();
    this.featureFlagService.getActiveFeatureFlags();
  }

  ctaAction() {
    this.analyticsService.trackDirectSignupStarted();
  }
}
