import { Component, HostBinding, Input, OnInit } from '@angular/core';

const REACTIVE_LABEL_WIDTH_CLASSES = 'form-group col-9 offset-1 col-sm-8 offset-sm-2 col-md-6 offset-md-3';

@Component({
  selector: 'om-label-wrapper',
  templateUrl: './label-wrapper.component.html',
  styleUrls: ['../form-input.scss'],
})
export class LabelWrapper implements OnInit {
  @Input() label: string;
  @Input() labelClass: string;
  @Input() inputWrapperClass: string;
  @Input() errorMessageClass = 'text-danger mb-1';
  @Input() @HostBinding('class.has-error') hasError: boolean;
  @Input() @HostBinding('class') class: string = REACTIVE_LABEL_WIDTH_CLASSES;
  @Input() errorMessage: string;

  ngOnInit() {
    // This is in place to disallow empty string class from overriding the default value.
    // That allows for other components - such as AddressAutocompleteComponent - to have an optional
    // input for the labelClass & not override it with an empty string here if it isn't provided to the wrapping component
    if (typeof this.labelClass === 'undefined' || this.labelClass === '') {
      this.labelClass = 'font-weight-normal';
    }
  }
}
