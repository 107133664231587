import { Component, EventEmitter, Output } from '@angular/core';
import get from 'lodash-es/get';
import moment from 'moment';
import { take } from 'rxjs/operators';

import { AttestSelfPayIsPrimaryInsuranceGraphQL } from '@app/account/profile/attest-self-pay-graphql.service';

@Component({
  selector: 'om-self-pay-confirmation',
  templateUrl: './self-pay-confirmation.component.html',
  styleUrls: ['./self-pay-confirmation.component.scss'],
})
export class SelfPayConfirmationComponent {
  @Output() completionEvent = new EventEmitter<any>();
  @Output() closeEvent = new EventEmitter<void>();

  isSaving = false;
  submitFailed = false;

  constructor(private attestSelfPayIsPrimaryInsuranceGraphQL: AttestSelfPayIsPrimaryInsuranceGraphQL) {}

  close() {
    this.closeEvent.emit();
  }

  onConfirm(): void {
    if (this.isSaving) {
      return;
    }

    this.isSaving = true;

    this.attestSelfPayIsPrimaryInsuranceGraphQL
      .mutate()
      .pipe(take(1))
      .subscribe({
        next: response => {
          if (get(response, 'data.attestSelfPayIsPrimaryInsurance.success')) {
            this.completionEvent.emit(response);
          } else {
            this.submitFailed = true;
          }

          this.isSaving = false;
        },
        error: () => {
          this.submitFailed = true;
          this.isSaving = false;
        },
      });
  }
}
