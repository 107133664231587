import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { State } from '@app/app.reducer';
import { Membership } from '@app/core/membership';
import { MembershipService } from '@app/core/membership.service';
import { MP_EVENT_PAGE_VIEWED } from '@app/core/mixpanel.constants';
import { MixpanelService } from '@app/core/mixpanel.service';
import { Coupon } from '@app/shared/coupon';
import { ServiceArea } from '@app/shared/service-area';

import { environment } from '../../environments/environment';
import { AnalyticsService } from '../core/analytics.service';
import { ConfigService } from '../core/config.service';

export interface RegInputEnteredProperties {
  formField: string;
  membershipType: string;
  module: string;
  value: any;
}

declare const ga;
declare const fbq;
declare const window;

@Injectable({
  providedIn: 'root',
})
export class RegistrationAnalyticsService extends AnalyticsService {
  protected membershipCoupon: Coupon;
  private isFamilyFlow = false;

  constructor(
    private config: ConfigService,
    protected membershipService: MembershipService,
    store: Store<State>,
    mixpanel: MixpanelService,
  ) {
    super(mixpanel, store);

    if (config.json.gaAccount) {
      ga('create', config.json.gaAccount, 'auto');
      ga('send', 'pageview', location.pathname);
    }

    if (config.json.facebookAccount) {
      fbq('init', config.json.facebookAccount);
      fbq('track', 'PageView');

      fbq.push(['addPixelId', config.json.facebookAccount]);
      fbq.push(['track', 'PixelInitialized', {}]);
    }

    this.membershipService.membership$.subscribe((membership: Membership) => {
      this.membershipCoupon =
        membership.isActive && membership.familyPromotionDiscountCoupon
          ? membership.familyPromotionDiscountCoupon
          : null;
    });

    if (config.json.linkedinPartnerId) {
      (<any>window)._linkedin_data_partner_id = config.json.linkedinPartnerId;
      (function () {
        const s = document.getElementsByTagName('script')[0];
        const b = document.createElement('script');
        b.type = 'text/javascript';
        b.async = true;
        b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
        s.parentNode.insertBefore(b, s);
      })();
    }

    if (config.json.hotjarId) {
      (function (h, o, t, j, a, r) {
        h.hj =
          h.hj ||
          function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: config.json.hotjarId, hjsv: 6 };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script');
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(<any>window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    }
  }

  trackWithMembershipCoupon(eventName: string, properties?: object): Observable<any> {
    const inputProps = properties ? properties : {};

    if (this.membershipCoupon && this.membershipCoupon.id === 'OMKIDSSPECIAL2021') {
      inputProps['promo_banner_type'] = 'OM Kids Special 2021';
    }

    return this.trackWithDefaultProperties(eventName, inputProps);
  }

  regInputEntered(props: RegInputEnteredProperties) {
    ga('send', 'event', 'reg-question-answered', props.formField);

    return this.track('Registration Input Entered', {
      flow: 'Registration',
      form_field: props.formField,
      module: props.module,
      om_membership_type: props.membershipType,
      value: props.value || null,
    });
  }

  invalidRegInputEntered(question: string) {
    ga('send', 'event', 'reg-question-answered-invalid', question);
  }

  previousQuestionClicked(currentQuestion: string) {
    ga('send', 'event', 'reg-back-button-clicked', currentQuestion);
  }

  trackGoogleEvent(category: string, action: string, label: string) {
    ga('send', 'event', category, action, label);
  }

  childIntakePageViewed({ isLoggedIn, source }: { isLoggedIn: boolean; source?: string }) {
    let source_property = '';
    const module_variant = source === 'familyFlowConfirmationPage' ? 'Me and My Child' : '';
    if (['confirmationPagePromoCard', 'familyFlowConfirmationPage'].indexOf(source) !== -1) {
      source_property = 'Adult Registration Confirmation Page';
      this.isFamilyFlow = true; // needed for reporting module_variant on the confirmation page
    }
    this.trackGoogleEvent('PedSignupForm', 'page view', 'ChildInfo_Page');
    return this.membershipPropertiesUpdated.then(() => {
      this.trackWithMembershipCoupon(MP_EVENT_PAGE_VIEWED, {
        flow: 'Direct Sign Up',
        module: 'Child Info Page',
        is_pediatrics: true,
        is_logged_in: isLoggedIn,
        module_variant: module_variant,
        source: source_property,
      });
    });
  }

  childBirthTypeSelected({
    unborn,
    isLoggedIn,
    omMembershipType,
  }: {
    unborn: boolean;
    isLoggedIn: boolean;
    omMembershipType: string;
  }) {
    return this.trackWithDefaultProperties('Child Birth Type Selected', {
      flow: 'Direct Sign Up',
      module: 'Child Info Page',
      birth_type: unborn ? 'Unborn' : 'Born',
      is_pediatrics: true,
      is_logged_in: isLoggedIn,
      om_membership_type: omMembershipType,
    });
  }

  guardianConfirmationPageViewed({ isLoggedIn, omMembershipType }: { isLoggedIn: boolean; omMembershipType: string }) {
    return this.trackWithMembershipCoupon(MP_EVENT_PAGE_VIEWED, {
      flow: 'Direct Sign Up',
      module: 'Guardian Info Page',
      is_pediatrics: true,
      is_logged_in: isLoggedIn,
      om_membership_type: omMembershipType,
    });
  }

  guardianConfirmationPageConfirmButtonClicked({ isLoggedIn }: { isLoggedIn: boolean }) {
    return this.trackWithDefaultProperties('Guardian Changes Confirmed', {
      flow: 'Direct Sign Up',
      module: 'Guardian Info Page',
      is_pediatrics: true,
      is_logged_in: isLoggedIn,
    });
  }

  guardianConfirmationPageClosed({ isLoggedIn }: { isLoggedIn: boolean }) {
    return this.trackWithDefaultProperties('Guardian Changes Go Back', {
      flow: 'Direct Sign Up',
      module: 'Guardian Changes Modal',
      is_pediatrics: true,
      is_logged_in: isLoggedIn,
    });
  }

  childAccountCreationPageViewed({ isLoggedIn, omMembershipType }: { isLoggedIn: boolean; omMembershipType: string }) {
    return this.trackWithMembershipCoupon(MP_EVENT_PAGE_VIEWED, {
      flow: 'Direct Sign Up',
      module: 'Child Account Creation Page',
      is_pediatrics: true,
      is_logged_in: isLoggedIn,
      om_membership_type: omMembershipType,
    });
  }

  childAccountEmailLinkSelected({ isLoggedIn, omMembershipType }: { isLoggedIn: boolean; omMembershipType: string }) {
    return this.trackWithDefaultProperties('Child Account Email Link Selected', {
      flow: 'Direct Sign Up',
      module: 'Child Account Creation Page',
      is_pediatrics: true,
      is_logged_in: isLoggedIn,
      om_membership_type: omMembershipType,
    });
  }

  pedsUnavailableModalShown({
    serviceArea,
    isLoggedIn,
    omMembershipType,
  }: {
    serviceArea: ServiceArea;
    isLoggedIn: boolean;
    omMembershipType: string;
  }) {
    return this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, {
      flow: 'Direct Sign Up',
      module: 'Pediatrics Location Error Modal',
      service_area: serviceArea.name,
      is_pediatrics: true,
      is_logged_in: isLoggedIn,
      om_membership_type: omMembershipType,
    });
  }

  confirmationPageViewed({ isLoggedIn, omMembershipType }: { isLoggedIn: boolean; omMembershipType: string }) {
    const module_variant = this.isFamilyFlow ? 'Another Kid OM Kids Special 2021' : '';
    this.trackGoogleEvent('PedSignupForm', 'page view', 'Confirmation_Page');
    return this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, {
      flow: 'Direct Sign Up',
      module: 'Child Membership Confirmation Page',
      module_variant: module_variant,
      is_pediatrics: true,
      is_logged_in: isLoggedIn,
      om_membership_type: omMembershipType,
    });
  }

  confirmationActionSelected({
    action,
    isLoggedIn,
    omMembershipType,
  }: {
    action: string;
    isLoggedIn: boolean;
    omMembershipType: string;
  }) {
    return this.trackWithDefaultProperties('Child Membership Confirmation Action Selected', {
      flow: 'Direct Sign Up',
      module: 'Child Membership Confirmation Page',
      is_pediatrics: true,
      is_logged_in: isLoggedIn,
      om_membership_type: omMembershipType,
      action_selected: action,
    });
  }

  addressManualLinkClicked({ isLoggedIn, omMembershipType }: { isLoggedIn: boolean; omMembershipType: string }) {
    return this.trackWithDefaultProperties('Address Manual Link Clicked', {
      flow: 'Direct Sign Up',
      module: 'Guardian Info Page',
      is_pediatrics: true,
      is_logged_in: isLoggedIn,
      om_membership_type: omMembershipType,
    });
  }

  tosPageViewed({
    is_pediatrics,
    isLoggedIn,
    omMembershipType,
  }: {
    is_pediatrics: boolean;
    isLoggedIn: boolean;
    omMembershipType: string;
  }) {
    return this.trackWithMembershipCoupon(MP_EVENT_PAGE_VIEWED, {
      flow: 'Direct Sign Up',
      module: 'Peds TOS page',
      is_pediatrics: is_pediatrics,
      is_logged_in: isLoggedIn,
      om_membership_type: omMembershipType,
    });
  }

  legalDocumentSigned({
    isLoggedIn,
    isPediatrics,
    omMembershipType,
  }: {
    isLoggedIn: boolean;
    isPediatrics: boolean;
    omMembershipType: string;
  }) {
    return this.trackWithDefaultProperties('Legal Document Signed', {
      flow: 'Direct Sign Up',
      module: 'Peds Terms of Service page',
      is_pediatrics: isPediatrics,
      is_logged_in: isLoggedIn,
      om_membership_type: omMembershipType,
    });
  }

  existingEmailError({ isLoggedIn, omMembershipType }: { isLoggedIn: boolean; omMembershipType: string }) {
    return this.trackWithDefaultProperties('Existing Email Error', {
      flow: 'Direct Sign Up',
      module: 'Child Account Creation Page',
      is_pediatrics: true,
      is_logged_in: isLoggedIn,
      om_membership_type: omMembershipType,
    });
  }

  serviceAreaPageViewed({
    isLoggedIn,
    omMembershipType,
    submodule,
  }: {
    isLoggedIn: boolean;
    omMembershipType: string;
    submodule: string;
  }) {
    return this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, {
      flow: 'Registration',
      module: 'Service Area Page',
      is_whitelist: false,
      is_pediatrics: true,
      is_logged_in: isLoggedIn,
      om_membership_type: omMembershipType,
      submodule: submodule,
    });
  }

  serviceAreaSelected({
    isLoggedIn,
    serviceArea,
    isPedsServiceable,
    omMembershipType,
  }: {
    isLoggedIn: boolean;
    serviceArea: ServiceArea;
    isPedsServiceable: boolean;
    omMembershipType: string;
  }) {
    return this.trackWithDefaultProperties('Service Area Selected', {
      flow: 'Registration',
      module: 'Service Area Page',
      is_whitelist: false,
      is_logged_in: isLoggedIn,
      service_area: serviceArea.name,
      is_peds_serviceable: isPedsServiceable,
      om_membership_type: omMembershipType,
    });
  }

  paymentPageViewed({
    membershipType,
    isLoggedIn,
    creditCard,
  }: {
    membershipType: string;
    isLoggedIn: boolean;
    creditCard?: string;
  }) {
    const properties = {
      flow: 'Registration',
      module: 'Billing Page',
      om_membership_type: membershipType,
      is_logged_in: isLoggedIn,
      is_whitelist: false,
      is_pediatrics: true,
      ...(creditCard && { credit_card: creditCard }),
    };
    return this.trackWithMembershipCoupon(MP_EVENT_PAGE_VIEWED, properties);
  }

  paymentSubmitted({ membershipType, isLoggedIn }: { membershipType: string; isLoggedIn: boolean }) {
    let props = {
      flow: 'Registration',
      module: 'Billing Page',
      om_membership_type: membershipType,
      is_logged_in: isLoggedIn,
      is_whitelist: false,
      is_pediatrics: true,
    };

    if (this.membershipCoupon) {
      props = Object.assign(props, {
        promo_code: this.membershipCoupon.id,
        promo_code_amount: this.membershipCoupon.amountOff,
        promo_code_percent: this.membershipCoupon.percentOff,
        promo_code_type: this.membershipCoupon.discountType,
      });
    }

    return this.trackWithMembershipCoupon('Billing Info Submitted', props);
  }

  paymentSubmitError({
    membershipType,
    isLoggedIn,
    error,
  }: {
    membershipType: string;
    isLoggedIn: boolean;
    error: string;
  }) {
    return this.trackWithDefaultProperties('Submit Error Encountered', {
      flow: 'Registration',
      module: 'Billing Page',
      om_membership_type: membershipType,
      is_logged_in: isLoggedIn,
      is_whitelist: false,
      is_pediatrics: true,
      error_message: error,
    });
  }

  familyTypePageViewed() {
    return this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, {
      flow: 'Registration',
      module: 'Family Type Page',
    });
  }

  familyTypeSelectedAction(action: string) {
    return this.trackWithDefaultProperties('Family type selected', {
      flow: 'Registration',
      module: 'Family Type Page',
      action_selected: action,
    });
  }

  planTypePageViewed() {
    return this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, {
      flow: 'Registration',
      module: 'Plan Type Page',
    });
  }

  planTypeSelectedAction(action: string) {
    return this.trackWithDefaultProperties('Plan type selected', {
      flow: 'Registration',
      module: 'Plan Type Page',
      action_selected: action,
    });
  }

  freeTrialCodeSubmitted(code: string) {
    return this.trackWithDefaultProperties('Free Trial Code Applied', {
      flow: 'Registration',
      module: 'Free Trial Code Page',
      form_field: 'Free Trial Code',
      is_trial: true,
      value: code,
    });
  }

  editExistingCreditCard({ membershipType, isLoggedIn }: { membershipType: string; isLoggedIn: boolean }) {
    return this.trackWithDefaultProperties('Edit existing credit card', {
      flow: 'Registration',
      module: 'Billing Page',
      om_membership_type: membershipType,
      is_logged_in: isLoggedIn,
      is_whitelist: false,
      is_pediatrics: true,
    });
  }

  editExistingCreditCardAction({
    membershipType,
    isLoggedIn,
    action,
  }: {
    membershipType: string;
    isLoggedIn: boolean;
    action: string;
  }) {
    return this.trackWithDefaultProperties('Edit existing credit card action selected', {
      flow: 'Registration',
      module: 'Billing Page',
      action_selected: action,
      om_membership_type: membershipType,
      is_logged_in: isLoggedIn,
      is_whitelist: false,
      is_pediatrics: true,
    });
  }

  /**
   * Ported over from old consumer reg in 1life
   * @param eventName
   */
  notifyFacebookTracker(eventName: string) {
    fbq('track', eventName);
  }

  /**
   * Ported over from the old consumer reg in 1life
   */
  notifyAdwordsPage2Conversion() {
    const googleSnippetVars = function () {
      const w = window;
      w.google_conversion_id = environment.googleConversionId;
      w.google_conversion_language = 'en';
      w.google_conversion_format = '3';
      w.google_conversion_color = 'ffffff';
      w.google_conversion_label = 'FfzNCID_8l8Ql_fa7wM';
      w.google_remarketing_only = false;
    };
    // DO NOT CHANGE THE CODE BELOW.
    const reportConversion = function (url?) {
      googleSnippetVars();
      const opt = new Object();
      opt['onload_callback'] = function () {
        if (typeof url != 'undefined') {
          window.location = url;
        }
      };
      const conversionHandler = window['google_trackConversion'];
      if (typeof conversionHandler == 'function') {
        conversionHandler(opt);
      }
    };
    reportConversion();
  }
}
