import { Injectable } from '@angular/core';
import * as LDClient from 'launchdarkly-js-client-sdk';
import { LDUser } from 'launchdarkly-js-sdk-common';
import { fromEvent, Observable, of as observableOf } from 'rxjs';
import * as uuid from 'uuid';

import { ConfigService } from '@app/core/config.service';
import { UpdateUserProps } from '@app/core/feature-flags/interfaces';
import { anonymousLDUser, mapPropsToLDUser } from '@app/core/feature-flags/launch-darkly-user-property-mapper';

/**
 *  Service wrapper around the LaunchDarkly Javascript SDK.
 * https://docs.launchdarkly.com/sdk/client-side/javascript
 */
@Injectable({
  providedIn: 'root',
})
export class LaunchDarklyService {
  private ldClient: LDClient.LDClient;
  user: LDUser;
  onChange$: Observable<any>;

  constructor(private config: ConfigService) {
    this.initializeLDClient();
  }

  /**
   * Call when you need percentage rollout or A/B testing for a feature flag targeting anonymous users.
   * Pass in your own random user key or use the UUID generated and returned by this function.
   *
   * @param key
   */
  setAnonymousUserUUID(key = uuid.v4()): string {
    this.user.key = key;
    this.user.anonymous = true;
    this.identifyUser(this.user);
    return key;
  }

  /**
   * Returns the an observable of the value for a given flag based on the user identified for the LaunchDarkly client instance.
   * @param flag
   * @param defaultValue This value will be returned in the case that the LD client can't be initialized properly
   * @return Observable<any> Expect one of: boolean, number, string, or JSON
   */
  featureFlag$(flag: string, defaultValue?: any): Observable<any> {
    return observableOf(this.ldClient.variation(flag, defaultValue));
  }

  /**
   * Takes User and maps various properties to the LDUser interface then updates LaunchDarkly with the user property data
   * @param user
   * @param customAttributes
   * @param privateAttributes
   */
  updateUser(props: UpdateUserProps) {
    this.user = mapPropsToLDUser(props);
    this.identifyUser(this.user);
  }

  resetUser() {
    this.user = anonymousLDUser();
    this.identifyUser(this.user);
  }

  private initializeLDClient() {
    this.user = anonymousLDUser();
    this.ldClient = LDClient.initialize(this.config.json.launchDarklyClientId, this.user, {
      sendEventsOnlyForVariation: true,
    });

    this.onChange$ = fromEvent(this.ldClient, 'change');
  }

  private identifyUser(user: LDUser) {
    this.ldClient.identify(user);
  }
}
