<a
  [href]="deviceSpecificRedirectUrl"
  class="d-block d-lg-none card py-3 px-3 col om-card-link"
  *ngIf="pageState | isSuccessState"
>
  <ng-container *ngTemplateOutlet="closeButton"></ng-container>

  <div class="d-flex">
    <ng-container *ngTemplateOutlet="svg"></ng-container>
    <div class="d-flex flex-column"> <ng-container *ngTemplateOutlet="messaging"></ng-container> </div>
  </div>
</a>

<div class="card p-4 d-none d-lg-block col" [attr.data-cy]="dataCy" *ngIf="pageState | isSuccessState">
  <ng-container *ngTemplateOutlet="closeButton"></ng-container>

  <div class="d-flex">
    <ng-container *ngTemplateOutlet="svg"></ng-container>
    <div class="d-flex flex-column">
      <ng-container *ngTemplateOutlet="messaging"></ng-container>
      <form [formGroup]="formGroup" (ngSubmit)="onSubmit()" class="d-flex">
        <div class="d-inline-block mr-2">
          <!--ID should exist inside of om-phone-number-input; suppress the IDE warning -->
          <!--suppress XmlInvalidId -->
          <label class="d-block" for="phone-number">Mobile Number</label>
          <om-phone-number-input
            [overrideDisplayError]="true"
            [formControlName]="formControlName"
            (errorMessageEmitter)="setErrorMessage($event)"
            class="d-block"
          ></om-phone-number-input>
        </div>
        <button
          type="submit"
          class="btn btn-outline-primary d-inline-block align-self-end mr-2"
          [ngClass]="buttonClasses"
          [disabled]="submitting || submitted"
        >
          {{ currentButtonText }}
        </button>
        <span class="align-self-end text-danger" *ngIf="!wrappedErrorMessage && formErrorMessage">{{
          formErrorMessage
        }}</span>
      </form>
      <p class="mt-1 text-danger" *ngIf="wrappedErrorMessage && formErrorMessage">{{ formErrorMessage }}</p>
    </div>
  </div>
</div>

<ng-template #messaging>
  <h3 class="card-title">{{ title }}</h3>
  <!-- prettier-ignore -->
  <p class="mb-lg-4 mb-0" [ngClass]="textClasses"> {{ text }}</p>
</ng-template>

<ng-template #svg>
  <div class="svg-container mr-4 my-auto"><om-svg-download-app></om-svg-download-app></div>
</ng-template>

<ng-template #closeButton>
  <om-close-x
    *ngIf="showCloseButton"
    class="dismiss"
    role="button"
    aria-label="Permanently hide the Download the One Medical app card."
    height="32"
    width="32"
    (click)="dismissCard($event)"
  ></om-close-x>
</ng-template>
