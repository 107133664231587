import { Component, Input } from '@angular/core';

@Component({
  selector: 'om-svg-eye-closed',
  templateUrl: './svg-eye-closed.component.html',
})
export class SvgEyeClosedComponent {
  @Input() color = '#0358B2';
  @Input() width = 24;
  @Input() height = 15;
}
