import { Address } from './address';

export class Office {
  id: number;
  name: string;
  phone: string;
  email: string;
  imageUrl: string;
  address: Address;
  serviceAreaId: number;
  b2bCompanyId: number;
  permalink: string;

  constructor() {}

  static fromApiV2(officeObject): Office {
    const office = new Office();
    office.id = officeObject.id;
    office.name = officeObject.name;
    office.phone = officeObject.phone;
    office.imageUrl = officeObject.image_url_large;
    office.email = `${officeObject.email_account}@onemedical.com`;
    office.serviceAreaId = officeObject.service_area_id;
    office.b2bCompanyId = officeObject.b2b_company_id;
    office.permalink = officeObject.permalink;

    const address = new Address();
    address.address1 = officeObject.address1;
    address.address2 = officeObject.address2;
    address.city = officeObject.city;
    address.state = officeObject.state;
    address.zip = officeObject.zip;
    address.latitude = officeObject.latitude;
    address.longitude = officeObject.longitude;
    office.address = address;

    return office;
  }
}
