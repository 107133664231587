import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'om-svg-allergy',
  template: `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.5 12.5C13.5 10.5 13.4204 10.4893 14 9" stroke="black" />
      <path d="M4 21C4 21 7.53823 19.4742 10.6942 15.4037C10.7968 15.2719 10.8987 15.1376 11 15" stroke="black" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.0604 4.17046C13.7323 5.42061 14.8479 7 14.8479 7C14.8479 7 16.5712 6.27174 16.9503 4.82954C17.2408 3.72373 16.1622 2 16.1622 2C16.1622 2 14.3509 3.06465 14.0604 4.17046Z"
        stroke="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0463 7.31144C10.2996 8.31112 11.4952 9 11.4952 9C11.4952 9 12.2228 7.84204 11.931 6.68792C11.7067 5.80295 10.482 5 10.482 5C10.482 5 9.82259 6.42647 10.0463 7.31144Z"
        stroke="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.9793 10.3814C17.9811 11.129 16.0046 10.9897 16.0046 10.9897C16.0046 10.9897 15.8726 9.47188 17.0253 8.60938C17.9086 7.94836 20 8.00099 20 8.00099C20 8.00099 19.8626 9.72033 18.9793 10.3814Z"
        stroke="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.02159 13.7196C6.24464 14.9693 7.94064 16 7.94064 16C7.94064 16 9.22319 14.7225 8.96588 13.2796C8.76832 12.1742 7.04763 11 7.04763 11C7.04763 11 5.82402 12.6135 6.02159 13.7196Z"
        stroke="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.0337 19.999C12.034 20.0427 11 18.5877 11 18.5877C11 18.5877 11.8124 17.0513 12.9657 17.0008C13.8503 16.9622 15 18.4121 15 18.4121C15 18.4121 13.9183 19.9596 13.0337 19.999Z"
        stroke="black"
      />
      <path d="M5 5L20 20" stroke="black" />
    </svg>
  `,
})
export class SvgAllergyComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
