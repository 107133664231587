import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'om-toggle-caret',
  templateUrl: './toggle-caret.component.html',
})
export class ToggleCaretComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
