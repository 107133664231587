import { createFeatureSelector, createReducer, on } from '@ngrx/store';

import { State } from '@app/app.reducer';
import { updateAnalyticsProperties } from '@app/core/analytics.actions';

export interface AnalyticsState {
  flow?: string;
  membership_status?: string;
  om_membership_type?: string;
  is_trial?: boolean;
  is_autorenew_trial?: boolean;
  service_area?: string;
  b2b_company_name?: string;
}

const initialState: AnalyticsState = {
  flow: '',
};

export const reducer = createReducer(
  initialState,
  on(updateAnalyticsProperties, (state, action) => ({
    ...state,
    ...action.updates,
  })),
);

export const getAnalyticsState = createFeatureSelector<State, AnalyticsState>('analytics');
