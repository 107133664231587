export enum UserAlerts {
  PEDIATRIC_CONSUMER_REGISTRATION_PROMOTION_ALERT = 'family_promotional_pricing_jan_2021',
}

export class UserAlert {
  id: number;
  alertKey: string;
  dismissed: boolean;

  constructor() {}

  static fromApiV2(response): UserAlert {
    const userAlert = new UserAlert();
    userAlert.id = response.id;
    userAlert.alertKey = response.alert_key;
    userAlert.dismissed = response.dismissed;
    return userAlert;
  }
}
