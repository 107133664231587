import { EventEmitter } from '@angular/core';
import compact from 'lodash-es/compact';
import flatten from 'lodash-es/flatten';
import partition from 'lodash-es/partition';
import { of as observableOf } from 'rxjs';
import { map } from 'rxjs/operators';

import { cssOverrides, fontFaces } from './formstack-form-styles.utils';
import { FormstackForm } from './formstack.type';

interface FormEntry {
  name: string;
  value: string | [];
}

interface FormattedFormEntry {
  name: string;
  value: string;
}

const getMultiSelectValue = (currentValue: [], value: string | []): string[] =>
  flatten(compact([...(currentValue || []), value]));

const getFormData = (formKeyValues: FormEntry[]): Record<string, string | []> =>
  formKeyValues.reduce(
    (keyValues, kv) => ({
      ...keyValues,
      [kv.name]: kv.name.endsWith('[]') ? getMultiSelectValue(keyValues[kv.name], kv.value) : kv.value,
    }),
    {},
  );

const renameFields = (formData: Record<string, string | []>): FormEntry[] =>
  Object.entries(formData).map(([key, value]) => ({
    name: key.replace('field', 'field_').replace('[]', ''),
    value: value,
  }));

const flattenArrayFields = (entries: FormEntry[]): FormattedFormEntry[] =>
  entries.map(entry => ({
    name: entry.name,
    value: entry.value instanceof Array ? entry.value.join('\n') : entry.value,
  }));

const otherSuffix = '_other';
const otherOptionPlaceholder = 'Other';

const mergeValues = (entry: FormattedFormEntry, otherEntry: FormattedFormEntry) => ({
  name: entry.name,
  value: !!otherEntry
    ? entry.value.replace(otherOptionPlaceholder, `${otherOptionPlaceholder}: ${otherEntry.value}`)
    : entry.value,
});

const mergeOtherOptions = ([otherFieldKeyValues, fieldKeyValues]: [
  FormattedFormEntry[],
  FormattedFormEntry[],
]): FormattedFormEntry[] =>
  fieldKeyValues.map((entry: FormattedFormEntry) =>
    mergeValues(
      entry,
      otherFieldKeyValues.find(otherEntry => otherEntry.name === entry.name + otherSuffix),
    ),
  );

const reduceToCollection = (fieldEntries: FormEntry[]): Record<string, string> =>
  fieldEntries.reduce(
    (col, entry) => ({
      ...col,
      [entry.name]: entry.value,
    }),
    {},
  );

export const modifyForm = (iframe: any, onSubmit: EventEmitter<any>) => {
  const iframeWindow = iframe.contentWindow;
  const iframeDoc = iframe.contentDocument;
  removeLogo(iframeDoc);
  applyStyles(iframeWindow);
  const form = iframeDoc.querySelector('form');
  if (form) {
    form.setAttribute('action', '#');

    form.onsubmit = event => {
      event.preventDefault();

      const rawData = iframeWindow.jQuery(form).serializeArray();
      observableOf(rawData)
        .pipe(
          map(formKeyValues => formKeyValues.filter(keyValue => keyValue.name.startsWith('field'))), // grab all relevant inputs
          map(fieldKeyValues => getFormData(fieldKeyValues)),
          map(fieldKeyValues => renameFields(fieldKeyValues)), // the inputs need to be named field_123 to match formstack API but their html uses field123 format
          map(entries => flattenArrayFields(entries)), // formstack posts multiselect values as line delimited strings
          map(entries => mergeOtherOptions(partition(entries, field => field.name.endsWith(otherSuffix)))), // other options control is named field_123_other in formstack - the value in this needs to be updated in field_123
          map(entries => reduceToCollection(entries)),
        )
        .subscribe(formData => onSubmit.emit({ formData, rawData }));
    };
  }
};

export const mapHtml = (s: FormstackForm) =>
  s.html
    .replace(/\/\/static.formstack.com/g, 'http://static.formstack.com')
    .replace(/http:/g, `${window.location.protocol}`); // avoid blocking mixed content http vs https

export const removeLogo = iframeDoc => iframeDoc.querySelector('a[href*="formstack.com"]')?.closest('p')?.remove();

export const iframeStyles = `<style type="text/css">
                                ${fontFaces(`${window.location.protocol}//${window.location.host}`)}
                                ${cssOverrides}
                              </style>`;

export const applyStyles = iframeWindow => iframeWindow.jQuery('head').append(iframeStyles);
