import { Injectable } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AttemptedPathService } from '@app/core/attempted-path.service';

import { RegistrationService } from '../../registration.service';
import { EnterpriseRegistration } from '../enterprise-registration';
import { EnterpriseRegistrationAnalyticsService } from '../enterprise-registration-analytics.service';
import { IRegistrationStep, RegistrationStep } from '../registration-step';
import { TermsOfServiceStepComponent } from './terms-of-service-step.component';

@Injectable()
export class TermsOfServiceConfig extends RegistrationStep implements IRegistrationStep {
  GA_LABEL = 'ToS_Step';

  MODULE = 'Legal Document Page';
  component = TermsOfServiceStepComponent;
  componentInstance: TermsOfServiceStepComponent;
  progress = 87.5;
  form = null;

  constructor(
    private attemptedPathService: AttemptedPathService,
    private registrationService: RegistrationService,
    private enterpriseRegistrationAnalyticsService: EnterpriseRegistrationAnalyticsService,
  ) {
    super();
  }

  initComponent(component: TermsOfServiceStepComponent, state: EnterpriseRegistration) {
    component.user = state.patient;
    component.signer = state.patient;
    this.componentInstance = component;
    this.trackPageView();
  }

  submit(state: EnterpriseRegistration): Observable<any> {
    return this.registrationService.markRegComplete(null).pipe(
      tap(() => {
        this.enterpriseRegistrationAnalyticsService.regInputSubmitted({
          module: this.MODULE,
          isWhitelist: state.isWhitelisted,
        });
        if (this.attemptedPathService.hasAttemptedPath()) {
          this.attemptedPathService.navigateToAttemptedPath();
        } else {
          state.setCurrentStep('success');
        }
      }),
    );
  }

  private trackPageView() {
    this.enterpriseRegistrationAnalyticsService.trackGoogleEvent(this.GA_ACTION, this.GA_LABEL);
  }
}
