import { Component } from '@angular/core';

@Component({
  selector: 'omgui-action',
  template: `
    <ng-content select="omgui-checkbox"></ng-content>
    <ng-content select="button.btn"></ng-content>
    <ng-content select="a.btn"></ng-content>
  `,
})
export class OmguiActionComponent {
  constructor() {}
}
