import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'om-specialty',
  templateUrl: './specialty.component.html',
  styleUrls: ['./specialty.component.scss'],
})
export class SpecialtyComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
