import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { RegistrationStepDirective } from '../registration-step.directive';
import {
  INVALID_CONVERSION_ERROR_MESSAGE,
  PLAN_DOESNT_SUPPORT_DEPENDENTS_ERROR_MESSAGE,
} from '../whitelisted-employee-errors';

@Component({
  selector: 'om-activation-code-step',
  templateUrl: './activation-code-step.component.html',
})
export class ActivationCodeStepComponent extends RegistrationStepDirective implements AfterViewInit {
  @Input() canEmailCode = false;
  @Input() codeSent = false;
  @Input() resendError = false;
  @Input() codeRetrievalEmail: string;
  @Output() resendCode: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('activationCode') activationCode;

  activationCodeErrorMsg =
    'The code you’ve entered is invalid. Please enter a valid company code or contact your benefits team if you ' +
    'continue to experience problems.';

  resendErrorMsg = "We're having trouble retrieving your code. Please contact your benefits department.";
  systemError = 'There was an error submitting your activation code. Please try again later.';

  ngAfterViewInit(): void {
    this.activationCode.nativeElement.focus();
  }

  onResendCode(e: MouseEvent) {
    e.preventDefault();
    this.resendCode.emit(this.codeRetrievalEmail);
  }

  onSubmit() {
    this.submitting = true;
    this.submit.emit();
  }

  hasError() {
    return this.activationCodeError() !== '';
  }

  onInput() {
    this.form.controls.activationCode.setErrors(null);
  }

  activationCodeError(): string {
    const { errors } = this.form.controls.activationCode;
    if (errors && errors.invalid) {
      return this.activationCodeErrorMsg;
    } else if (errors && errors.system) {
      return this.systemError;
    } else if (errors && errors.planDoesntSupportDependents) {
      return PLAN_DOESNT_SUPPORT_DEPENDENTS_ERROR_MESSAGE;
    } else if (errors && errors.invalidConversion) {
      return INVALID_CONVERSION_ERROR_MESSAGE;
    } else if (this.resendError) {
      return this.resendErrorMsg;
    }
    return '';
  }
}
