import { Membership } from 'app/core/membership';
import * as moment from 'moment';

export class MembershipExpirationHelper {
  static readonly soonExpirationInDays = 30;

  constructor(private membership: Membership) {}

  formattedExpiry(): string {
    return moment(this.membership.validUntil).calendar(null, {
      sameDay: function(now) {
        return this.isAfter(now) ? '[expires today]' : '[expired today]';
      },
      nextDay: '[is expiring tomorrow]',
      nextWeek: '[is expiring on] LL',
      lastDay: '[has expired]',
      lastWeek: '[has expired]',
      sameElse: function(now) {
        return this.isAfter(now) ? '[is expiring on] LL' : '[has expired]';
      },
    });
  }

  expiresSoon(): boolean {
    return this.membership.isExpiringSoon();
  }

  expirationDateString(): string {
    return moment(this.membership.validUntil).format('MMM DD, YYYY');
  }

  freeTrialExpirationDateString(): string {
    return moment(this.membership.trialUntil).format('MMM DD, YYYY');
  }

  cancellationDateString(): string {
    const cancelDate = this.membership.inFreeTrial() ? this.membership.trialUntil : this.membership.validUntil;
    return moment(cancelDate).format('M/D/YY');
  }

  hasExplicitExpirationDate(): boolean {
    return this.membership.validUntil !== null;
  }
}
