import { Injectable } from '@angular/core';
import * as Pusher from 'pusher-js';

import { ConfigService } from '../core/config.service';

@Injectable()
export class PusherService {
  private socket: Pusher.Pusher;

  constructor(configService: ConfigService) {
    const pusherKey = configService.json.pusherKey;
    if (pusherKey == null) {
      console.error('Pusher key is not defined in this environment');
      return;
    }

    this.socket = new Pusher(configService.json.pusherKey, {
      encrypted: true,
    });
  }

  subscribeTo(channelName): Pusher.Channel {
    return this.socket.subscribe(channelName);
  }
}
