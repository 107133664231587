import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'om-close',
  templateUrl: './close.component.html',
})
export class CloseComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
