import includes from 'lodash-es/includes';

export enum Features {
  APPOINTMENT_SEARCH_ADDRESS_REMOTE_WEB = 'appointment_search_address_remote_web',
  APPOINTMENT_TYPE_HEADER_CONTENT_WEB = 'appointment_type_header_content_web',
  BHX_HOMESCREEN_INLINE_SURVEY = 'bhx_homescreen_inline_survey',
  BHX_MINDSET_SELF_BOOKING = 'bhx_mindset_self_book',
  BHX_MINDSET_HOMESCREEN_BANNER = 'bhx_mindset_homescreen_banner',
  BHX_SHIFT_SELF_BOOKING = 'bhx_rgv_self_book', // To be removed by https://jira.onemedical.com/browse/NS-244
  BHX_LICENSURE_BASED_APPOINTMENT_SEARCH = 'bhx_licensure_based_appointment_search',
  BHX_PROGRAM_HOMESCREEN_BANNER = 'bhx_program_homescreen_banner',
  BHX_SHIFT_SPECIALTY_PROGRAMS_SELF_BOOK = 'bhx_shift_specialty_programs_self_book', // To be removed by https://jira.onemedical.com/browse/NS-245
  CARE_ROUTING_COMMON_CONCERNS_WEB = 'care_routing_common_concerns_web',
  CARE_ROUTING_ENABLE_CHANNEL_ROUTING_GROUP = 'care_routing_enable_channel_routing_group',
  CARE_ROUTING_ENABLE_CHANNEL_ROUTING_PERCENTAGE = 'care_routing_enable_channel_routing_percentage',
  CORONAVIRUS_BANNER_ENABLED = 'coronavirus_banner_enabled',
  CORONAVIRUS_CARD_ENABLED = 'coronavirus_card_enabled',
  DISABLE_ENTERPRISE_PEDS_REGISTRATION = 'disable_enterprise_peds_registration',
  ENTERPRISE_DIRECT_SIGNUP_REDESIGN = 'enterprise_direct_signup_redesign',
  FITBIT = 'fitbit',
  GIFT_MEMBERSHIP = 'gift_onemedical',
  INSURANCE_VERIFY_OR_SELF_PAY = 'insurance_verify_or_self_pay',
  OM_NOW_ONBOARDING_EXPERIMENT = 'om_now_onboarding_experiment',
  PEDIATRIC_CONSUMER_REGISTRATION_EXISTING_CC = 'new_pediatric_consumer_registration_existing_cc',
  PEDIATRIC_CONSUMER_REGISTRATION_PROMOTION = 'pediatric_consumer_registration_promotion',
  SHARE_PCP = 'share_pcp',
  SHOW_UPCOMING_APPOINTMENT_REMINDER_CARD = 'show_upcoming_appointment_reminder_card_web',
  SHOW_VIRTUAL_DESCRIPTION_REGISTRATION = 'show_virtual_description_registration',
  VIRTUAL_CARE_REMOTE_VISITS_DEEP_LINK = 'virtual_care_remote_visits_deep_link',
  VIRTUAL_CARE_PHARMACY_PAGE_MIGRATION = 'virtual_care_pharmacy_page_migration',
  VIRTUAL_CARE_VACCINES_PAGE = 'virtual_care_vaccines_page',
  GET_CARE_BOOK_VISIT_CTA = 'get_care_book_visit_cta',
}
export class ActiveFeatureFlagCollection {
  activeFlags: string[];

  constructor(flags: string[]) {
    this.activeFlags = flags;
  }

  static fromApiV2(res): ActiveFeatureFlagCollection {
    return new ActiveFeatureFlagCollection(res);
  }

  isFeatureActive(feature_name: string): boolean {
    return includes(this.activeFlags, feature_name);
  }
}
