<div class="border">
  <agm-map
    [latitude]="latitude"
    [longitude]="longitude"
    [styles]="styles"
    [streetViewControl]="false"
    [disableDefaultUI]="true"
    [zoom]="zoom"
  >
    <agm-marker [latitude]="latitude" [longitude]="longitude" [iconUrl]="icon"></agm-marker>
  </agm-map>
</div>
