/* Mixpanel Event Names */
export const MP_EVENT_PAGE_VIEWED = 'Page Viewed';

/* Registration Specific MP Constants. Used by Consumer, Pediatric, and B2b Reg */
export const MP_EVENT_REG_INPUT_SUBMITTED = 'Registration Input Submitted';
export const MP_EVENT_REG_ADD_DISCOUNT_CODE_CLICKED = 'Add Discount Code Clicked';
export const MP_EVENT_REG_DISCOUNT_CODE_APPLIED = 'Discount Code Applied';
export const MP_EVENT_REG_SUCCESSFULLY_SUBMITTED = 'Consumer Registration Successfully Submitted';
export const MP_EVENT_GIFT_REG_SUCCESSFULLY_SUBMITTED = 'Consumer Gift Registration Successfully Submitted';
export const MP_EVENT_INVALID_CC_ENTERED = 'Invalid Credit Card Information Added';
export const MP_EVENT_PROMO_CODE_AUTO_APPLIED = 'Promotion Code Auto Applied';
export const MP_EVENT_REG_CONFIRMATION_SURVEY_CLICKED = 'Health History Survey Started';
export const MP_EVENT_REG_CONFIRMATION_APPOINTMENT_CLICKED = 'Book Appointment Clicked';
