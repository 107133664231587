import { Injectable } from '@angular/core';
import { throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ApiService } from '../core/api.service';

@Injectable()
export class ValidatePasswordService {
  constructor(private apiService: ApiService) {}

  validateComplexity(email, password) {
    return this.apiService
      .post('/api/v2/public/validate_passwords', { email: email, password: password })
      .pipe(catchError(this.handleError));
  }

  private handleError(response) {
    const returnedErrorMsg = response.error.errors;
    const defaultMessage = `Your password must be at least eight characters and include at
      least one lowercase letter, one uppercase letter, and one number.
      Additionally, passwords can not be made up of repeating characters or consecutive characters`;

    return observableThrowError(returnedErrorMsg || defaultMessage);
  }
}
