import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { UserService } from './user.service';

@Injectable()
export class AdultOnlyGuardService implements CanActivate {
  constructor(private authService: AuthService, private userService: UserService, private router: Router) {}

  canActivate(): boolean | Observable<boolean> {
    if (this.authService.isLoggedIn()) {
      this.userService.getUser();

      return this.userService.user$.pipe(
        take(1),
        map(user => {
          if (user.isPediatric()) {
            this.router.navigate([''], { replaceUrl: true });
            return false;
          } else {
            return true;
          }
        }),
      );
    } else {
      return true;
    }
  }
}
