import {
  Component,
  ContentChild,
  ContentChildren,
  Directive,
  Input,
  OnInit,
  QueryList,
  TemplateRef,
} from '@angular/core';

import { cardContentAnimation, enterAnimation } from '../page-state/animations';
import { isSuccessState, PageState } from '../page-state/page-state';

@Directive({
  selector: '[om-description-list-card-value]',
})
export class DescriptionListCardValueDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({
  selector: '[om-description-list-card-key]',
})
export class DescriptionListCardKeyDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({
  selector: 'om-description-list-card-row',
})
export class DescriptionListCardRowDirective {
  @ContentChild(DescriptionListCardKeyDirective) key: DescriptionListCardKeyDirective;
  @ContentChild(DescriptionListCardValueDirective) value: DescriptionListCardValueDirective;

  constructor() {}
}

@Directive({
  selector: '[om-description-list-card-header]',
})
export class DescriptionListCardHeaderDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Component({
  selector: 'om-description-list-card',
  templateUrl: './description-list-card.component.html',
  styleUrls: ['../card.scss'],
  animations: [enterAnimation, cardContentAnimation],
})
export class DescriptionListCardComponent implements OnInit {
  @ContentChild(DescriptionListCardHeaderDirective) header: DescriptionListCardHeaderDirective;
  @ContentChildren(DescriptionListCardRowDirective) rows: QueryList<DescriptionListCardRowDirective>;
  @Input() pageState: PageState = PageState.SUCCESS;
  isSuccessState = isSuccessState;

  constructor() {}

  ngOnInit() {}
}
