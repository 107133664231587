<svg
  width="50px"
  height="50px"
  viewBox="0 0 50 50"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <g id="Table-button/subcomponent/specialty" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <circle stroke="currentColor" cx="25" cy="25" r="24.5"></circle>
    <g id="ico-vaccine-order">
      <g transform="translate(14.000000, 12.000000)" id="Page-1">
        <g>
          <path id="path-1" d="M0,0 L40,0 L40,34 L0,34 L0,0 Z"></path>
          <path
            d="M17.5264138,6.62852414 C17.5264138,7.84762759 16.5386897,8.83535172 15.3195862,8.83535172 C14.1004828,8.83535172 13.1127586,7.84762759 13.1127586,6.62852414 C13.1127586,5.40942069 14.1004828,4.42169655 15.3195862,4.42169655 C16.5386897,4.42169655 17.5264138,5.40942069 17.5264138,6.62852414 L17.5264138,6.62852414 Z"
            id="Stroke-1"
            stroke="currentColor"
          ></path>
          <path
            d="M7.88024828,14.0413862 C3.96045517,15.2422828 0.379006897,13.2827655 0.379006897,13.2827655 L0.379006897,1.90345517 C0.379006897,1.90345517 3.89748966,0.259524138 7.88024828,0.386213793 C9.7805931,0.446903448 10.8441793,1.02421379 10.8441793,1.02421379 L10.8441793,14.8000069 C10.8441793,14.8000069 9.75783448,15.3431793 8.63886897,15.5586276 C3.70783448,16.5069034 1.05266207,14.8000069 1.05266207,14.8000069"
            id="Stroke-3"
            stroke="currentColor"
          ></path>
          <path
            d="M9.39748966,15.5586276 C6.23631724,24.7250414 19.8300414,22.9566966 14.3937655,12.9686966 C12.6368,9.7400069 13.6351448,8.41697241 13.6351448,8.41697241"
            id="Stroke-5"
            stroke="currentColor"
          ></path>
          <path
            d="M7.38714483,15.7988069 C3.46735172,29.7066 23.6974897,28.8850138 20.462731,15.2442552"
            id="Stroke-7"
            stroke="currentColor"
          ></path>
          <path
            d="M21.9797448,12.9685448 C21.9797448,14.2255793 21.3007793,15.2444069 20.4625034,15.2444069 C19.6242276,15.2444069 18.9452621,14.2255793 18.9452621,12.9685448 C18.9452621,11.7115103 19.6242276,10.6926828 20.4625034,10.6926828 C21.3007793,10.6926828 21.9797448,11.7115103 21.9797448,12.9685448 L21.9797448,12.9685448 Z"
            id="Stroke-9"
            stroke="currentColor"
          ></path>
          <path d="M17.2492138,5.17811724 L14.9733517,6.69535862" id="Stroke-11" stroke="currentColor"></path>
        </g>
      </g>
    </g>
  </g>
</svg>
