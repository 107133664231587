import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DetectResizeService } from './detect-resize.service';

@Directive({
  selector: '[om-detect-resize]',
})
export class DetectResizeDirective implements OnInit, OnDestroy {
  @Output() resizeEvent = new EventEmitter<DOMRectReadOnly>();

  private destroy$ = new Subject();

  constructor(private el: ElementRef, private detectResizeService: DetectResizeService) {}

  ngOnInit() {
    this.detectResizeService
      .getResizeEvents$(this.el)
      .pipe(takeUntil(this.destroy$))
      .subscribe(contentRect => this.resizeEvent.emit(contentRect));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
