import { HttpClient } from '@angular/common/http';
import { AfterContentInit, Component, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'om-svg',
  providers: [],
  template: ` <span class="inline-svg" [innerHTML]="inlineSvg"></span> `,
})
export class Svg implements AfterContentInit {
  static PATHS = {
    appointment: 'assets/images/icons/tasks/ico-button-appointment.svg',
    back: 'assets/images/icons/icon-back.svg',
    checkmark: 'assets/images/icons/icon-checkmark.svg', // <!-- use <om-svg-checkmark> component instead, remove from here when all usages updated -->
    next: 'assets/images/icons/icon-next.svg',
    next_no_circle: 'assets/images/icons/next.svg',
    sendMessage: 'assets/images/icons/tasks/ico-button-message.svg',
    show_password: 'assets/images/icons/icon-show-password.svg',
    clock: 'assets/images/icons/icon-clock.svg',
    logo_confetti: 'assets/images/logo_confetti.svg',
    checklist: 'assets/images/checklist.svg',
    welcome_back: 'assets/images/welcome_back.svg',
  };

  @Input() image: string;

  inlineSvg: SafeHtml;

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {
    this.inlineSvg = null;
  }

  ngAfterContentInit() {
    if (!this.image) {
      return;
    }

    const url = Svg.PATHS[this.image] || `assets/images/icons/${this.image}.svg`;

    this.http.get(url, { responseType: 'text' }).subscribe(response => {
      this.inlineSvg = this.sanitizer.bypassSecurityTrustHtml(response);
    });
  }
}
