import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'om-provider',
  templateUrl: './provider.component.html',
  styleUrls: ['./provider.component.scss'],
})
export class ProviderComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
