import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import {
  combineLatest,
  EMPTY,
  from,
  noop,
  Observable,
  observable,
  of as observableOf,
  throwError as observableThrowError,
} from 'rxjs';
import { catchError, concatMap, map, shareReplay, switchMap, tap } from 'rxjs/operators';

import { AnalyticsService } from '@app/core/analytics.service';

import { environment } from '../../environments/environment';
import { AuthServiceImplementation } from './auth.service';
import { WindowService } from './window.service';
interface Auth0Config {
  audience: string;
  domain: string;
  clientId: string;
}

const auth0Config = (<any>environment).auth0 || <any>{};
const { myoneServer } = environment;
const configured = (auth0Config.audience && auth0Config.domain && auth0Config.clientId && true) || false;

// **** This Injectable should only be accessed through the AuthService ****

@Injectable({
  providedIn: 'root',
})
/**
 * This Class is specifically for handling the onelife implementation of authentication
 * We are moving away from this towards auth0; separating the implementation from the
 * interface will allow use to seemlessly switch to a new implementation
 */
export class Auth0AuthService implements AuthServiceImplementation {
  static configured: boolean = configured;
  configured: boolean = configured;
  // private wrappers for the auth0 sdk client and methods

  private returnURI = this.windowService.getLocationOrigin();

  // Async auth0 sdk client instantiation
  private auth0Client$: Observable<Auth0Client> = (from(
    createAuth0Client({
      audience: (<any>auth0Config).audience,
      domain: (<any>auth0Config).domain,
      client_id: (<any>auth0Config).clientId,
      redirect_uri: `${this.returnURI}`,
      patient_host: myoneServer,
    }),
  ) as Observable<Auth0Client>).pipe(
    // tap(auth0 => ((<any>window).auth0 = auth0)),
    shareReplay(1), // Every subscription receives the same shared value
    catchError(err => observableThrowError(err)),
  );

  // private wrapper for the auth0 isAuthenticated promise
  private isAuthenticated$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.isAuthenticated())),
    shareReplay(1),
  );

  // private wrapper for the handleRedirectCallback() promise
  private handleRedirectCallback$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.handleRedirectCallback())),
  );

  constructor(
    private router: Router,
    private windowService: WindowService,
    private analyticsService: AnalyticsService,
  ) {}

  private handleAuthCallback(): Observable<boolean> {
    this.analyticsService.auth0LoginCompleted();
    const cbResToTarget = cbRes => (cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/');
    const authComplete$ = this.handleRedirectCallback$.pipe(
      // target redirect route from callback results
      map(cbResToTarget),
      tap(target => this.router.navigateByUrl(target)),
      concatMap(() => this.isAuthenticated$),
      shareReplay(1),
    );
    authComplete$.subscribe();
    return authComplete$;
    // if there are no auth params, auth is still handled
  }

  init() {
    // Call when app reloads after user logs in with Auth0
    const params = this.windowService.getLocationSearch();
    if (params.includes('code=') && params.includes('state=')) {
      return this.handleAuthCallback();
    }

    this.isAuthenticated$.subscribe();
    return this.isAuthenticated$;
  }

  getToken() {
    return this.auth0Client$.pipe(
      switchMap(client => from(client.getTokenSilently())),
      catchError(err => {
        console.error(err);
        return observableOf(null);
      }),
    );
  }

  isAuthenticated() {
    return this.isAuthenticated$;
  }

  goLogin(redirectPath = '/') {
    this.auth0Client$.subscribe((client: Auth0Client) => {
      this.analyticsService.auth0LoginStarted();
      client.loginWithRedirect({
        redirect_uri: this.returnURI,
        appState: { target: redirectPath },
      });
    });
  }

  logout(returnURI?: string) {
    this.auth0Client$.subscribe((client: Auth0Client) => {
      client.logout({
        client_id: auth0Config.clientId,
        returnTo: returnURI || this.returnURI,
      });
    });
  }

  // method used in the authService impelentation interface, currrently noop
  setToken(token) {}
}
