<div [ngClass]="determineClass()">
  <label class="checkbox-label" [class.d-flex]="displayFlex" (click)="handleCheck($event)" data-cy="checkbox">
    <div
      tabindex="0"
      (keydown.space)="handleCheck($event)"
      (keydown.enter)="handleCheck($event)"
      class="checkmark-container"
      role="checkbox"
      [attr.aria-checked]="isChecked"
    >
      <om-circle-with-checkmark [ngClass]="determineClass()"></om-circle-with-checkmark>
    </div>
    <span *ngIf="!!checkboxLabel" class="label-text">{{ checkboxLabel }}</span>
  </label>
</div>
