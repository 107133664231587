import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ApiHeaderService {
  headers: HttpHeaders;
  private _token: string;

  constructor() {
    this.headers = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }

  get token() {
    return this._token;
  }

  setCacheType() {
    this.headers = this.headers.set('Cache-Control', 'no-cache');
  }

  resetCacheType() {
    this.headers = this.headers.delete('Cache-Control');
  }

  setAccessToken(token) {
    this._token = token;
    this.headers = this.headers.set('Authorization', `Bearer ${token}`);
  }

  revokeAccessToken() {
    this._token = undefined;
    this.headers = this.headers.delete('Authorization');
  }
}
