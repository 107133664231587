import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { GraphqlService } from '../core/graphql.service';

@Injectable()
export class StateService {
  states$: Observable<Array<any>>;

  constructor(private graphqlService: GraphqlService) {
    this.states$ = this.graphqlService.postQuery('states { id name short_name }').pipe(
      map(data => data.states),
      shareReplay(1),
    );
  }
}
