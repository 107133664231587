import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from '@app/core/api.service';

@Injectable()
export class PatientExperimentService {
  constructor(private apiService: ApiService) {}

  experimentVariant(experimentName, variantName, userId): Observable<boolean> {
    const apiParams = { patient_id: userId, experiment_name: experimentName };
    return this.apiService
      .get('/api/v2/patient/patient_experiment', false, apiParams)
      .pipe(map((variantNameJson: any) => variantNameJson && variantNameJson['experimentVariant'] === variantName));
  }
}
