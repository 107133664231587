import { Injectable } from '@angular/core';

import { ConfigService } from '@app/core/config.service';
import { WindowService } from '@app/core/window.service';

@Injectable()
export class AppStoreRedirectService {
  constructor(private windowService: WindowService, private config: ConfigService) {}

  get redirectUrl(): string {
    const mobileUrl = this.mobileRedirectUrl;
    const defaultUrl = 'https://www.onemedical.com/technology/';

    return mobileUrl || defaultUrl;
  }

  get mobileRedirectUrl(): string | void {
    if (this.windowService.isAndroid()) {
      return this.config.json.googlePlayLink;
    } else if (this.windowService.isIos()) {
      return this.config.json.appStoreLink;
    }
  }
}
